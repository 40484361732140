import { useState } from 'react';

import { RadioGroup } from '@mui/material';

import { useGetTheme } from '@src/hooks/useGetTheme';
import i18n from '@src/i18n/config';
import { Language, LanguageType } from '@src/lib/types';

import { LanguageOption, RadioStyled } from '../style';

const LanguageDrawerLayout = () => {
  const [selectedLang, setSelectedLang] = useState(localStorage.getItem('lang') || Language.EN);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang as LanguageType);
    localStorage.setItem('lang', lang);
    setSelectedLang(lang);
  };

  const { styledTheme } = useGetTheme();

  const LanguagesOptions = [
    { label: '🇺🇸 English', key: Language.EN, onClick: changeLanguage },
    { label: '🇫🇷 Français', key: Language.FR, onClick: changeLanguage },
    { label: '🇰🇷 한국인', key: Language.KO, onClick: changeLanguage },
    { label: '🇯🇵 日本語', key: Language.JA, onClick: changeLanguage },
  ];

  return (
    <RadioGroup
      value={selectedLang}
      onClick={e => e.stopPropagation()}
      sx={{ gap: styledTheme.newSpace.get('xl'), flex: 1 }}
    >
      {LanguagesOptions.map(option => (
        <LanguageOption
          key={`${option.key}-${option.label}`}
          onClick={e => {
            e.stopPropagation();
            changeLanguage(option.key);
          }}
        >
          {option.label}
          <RadioStyled value={option.key} />
        </LanguageOption>
      ))}
    </RadioGroup>
  );
};

export default LanguageDrawerLayout;
