import styled from 'styled-components';

import { Avatar, IconButton } from '@mui/material';

import { Breakpoints } from '@src/lib/styles/breakpoints';

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.newSpace.get('4xl')};
  width: 400px;
  padding: ${({ theme }) => ` ${theme.newSpace.get('4xl')}  ${theme.newSpace.get('xl')}`};
  padding-right: 30px;
  position: relative;
  ${Breakpoints.TABLET_SMALL} {
    border-radius: 30px 30px 0 0;
    width: 100%;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.newSpace.get('xs')};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const TitleTextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.newSpace.get('xs')};
`;

export const AvatarContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.paleGray};
  border-radius: 50%;
  padding: ${({ theme }) => theme.newSpace.get('2xs')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleText = styled.div`
  color: ${({ theme }) => theme.colors.midnightGray};
  font-size: ${({ theme }) => theme.newFontSize.get('2xl')};
  font-weight: 700;
`;

export const DescriptionText = styled.div`
  color: ${({ theme }) => theme.colors.midnightGray};
  font-size: ${({ theme }) => theme.newFontSize.get('l')};
  font-weight: 500;
`;

export const CostText = styled.div`
  font-size: ${({ theme }) => theme.newFontSize.get('xl')};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.midnightGray};
`;

export const TimeContainer = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.midnightGray};
  font-size: ${({ theme }) => theme.newFontSize.get('l')};
  font-weight: 500;
  align-items: center;
  gap: ${({ theme }) => theme.newFontSize.get('3xs')};
`;

export const InstructorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.newSpace.get('xs')};
`;

export const InstructorItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.newSpace.get('xs')};
`;

export const InstructorNameText = styled.div`
  color: ${({ theme }) => theme.colors.gunmental};
  font-size: ${({ theme }) => theme.newFontSize.get('m')};
  font-weight: 500;
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: -15px;
  top: -15px;
`;

export const StyledAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.dandelion};
  color: ${({ theme }) => theme.colors.midnightGray};
  width: 20px;
  height: 20px;
  font-size: ${({ theme }) => theme.newSpace.get('s')};
  font-weight: 500;
`;
