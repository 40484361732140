import { useContext, useEffect } from 'react';

import { Box, useMediaQuery } from '@mui/material';

import MobileHeader from '@src/components/ParentMainLayout/MobileHeader';
import WalkThrough from '@src/components/WalkThrough';
import { AuthContext } from '@src/contexts/AuthContextProvider';
import { GlobalUIContext } from '@src/contexts/GlobalUIContext';
import { useGetTheme } from '@src/hooks/useGetTheme';

import ProgramDropdown from './ProgramDropdown';
import SwitchKidsDropdown from './SwitchKidsDropdown';

const DropdownLayout = () => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const { selectedChildren, setSelectedChildren } = useContext(GlobalUIContext);
  const { userDetail } = useContext(AuthContext);
  const { styledTheme } = useGetTheme();
  useEffect(() => {
    if (userDetail && setSelectedChildren) {
      if (userDetail?.children?.length) {
        setSelectedChildren(userDetail.children[0]);
      }
    }
  }, [userDetail, setSelectedChildren]);

  const WalkThroughSteps = [
    {
      target: '#switch-child-tour-target',
      heading: 'switchChild',
      content: 'switchChildDescription',
      disableBeacon: true,
    },
    {
      target: '#program-selection-tour-target',
      heading: 'programSelection',
      content: 'programSelectionDescription',
      disableBeacon: true,
    },
  ];

  if (isDesktop)
    return (
      <WalkThrough phase={2} steps={WalkThroughSteps}>
        <Box
          sx={{
            display: 'flex',
            bgcolor: 'white',
            gap: styledTheme.newSpace.get('xl'),
            padding: `${styledTheme.newSpace.get('m')} 60px`,
            margin: '0 -60px',
          }}
        >
          {selectedChildren ? (
            <Box sx={{ width: '300px' }} id='switch-child-tour-target'>
              <SwitchKidsDropdown />
            </Box>
          ) : null}
          <Box sx={{ width: '300px' }} id='program-selection-tour-target'>
            <ProgramDropdown />
          </Box>
        </Box>
      </WalkThrough>
    );

  return (
    <WalkThrough phase={2} steps={WalkThroughSteps}>
      <MobileHeader />
      <Box
        sx={{
          padding: `${styledTheme.newSpace.get('m')} ${styledTheme.newSpace.get('xl')}`,
          bgcolor: styledTheme.colors.white,
          margin: `0 calc(${styledTheme.newSpace.get('xl')} * -1)`,
        }}
        id='program-selection-tour-target'
      >
        <ProgramDropdown />
      </Box>
    </WalkThrough>
  );
};

export default DropdownLayout;
