import { DefaultTheme } from 'styled-components';

import { createTheme, Theme } from '@mui/material/styles';

import getTheme, { Modes, ModeType } from './theme';

declare module '@mui/material/styles' {
  interface Theme {
    styledTheme: DefaultTheme;
  }
  interface ThemeOptions {
    styledTheme?: DefaultTheme;
  }
}

const muiTheme = (styledTheme: DefaultTheme) =>
  createTheme({
    styledTheme,
    palette: {
      primary: {
        main: '#D97415',
      },
    },
    typography: {
      fontFamily: 'Inter, sans-serif',
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            backgroundColor: '#FFFFFF',
            borderRadius: '12px',
            border: '1px solid #F2F2F2',
            overflow: 'hidden',
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 500,
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            backgroundColor: '#FCFCFD',
            color: '#707070',
            paddingTop: 0,
            paddingBottom: 0,
            fontWeight: 500,
            whiteSpace: 'nowrap',
            fontSize: '14px',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            whiteSpace: 'nowrap',
            color: '#3D3D3D',
          },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          root: {
            width: '100%',
          },
        },
      },
      MUIDataTableBodyRow: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
      },
    },
  });

const darkMuiTheme = (styledTheme: DefaultTheme) =>
  createTheme({
    styledTheme,
    typography: {
      fontFamily: 'Inter, sans-serif',
    },
    palette: {
      mode: 'dark',
      background: {
        paper: styledTheme.colors.navyBlue,
      },
    },
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            fontWeight: 700,
          },
          data: {
            fontWeight: 700,
            whiteSpace: 'nowrap',
          },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          root: {
            width: '100%',
          },
        },
      },
      MUIDataTableBodyRow: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
      },
    },
  });

const getMuiTheme = (mode: ModeType): Theme => {
  const styledTheme = getTheme(mode);
  return mode === Modes.LIGHT ? muiTheme(styledTheme) : darkMuiTheme(styledTheme);
};

export { getMuiTheme };
