import styled from 'styled-components';

export const StyledActivityCardContainer = styled.div<{ $isSelected: boolean }>(({ $isSelected, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.newSpace.get('m'),
  boxSizing: 'border-box',
  background: 'white',
  borderRadius: '12px',
  justifyContent: 'space-between',
  gap: theme.newSpace.get('s'),
  boxShadow: '0px 4px 12px 0px #00000005',
  height: '100%',
  border: `2px solid ${$isSelected ? theme.colors.apricot : theme.colors.white}`,
}));

export const ActivityDetailsWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  gap: theme.newSpace.get('m'),
}));

export const ActivityDetailsHeader = styled.div(({ theme }) => ({
  display: 'flex',
  boxSizing: 'border-box',
  gap: theme.newSpace.get('2xs'),
  alignItems: 'center',
}));

export const ActivityAvatarWrapper = styled.div(({ theme }) => ({
  border: `1px solid ${theme.colors.paleGray}`,
  borderRadius: '50%',
  padding: theme.newSpace.get('2xs'),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
}));

export const ActivityName = styled.div(({ theme }) => ({
  color: theme.colors.midnightGray,
  fontSize: theme.newFontSize.get('xl'),
  fontWeight: '500',
}));

export const SeeActivityDetails = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: theme.newFontSize.get('m'),
  color: theme.colors.orange,
  fontWeight: '500',
  whiteSpace: 'nowrap',
  marginLeft: 'auto',
  cursor: 'pointer',
}));

export const ActivityCostWrapper = styled.div(() => ({
  display: 'flex',
  boxSizing: 'border-box',
  alignItems: 'center',
  gap: '2px',
}));

export const ActivityCost = styled.div<{ $isMaterial?: boolean }>(({ $isMaterial, theme }) =>
  $isMaterial
    ? { color: theme.colors.charcoalMist, fontSize: theme.newFontSize.get('m'), fontWeight: '500' }
    : {
        fontSize: theme.newFontSize.get('3xl'),
        fontWeight: '600',
        color: theme.colors.midnightGray,
      }
);

export const ActivityInstructorTimeWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  gap: theme.newSpace.get('xs'),
}));

export const InstructorDetailsWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.newSpace.get('3xs'),
}));
export const InstructorNameWrapper = styled.div(({ theme }) => ({ display: 'flex', gap: theme.newSpace.get('xs') }));

export const InstructorName = styled.div(({ theme }) => ({
  fontSize: theme.newFontSize.get('m'),
  fontWeight: '500',
  color: theme.colors.gunmental,
}));

export const ActivityTimeWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  color: theme.colors.midnightGray,
  fontSize: theme.newFontSize.get('m'),
  fontWeight: '500',
  alignItems: 'center',
  gap: theme.newSpace.get('3xs'),
  svg: { color: theme.colors.charcoalMist },
}));

export const SelectionContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: theme.newSpace.get('s'),
  borderTop: `1px dashed ${theme.colors.mistGray}`,
}));

export const SlotStatus = styled.div<{ $isWaiting?: boolean }>(({ $isWaiting, theme }) => ({
  color: $isWaiting ? theme.colors.fieryOrange : theme.colors.electricBlue,
  fontSize: theme.newFontSize.get('m'),
  display: 'flex',
  alignItems: 'center',
  gap: theme.newSpace.get('3xs'),
  backgroundColor: $isWaiting ? theme.colors.blushWhite : theme.colors.icyBlue,
  padding: `2px ${theme.newSpace.get('3xs')}`,
  borderRadius: '4px',
  span: { fontWeight: '700' },
}));

export const SelectButton = styled.div<{ $isSelected?: boolean }>(({ $isSelected, theme }) => ({
  padding: `${theme.newSpace.get('3xs')} ${theme.newSpace.get('2xs')}`,
  background: $isSelected ? theme.colors.orange : theme.colors.cantaloupe,
  fontSize: theme.newFontSize.get('m'),
  fontWeight: '500',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: $isSelected ? theme.colors.white : theme.colors.ebony,
  borderRadius: '6px',
  gap: theme.newSpace.get('3xs'),
  border: `1px solid ${$isSelected ? theme.colors.orange : theme.colors.apricot}`,
  cursor: 'pointer',
}));

export const StyledFreeActivity = styled.span<{ $isWaiting?: boolean }>(({ $isWaiting, theme }) => ({
  background: $isWaiting ? theme.colors.orange : theme.colors.verdant,
  color: theme.colors.white,
  fontSize: theme.newFontSize.get('m'),
  fontWeight: '500',
  padding: `2px ${theme.newSpace.get('3xs')}`,
  marginLeft: theme.newSpace.get('xs'),
  borderRadius: '4px',
}));
