export const KeyboardArrowRight = ({ stroke = '#BFBFBF' }: { stroke?: string }) => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.45312 9.95906L7.71313 6.69906C8.09813 6.31406 8.09813 5.68406 7.71313 5.29906L4.45312 2.03906'
        stroke={stroke}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
