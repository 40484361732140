import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { Props, StylesConfig } from 'react-select';
import { DefaultTheme } from 'styled-components';

import useDebounce from '@src/hooks/useDebounce';
import { useGetTheme } from '@src/hooks/useGetTheme';

export const customStyles = (theme: DefaultTheme): StylesConfig => ({
  placeholder: provided => ({
    ...provided,
    color: theme.colors.charcoalMist,
    fontWeight: 600,
    fontSize: theme.newFontSize.get('l'),
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: theme.newFontSize.get('l'),
    borderColor: theme.colors.paleGray,
    boxShadow: state.isFocused ? `0 0 0 2px ${theme.colors.paleGray}` : 'none',
    backgroundColor: theme.colors.white,
    height: '58px',
  }),
  input: provided => ({
    ...provided,
    fontWeight: 600,
    fontSize: theme.newFontSize.get('l'),
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    color: theme.colors.midnightGray,
    backgroundColor: state.isSelected ? theme.colors.ivoryLace : theme.colors.white,
    '&:hover': {
      backgroundColor: theme.colors.ivoryLace,
    },
  }),
});

interface AiscSelectProps
  extends Pick<Props, 'id' | 'options' | 'onChange' | 'isMulti' | 'formatOptionLabel' | 'value'> {
  placeholder?: string;
  isMenuOpen?: boolean;
  controllableOptionMenu?: boolean;
  setIsMenuOpen?: (value: boolean) => void;
  customOnClick?: () => void;
  handleSearchText?: (value: string) => Promise<void>;
  handleScrollToBottom?: (searchText: string) => Promise<void>;
}

const AiscSelect = ({
  id,
  value,
  isMulti,
  options,
  placeholder,
  controllableOptionMenu,
  isMenuOpen,
  setIsMenuOpen,
  onChange,
  customOnClick,
  handleSearchText,
  formatOptionLabel,
  handleScrollToBottom,
}: AiscSelectProps) => {
  const { t } = useTranslation();
  const { styledTheme } = useGetTheme();

  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const debouncedHandleSearchText = useDebounce(searchText => {
    if (!handleSearchText) return;

    setLoading(true);
    handleSearchText(searchText).finally(() => {
      setLoading(false);
    });
  }, 500);

  const handleSelectClick = () => {
    if (controllableOptionMenu) customOnClick?.();
  };

  return (
    <div onClick={handleSelectClick}>
      <Select
        id={id}
        value={value}
        options={options}
        onChange={onChange}
        isMulti={isMulti}
        menuPortalTarget={document.body}
        isLoading={loading}
        {...(handleSearchText || handleScrollToBottom ? { filterOption: () => true } : {})}
        styles={customStyles(styledTheme)}
        inputValue={inputValue}
        onInputChange={inputText => {
          setInputValue(inputText);
          debouncedHandleSearchText(inputText);
        }}
        getOptionValue={option => (option as { id: string }).id}
        placeholder={placeholder ? t(placeholder) : undefined}
        onMenuScrollToBottom={() => {
          if (!handleScrollToBottom || loading) return;

          setLoading(true);
          handleScrollToBottom(inputValue).finally(() => {
            setLoading(false);
          });
        }}
        formatOptionLabel={formatOptionLabel}
        menuIsOpen={controllableOptionMenu ? !!isMenuOpen : undefined}
        onMenuClose={() => setIsMenuOpen?.(false)}
      />
    </div>
  );
};

export default AiscSelect;
