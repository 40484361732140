import { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { GenericFormModal } from '@src/components/Modals/GenericFormModal';
import { TableCreateRowLayoutProps } from '@src/components/Table/type';
import { AuthContext } from '@src/contexts/AuthContextProvider';
import { useAxiosApi } from '@src/hooks/useAxiosApi';
import { FormControllerDataReturn } from '@src/lib/types';
import { User } from '@src/Models/user';

import { formSchema } from './formSchema';
import { validationSchema } from './validationSchema';

interface HomeRoomAssignModalProps extends TableCreateRowLayoutProps, PropsWithChildren {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: () => void;
}

interface FormDataType extends FormControllerDataReturn {
  assign_from?: { value: string };
  assign_to: { value: string };
  due_date?: string;
}

const HomeRoomAssignModal: React.FC<HomeRoomAssignModalProps> = ({ isOpen, setIsOpen, children, onSubmit }) => {
  const { t } = useTranslation();
  const [staffList, setStaffList] = useState<User[]>([]);
  const [homeRoomstaffList, setHomeRoomStaffList] = useState<User[]>([]);
  const { handleGetStaffs, handleAssignHomeRoomTeacher } = useAxiosApi();
  const { userDetail } = useContext(AuthContext);

  useEffect(() => {
    if (isOpen) {
      fetchStaffList();
      fetchHomeRoomStaffList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const fetchStaffList = async () => {
    try {
      const res = await handleGetStaffs({ data: { page: 1, limit: 1000, search: '' }, isLoading: 'table' });
      setStaffList(res.data);
    } catch (error) {
      console.error('Error fetching staff list:', error);
    }
  };

  const fetchHomeRoomStaffList = async () => {
    try {
      const res = await handleGetStaffs({
        data: { page: 1, limit: 1000, search: '', only_homeroom: true },
        isLoading: 'table',
      });

      setHomeRoomStaffList(res.data);
    } catch (error) {
      console.error('Error fetching staff list:', error);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = async (formData: FormDataType) => {
    try {
      const response = await handleAssignHomeRoomTeacher({
        assigned_from: formData?.assign_from?.value,
        assigned_to: formData?.assign_to?.value,
        due_date: formData?.due_date,
      });

      if (response?.status < 400) {
        console.log('Form data successfully submitted:', response.data);
        handleClose();
        onSubmit();
      } else {
        console.error('Failed to submit form data:', response);
      }
    } catch (error) {
      console.error('Error during form submission:', error);
    }
  };

  return (
    <Box>
      <GenericFormModal
        heading={t('assignTeacher')}
        fields={formSchema(staffList, homeRoomstaffList, userDetail)}
        handleClose={handleClose}
        isOpen={isOpen}
        onSubmit={async data => {
          await handleSubmit(data as FormDataType);
        }}
        validationSchema={validationSchema(t, userDetail?.role === 'super_admin')}
      >
        {children}
      </GenericFormModal>
    </Box>
  );
};

export default HomeRoomAssignModal;
