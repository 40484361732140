import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowLeftIcon } from '@src/components/Icons/ArrowLeft';

import { Container, GeneralLayoutContainer, Header } from '../style';

interface GenreralLayoutProps extends PropsWithChildren {
  headerText: string;
  handleBack: () => void;
}

const GeneralLayout: React.FC<GenreralLayoutProps> = ({ handleBack, children, headerText }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Header
        onClick={e => {
          e.stopPropagation();
          handleBack();
        }}
      >
        <ArrowLeftIcon />
        {t(headerText)}
      </Header>
      <GeneralLayoutContainer>{children}</GeneralLayoutContainer>
    </Container>
  );
};

export default GeneralLayout;
