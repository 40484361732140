import styled from 'styled-components';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Chip, styled as muiStyled } from '@mui/material';

import { Breakpoints } from '@src/lib/styles/breakpoints';

import { StyledSubmitHeader } from '../style';

// Signup Header Styles
export const RegisterActivitiesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RegisterActivitiesText = styled.div`
  color: ${({ theme }) => theme.colors.midnightGray};
  font-size: ${({ theme }) => theme.newFontSize.get('xl')};
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.newSpace.get('xs')};
  cursor: pointer;
`;

export const StyledAvatarChip = muiStyled(Chip)(({ theme }) => ({
  height: 'fit-content',
  '.MuiChip-avatar': { marginLeft: '0px' },
  background: theme?.styledTheme.colors.paleGray,
  border: 'none',
  padding: '2px',
  '.MuiChip-label': {
    color: theme?.styledTheme.colors.charcoalMist,
    fontSize: theme?.styledTheme.newFontSize.get('l'),
    textTransform: 'capitalize',
    fontWeight: '600',
  },
}));

export const StyledMobileAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledAvatarContainer = styled.div`
  padding: 2px;
  background: ${({ theme }) => theme.colors.paleGray};
  border-radius: 50%;
`;

// Information Header
export const HeaderTop = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.newSpace.get('xl')};
  align-items: center;

  span:first-child {
    font-weight: 600;
    font-size: ${({ theme }) => theme.newFontSize.get('xl')};
  }

  span:last-child {
    font-weight: 600;
    font-size: ${({ theme }) => theme.newFontSize.get('m')};
  }
`;

export const HeaderBottom = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.newSpace.get('xs')};
  font-size: ${({ theme }) => theme.newFontSize.get('m')};

  svg {
    color: ${({ theme }) => theme.colors.charcoalGray};
    height: 16px;
    width: 16px;
  }
`;

export const StyledMobileHeaderWrapper = styled(StyledSubmitHeader)`
  padding-top: ${({ theme }) => theme.newSpace.get('m')};
  padding-bottom: ${({ theme }) => theme.newSpace.get('m')};
  background: ${({ theme }) => theme.colors.ivoryBlush};
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

export const MobileListContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.newSpace.get('3xs')};
`;

export const StyledList = styled.ul`
  margin: 0;
  padding-left: ${({ theme }) => theme.newSpace.get('3xl')};
`;

export const StyledListItem = styled.li`
  color: ${({ theme }) => theme.colors.midnightGray};
  font-weight: 500;
  font-size: ${({ theme }) => theme.newFontSize.get('m')};
`;

// Week Day Selector Header
export const WeekDaysWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.newSpace.get('4xl')};
  text-transform: uppercase;
  padding: ${({ theme }) => theme.newSpace.get('xl')} 0;
  background: ${({ theme }) => theme.colors.offWhite};
  position: sticky;
  top: -1px;
  z-index: 1;
  overflow-y: auto;
  min-height: 64px;
  align-items: center;
`;

export const DayButton = styled.div<{ $isSelected: boolean }>`
  background: ${props =>
    props.$isSelected ? ({ theme }) => theme.colors.ebony : ({ theme }) => theme.colors.paleGray};
  padding: ${({ theme }) => ` ${theme.newSpace.get('xs')} ${theme.newSpace.get('m')}`};
  font-size: ${({ theme }) => theme.newFontSize.get('m')};
  font-weight: 600;
  border-radius: 8px;
  color: ${props => (props.$isSelected ? ({ theme }) => theme.colors.white : ({ theme }) => theme.colors.ebony)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.newSpace.get('3xs')};
  cursor: pointer;
`;

export const ActivityCheckIcon = styled(CheckCircleIcon)<{ $isSelected: boolean }>`
  width: 14px;
  height: 14px;
  color: ${props => (props.$isSelected ? ({ theme }) => theme.colors.white : ({ theme }) => theme.colors.verdant)};
`;

// order summary footer style
export const StickyFooterWrapper = styled.div`
  position: sticky;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.offWhite};
  width: 100%;
  margin-top: auto;
  padding-top: ${({ theme }) => theme.newSpace.get('s')};
  ${Breakpoints.MOBILE} {
    margin: 0 -22px;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  background: white;
  padding: ${({ theme }) => ` ${theme.newFontSize.get('xl')} ${theme.newFontSize.get('3xl')}`};
  border-radius: 12px 12px 0 0;
  border: 1px solid ${({ theme }) => theme.colors.paleGray};
  border-bottom: none;
  max-width: 400px;
  width: 100%;
  flex: 1;
  ${Breakpoints.MOBILE} {
    border-radius: 0;
    max-width: none;
  }
`;

export const SummaryInfo = styled.div`
  color: ${({ theme }) => theme.colors.verdant};
  font-size: ${({ theme }) => theme.newFontSize.get('3xl')};
  font-weight: 600;
`;

export const SelectedInfo = styled.div`
  color: ${({ theme }) => theme.colors.charcoalMist};
  font-size: ${({ theme }) => theme.newFontSize.get('m')};
  font-weight: 500;

  span {
    color: ${({ theme }) => theme.colors.midnightGray};
    font-weight: 600;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.newSpace.get('m')};
  align-items: center;
  justify-content: center;
`;

export const MobileAlertMessage = styled.div`
  background: ${({ theme }) => theme.colors.ivoryBlush};
  width: 100%;
  color: ${({ theme }) => theme.colors.midnightGray};
  fontsize: ${({ theme }) => theme.newFontSize.get('m')};
  font-weight: 500;
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.apricot};
  padding: ${({ theme }) => theme.newSpace.get('s')} ${({ theme }) => theme.newSpace.get('m')};
`;

// Camp nav bar
export const TimeText = styled.div`
  white-space: nowrap;
  text-transform: lowercase;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.newSpace.get('3xs')};
`;

export const BlockText = styled.div<{ $isSelected: boolean }>`
  color: ${({ $isSelected, theme }) => ($isSelected ? theme.colors.deepGrayBorder : theme.colors.midnightGray)};
  text-transform: capitalize;
  margin-top: ${({ theme }) => theme.newSpace.get('3xs')};
`;
