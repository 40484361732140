import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { TableColumnFilterLayout } from '../CustomTableToolbar/TableColumnFilterLayout';
import { TableCreateRowLayout } from '../CustomTableToolbar/TableCreateRowLayout';
import TableDownloadSchemaLayout from '../CustomTableToolbar/TableDownloadSchemaLayout';
import { TableFilterLayout } from '../CustomTableToolbar/TableFilterLayout';
import { TableSearchLayout } from '../CustomTableToolbar/TableSearchLayout';
import { TableSyncUserLayout } from '../CustomTableToolbar/TableSyncUserLayout';
import { TableUploadButtonLayout } from '../CustomTableToolbar/TableUploadButtonLayout';
import { StyledButton } from '../style';
import { CustomTableToolbarProps } from '../type';

const TableHeaderLayout = ({ children, ...data }: CustomTableToolbarProps) => {
  const { t } = useTranslation();

  const { setSearchText, filterSchema, ctaButton, addRowData, isUserSynUp, downloadSchemaLinks, handleUploadFile } =
    data;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: '16px', flex: 1 }}>
          <TableSearchLayout setSearchText={setSearchText} />
          {filterSchema ? <TableFilterLayout {...data} /> : null}
          <TableColumnFilterLayout {...data} />
          {isUserSynUp ? <TableSyncUserLayout /> : null}
          {!!handleUploadFile ? <TableUploadButtonLayout {...data} /> : null}
          {downloadSchemaLinks ? <TableDownloadSchemaLayout {...data} /> : null}
        </Box>
        {!!addRowData ? <TableCreateRowLayout {...data}>{children}</TableCreateRowLayout> : null}
        {ctaButton ? <StyledButton onClick={ctaButton.handleClick}>{t(ctaButton.text)}</StyledButton> : null}
      </Box>
      {children}
    </Box>
  );
};

export default TableHeaderLayout;
