import React, { PropsWithChildren, useContext } from 'react';

import { Avatar, Box, Divider, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';

import { AuthContext } from '@src/contexts/AuthContextProvider';
import { useGetTheme } from '@src/hooks/useGetTheme';
import { getFullName } from '@src/lib/helper';

export interface MenuOption {
  label: string;
  key: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
  url?: string;
  onClick: (label: string) => void;
}

interface MenuProps extends PropsWithChildren {
  menuID?: string;
  options: MenuOption[];
}

const CustomMenu: React.FC<MenuProps> = ({ options, children, menuID = 'custom-menu' }) => {
  const { userDetail } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { styledTheme } = useGetTheme();

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton sx={{ borderRadius: 2 }} aria-controls={menuID} aria-haspopup='true' onClick={handleClick}>
        {children}
      </IconButton>
      <Menu
        id={menuID}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              minWidth: '350px',
              borderRadius: styledTheme.newSpace.get('4xl'),
              boxShadow: '0px 4px 20px 0px #0000001A',
            },
          },
        }}
      >
        <Box
          sx={{
            bgcolor: styledTheme.colors.white,
            display: 'flex',
            alignItems: 'center',
            gap: styledTheme.newSpace.get('m'),
            padding: styledTheme.newSpace.get('4xl'),
          }}
        >
          <Avatar
            alt='avatar'
            style={{
              background: styledTheme.colors.cantaloupe,
              color: styledTheme.colors.tangerine,
              fontSize: 16,
              width: 40,
              height: 40,
            }}
            src={userDetail?.parent_id?.image?.url}
          >
            {userDetail?.first_name?.[0]?.toUpperCase() || ''}
            {userDetail?.last_name?.[0]?.toUpperCase() || ''}
          </Avatar>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                fontSize: styledTheme.newFontSize.get('xl'),
                fontWeight: '600',
                color: styledTheme.colors.midnightGray,
                textTransform: 'capitalize',
              }}
            >
              {getFullName(userDetail?.first_name, userDetail?.last_name)}
            </Typography>
            <Typography
              sx={{
                fontSize: styledTheme.newFontSize.get('m'),
                fontWeight: '500',
                color: styledTheme.colors.charcoalMist,
              }}
            >
              {userDetail?.email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        {options.map(({ icon: Icon, label, onClick, key, url }, index) => (
          <>
            {key === 'logout' ? <Divider /> : null}
            <Box
              key={`${key}=${index}`}
              onClick={() => {
                handleClose();
                onClick(key);
              }}
              sx={{
                p: `22px ${styledTheme.newSpace.get('4xl')}`,
                display: 'flex',
                alignItems: 'center',
                gap: styledTheme.newSpace.get('m'),
                cursor: 'pointer',
                '&:hover': {
                  background: styledTheme.colors.offWhite,
                },
              }}
            >
              {Icon ? <Icon fontSize='small' style={{ marginRight: 8 }} /> : null}
              {url ? <img src={url} alt='logout' /> : null}
              <Typography
                sx={{
                  fontSize: styledTheme.newFontSize.get('xl'),
                  fontWeight: '500',
                  color: styledTheme.colors.midnightGray,
                }}
              >
                {label}
              </Typography>
            </Box>
          </>
        ))}
      </Menu>
    </div>
  );
};

export default CustomMenu;
