import styledComponents from 'styled-components';

import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputBase,
  RadioGroup,
  styled,
  Typography,
} from '@mui/material';

import { useGetTheme } from '@src/hooks/useGetTheme';
import { StyledSubmitHeader } from '@src/screens/asa/signUp/style';

export const Search = styled('div')(({ theme }) => {
  const { styledTheme } = useGetTheme();
  return {
    position: 'relative',
    borderRadius: styledTheme.newSpace.get('xs'),
    backgroundColor: styledTheme.colors.lavenderBlue,
    marginLeft: 0,
    width: '100%',
    border: `1px solid ${styledTheme.colors.paleGray}`,

    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  };
});

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  },
}));

export const StyledToolbarContainer = styled('div')(({ theme }) => {
  const { styledTheme } = useGetTheme();

  return {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: styledTheme.space.get('s'),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: 10,
      gap: 0,
    },
  };
});

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const StyledIconsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

export const StyledButton = styledComponents(Button)`
background-color: ${({ theme }) => theme.colors.orange};
color: ${({ theme }) => theme.colors.white};
padding-left: ${({ theme }) => theme.space.get('m')};
padding-right: ${({ theme }) => theme.space.get('m')};
text-transform: none;
margin: 0 ${({ theme }) => theme.space.get('2xs')};

 &:hover {
    background-color: ${({ theme }) => theme.colors.orange};
  }
`;

export const StyledFilterBox = styledComponents(Box)<{ isSelected: boolean; hasAnchorFilter: boolean }>(
  ({ theme, hasAnchorFilter, isSelected }) => ({
    display: 'flex',
    color: hasAnchorFilter ? theme.colors.indigoBlue : theme.colors.midnightGray,
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.newSpace.get('xs'),
    backgroundColor: !isSelected ? theme.colors.white : theme.colors.whisperGray,
    borderRadius: theme.newSpace.get('xs'),
    border: `1px solid ${theme.colors.paleGray}`,
    padding: `0 ${theme.newSpace.get('xl')}`,
    cursor: 'pointer',
    userSelect: 'none',
    ...(isSelected ? { zIndex: 3 } : {}),
    borderBottomLeftRadius: hasAnchorFilter ? 0 : theme.newSpace.get('xs'),
    borderBottomRightRadius: hasAnchorFilter ? 0 : theme.newSpace.get('xs'),
  })
);

export const StyledFilterText = styledComponents(Typography)(({ theme }) => ({
  fontSize: theme.newFontSize.get('l'),
  fontWeight: '500',
  mt: '2px',
}));

export const StyledCheckbox = styledComponents(Checkbox)(({ theme }) => ({
  color: theme.colors.silver,
  '& .MuiSvgIcon-root': {
    fontSize: theme.newSpace.get('xl'),
  },
}));

export const StyledFormControlLabel = styledComponents(FormControlLabel)(({ theme }) => ({
  '.MuiTypography-root': {
    fontWeight: 500,
    fontSize: theme.newFontSize.get('l'),
    padding: theme.newSpace.get('s'),
  },
}));

export const StyledResetButton = styledComponents(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: theme.newFontSize.get('m'),
  color: theme.colors.midnightGray,
}));

export const StyledApplyButton = styledComponents(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: theme.newFontSize.get('m'),
}));

export const StyledBackdrop = styledComponents(Backdrop)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 2,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backdropFilter: 'blur(4px)',
}));

export const StyledRadioGroup = styledComponents(RadioGroup)(({ theme }) => ({ maxHeight: '50vh', overflowX: 'auto' }));

export const StyledButtonsContainer = styledComponents('div')(({ theme }) => ({
  marginTop: theme.newFontSize.get('3xl'),
  display: 'flex',
  justifyContent: 'end',
  gap: theme.newSpace.get('s'),
  padding: theme.newSpace.get('s'),
}));

// **********************Table Styled Components***********************************//

export const StyledStatusFilterContainer = styledComponents(StyledSubmitHeader)(({ theme }) => ({
  paddingTop: `${theme.newSpace.get('xl')}`,
  paddingBottom: `${theme.newSpace.get('xl')}`,
  zIndex: '3',
  background: theme?.colors.offWhite,
  gap: theme.newSpace.get('4xl'),
  position: 'relative',
  border: 'none',
  marginBottom: theme.newSpace.get('xl'),
  flexDirection: 'row',
  justifyContent: 'normal',
}));

export const StyledFilterOption = styledComponents.div<{ statusSelected: boolean }>(({ statusSelected, theme }) => ({
  background: statusSelected ? theme.colors.ebony : theme.colors.paleGray,
  fontSize: theme.newSpace.get('m'),
  padding: `${theme.newSpace.get('xs')} ${theme.newSpace.get('m')}`,
  color: statusSelected ? theme.colors.white : theme.colors.ebony,
  fontWeight: '600',
  cursor: 'pointer',
  borderRadius: `8px`,
}));

export const StyledPageButtons = styledComponents.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.newSpace.get('xs'),
}));

// Table Filter Layout Styled Components

export const StyledFilterContainer = styledComponents.div({
  display: 'flex',
  width: '600px',
  height: '100%',
  flexDirection: 'column',
});

export const StyledButtonContainer = styledComponents.div(({ theme }) => ({
  background: theme.colors.lavenderBlue,
  width: '100%',
  display: 'flex',
  gap: theme.newSpace.get('4xl'),
  justifyContent: 'end',
  padding: `${theme.newSpace.get('m')} ${theme.newSpace.get('xl')}`,
  border: `1px solid ${theme.colors.paleGray}`,
}));

export const StyledReset = styledComponents.button(({ theme }) => ({
  padding: `${theme.newSpace.get('s')} ${theme.newSpace.get('m')}`,
  background: theme.colors.white,
  borderRadius: '6px',
  cursor: 'pointer',
  border: `1px solid ${theme.colors.deepGrayBorder}`,
}));

export const StyledApply = styledComponents.button(({ theme }) => ({
  background: theme.colors.orange,
  border: 'none',
  padding: `${theme.newSpace.get('s')} ${theme.newSpace.get('m')}`,
  color: theme.colors.lavenderBlue,
  borderRadius: '6px',
  cursor: 'pointer',
}));

export const StyledLeftContainer = styledComponents.div(({ theme }) => ({
  background: theme.colors.offWhite,
  height: '300px',
  overflow: 'auto',
  minWidth: '200px',
  border: `1px solid ${theme.colors.paleGray}`,
  borderTop: 'none',
}));

export const StyledRightContainer = styledComponents.div({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  height: '300px',
  overflow: 'auto',
});

export const StyledSearchContainer = styledComponents.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.newSpace.get('xl'),
  background: theme.colors.offWhite,
  gap: theme.newSpace.get('xs'),
  position: 'sticky',
  top: 0,
  zIndex: 2,
}));

export const StyledInputSearch = styledComponents.input(({ theme }) => ({
  background: 'transparent',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.newSpace.get('xs'),
  border: 'none',
  outline: 'none',
  borderBottom: 'none',
  color: theme.colors.charcoalMist,
  fontSize: theme.newFontSize.get('l'),
}));

export const StyledFilterWrapper = styledComponents.div({
  display: 'flex',
  flex: 1,
});

export const StyledLabelWrapper = styledComponents.div(({ theme }) => ({
  width: '100%',
  padding: `${theme.newSpace.get('xs')} ${theme.newSpace.get('xl')}`,
  borderBottom: `1px solid ${theme.colors.paleGray}`,
  background: theme.colors.lavenderBlue,
  display: 'flex',
  gap: theme.newSpace.get('3xs'),
}));

export const StyledFilterCountText = styledComponents.span(({ theme }) => ({
  fontSize: theme.newFontSize.get('m'),
  fontWeight: '500',
  color: theme.colors.charcoalMist,
}));

export const StyledClearSelectionText = styledComponents.span(({ theme }) => ({
  fontSize: theme.newFontSize.get('m'),
  color: theme.colors.indigoBlue,
  fontWeight: '600',
  cursor: 'pointer',
}));

export const StyledMenuItem = styledComponents.div<{ $isFilterSelected: boolean }>(({ $isFilterSelected, theme }) => ({
  color: theme.colors.midnightGray,
  ...($isFilterSelected ? { background: theme.colors.lavenderBlue } : {}),
  fontSize: theme.newFontSize.get('l'),
  padding: `${theme.newSpace.get('l')} ${theme.newSpace.get('xl')}`,
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: 'none',
  cursor: 'pointer',
}));

export const StyledFilterDetails = styledComponents.div<{ $isFilterSelected: boolean }>(
  ({ $isFilterSelected, theme }) => ({
    alignItems: 'center',
    display: 'flex',
    gap: theme.newSpace.get('s'),
    color: $isFilterSelected ? theme.colors.orange : theme.colors.silver,
  })
);

export const StyledFilterCount = styledComponents.span(({ theme }) => ({
  color: theme.colors.indigoBlue,
  fontWeight: '600',
}));

export const StyledDateSelect = styledComponents.span(({ theme }) => ({
  background: theme.colors.indigoBlue,
  width: '6px',
  height: '6px',
  borderRadius: '50%',
}));

export const StyledCheckBox = styled(Checkbox)(() => {
  const { styledTheme } = useGetTheme();
  return {
    border: 'none',
    color: styledTheme.colors.paleGray,
    '& .MuiSvgIcon-root': {
      borderRadius: '20px',
      width: '16px',
      height: '16px',
    },
    '&.Mui-checked': {
      color: styledTheme.colors.orange,
    },
  };
});

export const StyledNoDataFound = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  mt: 2,
});
