export const ArrowLeftIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.4995 16.5999L7.06621 11.1666C6.42454 10.5249 6.42454 9.4749 7.06621 8.83324L12.4995 3.3999'
      stroke='#1E1E1E'
      stroke-width='2'
      stroke-miterlimit='10'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
