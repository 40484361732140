import styled from 'styled-components';

import { Menu, styled as muiStyled } from '@mui/material';

import { ContentContainer } from '@src/screens/asa/signUp/style';

import { Breakpoints } from './breakpoints';
import { FontSizes } from './theme';

export const ButtonGroup = styled.div<{ $isSubmitPositionRight?: boolean }>`
  display: flex;
  ${({ $isSubmitPositionRight }) => ($isSubmitPositionRight ? 'flex-direction: row-reverse;' : '')}
  gap: ${({ theme }) => theme.space.get('s')};
`;
export const StyledLineContainer = styled.div<{ $size?: FontSizes }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: ${({ theme }) => theme.colors.spanishGray};
  font-size: ${({ theme, $size }) => theme.fontSize.get($size || 'xs')};
  margin-bottom: ${({ theme }) => theme.space.get('s')};
  gap: ${({ theme }) => theme.space.get('s')};
`;

export const StyledMenu = muiStyled(Menu)(() => ({
  zIndex: 3,
  '& .MuiMenu-list': {
    padding: 0,
  },
  '& .MuiPopover-paper': {
    padding: 0,
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '24px',
    borderBottomLeftRadius: '24px',
    borderBottomRightRadius: '24px',
  },
}));

export const StyledContentContainer = styled(ContentContainer)`
  width: 100%;
  padding: ${({ theme }) => theme.space.get('s')};
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.lavenderBlue};
  color: ${({ theme }) => theme.colors.inkShade};
`;

export const StyledTableWrapper = styled.div`
  width: 100%;
`;

export const StyledDetailsCardWrapper = styled.div<{ $display?: string }>`
  width: 100%;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.lavenderBlue};
  border-radius: ${({ theme }) => theme.space.get('s')};
  border: 1px solid ${({ theme }) => theme.colors.deepGrayBorder};
  padding: ${({ theme }) => theme.space.get('s')};
  ${({ $display, theme }) =>
    $display &&
    `
    display: ${$display}; 
    gap: ${theme.space.get('s')};
  
    ${Breakpoints.TABLET} {
      flex-direction: column-reverse;
    }
  `}
`;

export const UserSelectNone = styled.div`
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
