import React, { useState } from 'react';
import { t } from 'i18next';

import { Box, FormControlLabel, RadioGroup, Typography } from '@mui/material';

import FormController from '@src/components/FormController';
import { CheckboxIcon } from '@src/components/Icons/CheckboxIcon';
import { KeyboardArrowRight } from '@src/components/Icons/KeyboardArrowRight';
import { NewCheckedBoxIcon } from '@src/components/Icons/NewCheckedBoxIcon';
import { formSchema } from '@src/components/Modals/FilterModal/formSchema';
import { RadioGroupWrapper } from '@src/components/Modals/FilterModal/style';
import { FiltersType } from '@src/components/Modals/FilterModal/type';
import {
  StyledApply,
  StyledBackdrop,
  StyledButtonContainer,
  StyledCheckBox,
  StyledClearSelectionText,
  StyledDateSelect,
  StyledFilterBox,
  StyledFilterContainer,
  StyledFilterCount,
  StyledFilterCountText,
  StyledFilterDetails,
  StyledFilterText,
  StyledFilterWrapper,
  StyledInputSearch,
  StyledLabelWrapper,
  StyledLeftContainer,
  StyledMenuItem,
  StyledNoDataFound,
  StyledReset,
  StyledRightContainer,
  StyledSearchContainer,
} from '@src/components/Table/style';
import useDebounce from '@src/hooks/useDebounce';
import { StyledMenu } from '@src/lib/styles/globalWrapper';

import { TableFilterLayoutProps } from '../type';

export const TableFilterLayout: React.FC<TableFilterLayoutProps> = ({ applyFilters, filterSchema, filterCount }) => {
  const [anchorFilter, setAnchorFilter] = useState<null | HTMLElement>(null);
  const [isSelected, setSelected] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState<FiltersType>({});
  const [dateValues, setDateValues] = useState<{ date: string }>();
  const [selectedFilterModule, setSelectedFilterModule] = useState<number>(0);
  const [searchText, setSearchText] = useState('');

  const filters = filterSchema?.filterOptions || [];
  const values = filters?.[selectedFilterModule]?.value;
  const isDateSelected = filters?.[selectedFilterModule].label === 'date';
  const filtersSelectedLength = Object.keys(selectedFilters[filters[selectedFilterModule].key] || {}).length || 0;
  const filterOptions = filterSchema?.filterOptions;
  const handleSearch = filterSchema?.handleSearch;

  const handleDebounceSearch = useDebounce((value: string) => {
    handleSearch?.({ value, selectedFilterModule: filters[selectedFilterModule].key });
  }, 500);

  const handleFilterChange = (column: string, checked: boolean) => {
    const key = filters[selectedFilterModule].key;

    if (key === 'date') {
      setDateValues({ date: column });
      setSelectedFilters(prev => ({ ...prev, [key]: { [column]: true } }));
      return;
    }

    setSelectedFilters(prevSelected => {
      const updatedModule = { ...prevSelected[key] };

      if (checked) {
        updatedModule[column] = checked;
      } else {
        delete updatedModule[column];
      }

      const updatedFilters = {
        ...prevSelected,
        [key]: updatedModule,
      };

      if (!Object.keys(updatedFilters[key]).length) {
        delete updatedFilters[key];
      }

      return updatedFilters;
    });
  };

  const handleClose = () => {
    setAnchorFilter(null);
    setSelected(false);
  };

  const handleApplyFilters = () => {
    const customisedFilters: Record<string, string> = {};
    Object.keys(selectedFilters).forEach(filterKey => {
      const filterValues = selectedFilters[filterKey];

      customisedFilters[filterKey] = Object.keys(filterValues).join(',');
    });
    applyFilters(customisedFilters);

    handleClose();
  };

  const handleResetFilters = () => {
    setSelectedFilters({});
    applyFilters({});
    handleClose();
  };

  const handleClearSelection = (selectedFilterKey: string = '') => {
    const { [selectedFilterKey]: _, ...restFilters } = selectedFilters;
    setSelectedFilters(restFilters);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollHeight, scrollTop, clientHeight } = event?.target as HTMLElement;

    const isAtBottom = scrollHeight - scrollTop - 10 <= clientHeight;

    if (isAtBottom) {
      filterSchema?.handleScrollToBottom?.(filters?.[selectedFilterModule].label || '');
    }
  };

  const handleOpenFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorFilter(event.currentTarget);
    setSelected(true);
  };

  const handleCloseFilterMenu = () => {
    setAnchorFilter(null);
    setSelected(false);
  };

  return (
    <>
      <StyledFilterBox isSelected={isSelected} hasAnchorFilter={!!anchorFilter} onClick={handleOpenFilterMenu}>
        <img
          src={isSelected ? '/icons/filter-selected.svg' : '/icons/filter.svg'}
          alt='filter'
          style={{ width: '16px', height: '16px' }}
        />
        <StyledFilterText>{t('filters')}</StyledFilterText>
      </StyledFilterBox>
      <StyledBackdrop onClick={handleCloseFilterMenu} open={Boolean(anchorFilter)} />
      <StyledMenu
        id='menu-appbar-filter'
        anchorEl={anchorFilter}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorFilter)}
        onClose={handleCloseFilterMenu}
      >
        <StyledFilterContainer>
          <StyledFilterWrapper>
            <StyledLeftContainer onScroll={handleScroll}>
              {filterOptions?.map((item, index) => {
                const isFilterSelected = filters?.[selectedFilterModule].key === item?.key || false;
                const selectedCount = Object.keys(selectedFilters?.[item?.key] || {})?.length || 0;

                return (
                  <React.Fragment key={item?.key}>
                    <StyledMenuItem
                      $isFilterSelected={isFilterSelected}
                      onClick={() => {
                        setSelectedFilterModule(index);
                        setSearchText('');
                      }}
                    >
                      <Typography
                        variant='body1'
                        color='text.primary'
                        style={{
                          textTransform: 'capitalize',
                          ...(isFilterSelected ? { color: '#D97415' } : { color: '#3D3D3D' }),
                        }}
                      >
                        {t(item.label)}
                      </Typography>
                      <StyledFilterDetails $isFilterSelected={isFilterSelected}>
                        {selectedCount ? (
                          item?.label === 'date' ? (
                            <StyledDateSelect />
                          ) : (
                            <StyledFilterCount>{selectedCount}</StyledFilterCount>
                          )
                        ) : null}
                        <KeyboardArrowRight {...(isFilterSelected ? { stroke: '#D97415' } : {})} />
                      </StyledFilterDetails>
                    </StyledMenuItem>
                  </React.Fragment>
                );
              })}
            </StyledLeftContainer>
            <StyledRightContainer>
              <StyledSearchContainer>
                <img src='/icons/search-normal.svg' alt='searchIcon' width={'20px'} height={'20px'} color='#707070' />
                <StyledInputSearch
                  placeholder='Search'
                  onChange={({ target: { value } }) => {
                    setSearchText(value);
                    handleDebounceSearch(value);
                  }}
                  value={searchText}
                />
              </StyledSearchContainer>
              <StyledLabelWrapper>
                {!isDateSelected && (
                  <StyledFilterCountText>
                    {filtersSelectedLength} {t('selected')}.
                  </StyledFilterCountText>
                )}
                <StyledClearSelectionText onClick={() => handleClearSelection(filters[selectedFilterModule].key)}>
                  {isDateSelected ? t('clear') : t('clearAll')}
                </StyledClearSelectionText>
              </StyledLabelWrapper>
              <RadioGroup>
                {typeof values === 'string' ? (
                  <Box sx={{ p: 2 }}>
                    <FormController
                      fields={formSchema(handleFilterChange)}
                      isSubmitButton={false}
                      values={dateValues}
                    />
                  </Box>
                ) : values.length ? (
                  <>
                    {values.map((column, i) => {
                      const value = typeof column === 'string' ? column : column.value;
                      const label = typeof column === 'string' ? column : column.label;

                      return (
                        <RadioGroupWrapper key={value + i} sx={{ px: 2, py: 1 }}>
                          <FormControlLabel
                            control={
                              <StyledCheckBox
                                checkedIcon={<NewCheckedBoxIcon />}
                                icon={<CheckboxIcon />}
                                checked={selectedFilters[filters[selectedFilterModule].key]?.[value] || false}
                                onChange={event => handleFilterChange(value, event.target.checked)}
                              />
                            }
                            label={label}
                          />
                        </RadioGroupWrapper>
                      );
                    })}
                  </>
                ) : (
                  <StyledNoDataFound>{t('noFilterDataFound')}</StyledNoDataFound>
                )}
              </RadioGroup>
            </StyledRightContainer>
          </StyledFilterWrapper>
          <StyledButtonContainer>
            <StyledReset onClick={handleResetFilters}>{t('resetAll')}</StyledReset>
            <StyledApply onClick={handleApplyFilters}>{t('applyNow')}</StyledApply>
          </StyledButtonContainer>
        </StyledFilterContainer>
      </StyledMenu>
    </>
  );
};
