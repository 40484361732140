import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';

import { ButtonGroup } from '@src/lib/styles/globalWrapper';
import { FormControllerDataReturn } from '@src/lib/types';

import { FieldLayout } from './FieldLayout';
import { FormActionContainer, FormContainer, StyledButton } from './style';
import { FormControllerProps } from './type';

const FormController: FC<FormControllerProps> = ({
  fields = [],
  validationSchema,
  onSubmit,
  submitButton = 'submit',
  children,
  backButton,
  submitButtonFullWidth = true,
  values,
  customSubmitSubmission,
  getFormController,
  isSubmitPositionRight = false,
  hasSaveAsDraft,
  isValid: customIsValid = true,
  isBackEnable = true,
  onlyDirtyValues,
  submitButtonColor,
  isSubmitButton = true,
  getIsValid,
  handleCustomBackButton,
  backButtonColor,
}) => {
  const formController = useForm(validationSchema ? { resolver: yupResolver(validationSchema), mode: 'all' } : {});

  const {
    handleSubmit,
    getValues,
    reset,
    trigger,
    formState: { isValid, dirtyFields },
  } = formController;

  const { t } = useTranslation();
  const navigation = useNavigate();

  const handleFormSubmit = (data: FormControllerDataReturn, isDraft = false) => {
    const formSubmitData: FormControllerDataReturn = {};

    Object.keys(dirtyFields).forEach(key => {
      formSubmitData[key] = data[key];
    });

    onSubmit?.({ ...(onlyDirtyValues ? formSubmitData : data), ...(hasSaveAsDraft ? { isDraft } : {}) });
  };

  const handleBackButton = () => {
    if (handleCustomBackButton) {
      handleCustomBackButton();
    } else {
      navigation(-1);
    }
  };

  const handleCustomSubmitSubmission = async (isDraft: boolean) => {
    const isFormValid = await trigger();

    if (!isFormValid) return;

    const fieldValues = getValues();
    handleFormSubmit(fieldValues, isDraft);
  };

  useEffect(() => {
    if (values === null) return reset();

    if (values) {
      const currentValues = getValues();
      reset({ ...currentValues, ...values });
    }
  }, [values, getValues, reset]);

  useEffect(() => {
    if (!(getFormController && formController)) return;

    getFormController(formController);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formController]);

  useEffect(() => {
    if (!getIsValid) return;

    getIsValid(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  return (
    <FormContainer onSubmit={handleSubmit(data => handleFormSubmit(data, false))}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {fields?.map(item => (
          <FieldLayout key={item.name} {...item} formController={formController} />
        ))}
      </Grid>

      <FormActionContainer>
        {children}
        {isSubmitButton ? (
          <ButtonGroup $isSubmitPositionRight={isSubmitPositionRight}>
            {hasSaveAsDraft ? (
              <StyledButton
                variant='contained'
                color='info'
                type='button'
                disabled={!(customIsValid && isValid)}
                $isFullWidth={submitButtonFullWidth}
                onClick={() => handleCustomSubmitSubmission(true)}
              >
                {t('saveAsDraft')}
              </StyledButton>
            ) : null}
            <StyledButton
              variant='contained'
              color='info'
              type={customSubmitSubmission ? 'button' : 'submit'}
              disabled={!(customIsValid && isValid)}
              $isFullWidth={submitButtonFullWidth}
              $submitButtonColor={submitButtonColor}
              {...(customSubmitSubmission ? { onClick: () => handleCustomSubmitSubmission(false) } : {})}
            >
              {t(submitButton)}
            </StyledButton>
            {backButton ? (
              <StyledButton
                variant='contained'
                color='error'
                type='button'
                disabled={!isBackEnable}
                $isCancel
                onClick={handleBackButton}
                $submitButtonColor={backButtonColor}
                $isFullWidth={submitButtonFullWidth}
              >
                {t(backButton)}
              </StyledButton>
            ) : null}
          </ButtonGroup>
        ) : null}
      </FormActionContainer>
    </FormContainer>
  );
};

export default FormController;
