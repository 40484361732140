import { createContext, Dispatch, SetStateAction, useState } from 'react';

import { Program } from '@src/Models/program';

import DropdownLayout from './DropdownLayout';
import ViewSchedule from './ViewSchedule';

interface SignUpContextProps {
  selectedProgram?: Program;
  setSelectedProgram?: (value: Program) => void;
  isProgramMobileDrawerOpen?: boolean;
  setIsProgramMobileDrawerOpen?: Dispatch<SetStateAction<boolean>>;
  isProgramWebDrawerOpen?: boolean;
  setIsProgramWebDrawerOpen?: Dispatch<SetStateAction<boolean>>;
}

export const SignUpContext = createContext<SignUpContextProps>({});

const SignUp = () => {
  const [selectedProgram, setSelectedProgram] = useState<Program>();
  const [isProgramMobileDrawerOpen, setIsProgramMobileDrawerOpen] = useState(false);
  const [isProgramWebDrawerOpen, setIsProgramWebDrawerOpen] = useState(false);

  return (
    <SignUpContext.Provider
      value={{
        selectedProgram,
        setSelectedProgram,
        isProgramMobileDrawerOpen,
        setIsProgramMobileDrawerOpen,
        isProgramWebDrawerOpen,
        setIsProgramWebDrawerOpen,
      }}
    >
      <DropdownLayout />
      <ViewSchedule />
    </SignUpContext.Provider>
  );
};

export default SignUp;
