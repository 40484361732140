import { ReactNode, useCallback, useContext, useEffect } from 'react';

import { Box, Typography, useMediaQuery } from '@mui/material';

import AiscSelect from '@src/components/AiscSelect';
import { useAxiosApi } from '@src/hooks/useAxiosApi';
import { useGetTheme } from '@src/hooks/useGetTheme';
import { useScrollAndSearch } from '@src/hooks/useScrollAndSearch';
import { initialTableFilterProps } from '@src/lib/constants';
import { formatDate } from '@src/lib/helper';
import { Program } from '@src/Models/program';

import { SignUpContext } from '..';

import MobileDrawer from './MobileDrawer';

const ProgramDropdown = () => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  const {
    selectedProgram,
    setSelectedProgram,
    setIsProgramMobileDrawerOpen,
    isProgramWebDrawerOpen,
    setIsProgramWebDrawerOpen,
  } = useContext(SignUpContext);

  const { handleGetProgramsApi } = useAxiosApi();

  const {
    data: programs,
    setData: setPrograms,
    handleSearchText: handleSearchTextPrograms,
    handleScrollToBottom: handleScrollToBottomPrograms,
  } = useScrollAndSearch<Program>({
    api: handleGetProgramsApi,
    filterProps: { active: true },
  });

  const programsScrollAndSearch = {
    handleSearchText: handleSearchTextPrograms,
    handleScrollToBottom: handleScrollToBottomPrograms,
  };

  const fetchPrograms = useCallback(async () => {
    try {
      const res = await handleGetProgramsApi({ data: { ...initialTableFilterProps, active: true } });
      setPrograms(res);
    } catch (error) {
      console.log('Error', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleGetProgramsApi]);

  useEffect(() => {
    fetchPrograms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrawerToggle = () => {
    if (isDesktop) {
      if (!isProgramWebDrawerOpen) {
        setIsProgramWebDrawerOpen?.(true);
      }
    } else {
      setIsProgramMobileDrawerOpen?.(prev => !prev);
    }
  };

  const { styledTheme } = useGetTheme();

  const customOption = (data: unknown): ReactNode => {
    const program = data as Program;
    return (
      <Box>
        <Typography
          sx={{
            color: styledTheme.colors.midnightGray,
            fontWeight: '600',
            fontSize: styledTheme.newFontSize.get('l'),
            pl: 1,
          }}
        >
          {program.name}
        </Typography>
        <Typography
          sx={{
            color: styledTheme.colors.charcoalMist,
            fontWeight: '500',
            fontSize: styledTheme.newFontSize.get('m'),
            pl: 1,
          }}
        >
          {`${formatDate(program.start_date)} - ${formatDate(program.end_date)}`}
        </Typography>
      </Box>
    );
  };

  const handleOnChange = (data: unknown) => {
    const program = data as Program;
    setSelectedProgram?.(program);
  };

  return (
    <>
      <AiscSelect
        id='programs'
        value={selectedProgram}
        options={programs}
        placeholder='selectProgram'
        {...programsScrollAndSearch}
        formatOptionLabel={customOption}
        onChange={handleOnChange}
        controllableOptionMenu
        isMenuOpen={isProgramWebDrawerOpen}
        setIsMenuOpen={setIsProgramWebDrawerOpen}
        customOnClick={handleDrawerToggle}
      />
      <MobileDrawer programs={programs} />
    </>
  );
};

export default ProgramDropdown;
