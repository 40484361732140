import { Button, Dialog, DialogActions, DialogContent, styled, Typography } from '@mui/material';

export const StyledDialog = styled(Dialog)({
  '.MuiDialog-paper': {
    borderRadius: '24px',
  },
});

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme?.styledTheme.newSpace.get('xl'),
}));

export const StyledHeading = styled(Typography)(({ theme }) => ({
  color: theme?.styledTheme.colors.midnightGray,
  fontSize: theme?.styledTheme.newFontSize.get('2xl'),
  fontWeight: 700,
}));

export const StyledText = styled(Typography)(({ theme }) => ({
  color: theme?.styledTheme.colors.midnightGray,
  fontSize: theme?.styledTheme.newFontSize.get('l'),
  fontWeight: 500,
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme?.styledTheme.newSpace.get('4xl'),
  paddingTop: '0px',
  gap: theme?.styledTheme.newSpace.get('s'),
}));

export const NoButton = styled(Button)(({ theme }) => ({
  background: theme?.styledTheme.colors.orange,
  boxShadow: 'none',
  textTransform: 'capitalize',
  color: 'white',
  whiteSpace: 'nowrap',
  '&:hover': {
    background: theme?.styledTheme.colors.orange,
  },
}));

export const YesButton = styled(Button)(({ theme }) => ({
  background: theme?.styledTheme.colors.ivoryLace,
  boxShadow: 'none',
  whiteSpace: 'nowrap',
  textTransform: 'capitalize',
  color: theme?.styledTheme.colors.orange,
  '&:hover': {
    background: theme?.styledTheme.colors.ivoryLace,
  },
}));
