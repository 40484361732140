import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipRenderProps } from 'react-joyride';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import { AuthContext } from '@src/contexts/AuthContextProvider';
import { GlobalUIContext } from '@src/contexts/GlobalUIContext';
import { useAxiosApi } from '@src/hooks/useAxiosApi';
import { useGetTheme } from '@src/hooks/useGetTheme';
import { getCookie } from '@src/utils/getCookie';

import {
  StyledButton,
  StyledContainer,
  StyledContent,
  StyledFooter,
  StyledHeading,
  StyledPrimaryButton,
} from './style';
import { StepsProps } from '.';

interface CustomToolTipProps extends TooltipRenderProps {
  phase: number;
  isSkip?: boolean;
}

const CustomToolTip = ({ index, size, backProps, primaryProps, step, phase, isSkip }: CustomToolTipProps) => {
  const { handleUpdateTuts } = useAxiosApi();
  const { t } = useTranslation();
  const { handleSetUserDetails } = useContext(AuthContext);
  const { setWalkThrough } = useContext(GlobalUIContext);
  const { styledTheme } = useGetTheme();
  const currentStep = step as StepsProps;

  return (
    <StyledContainer>
      <StyledHeading>{t(currentStep.heading)}</StyledHeading>
      <StyledContent>{t(currentStep.content as string)}</StyledContent>
      <StyledFooter>
        <StyledContent>{`${index + 1}/${size}`}</StyledContent>
        <Box sx={{ display: 'flex', gap: styledTheme.newSpace.get('xs') }}>
          {index > 0 && (
            <StyledButton
              {...backProps}
              onClick={() => {
                setWalkThrough?.(prev => ({ ...prev, stepIndex: prev.stepIndex - 1 }));
              }}
            >
              <KeyboardArrowLeft />
            </StyledButton>
          )}
          <StyledPrimaryButton
            {...primaryProps}
            onClick={async () => {
              try {
                if (index + 1 === size) {
                  await handleUpdateTuts({ status: false, phase_number: phase });

                  const tokenValue = getCookie('access_token');
                  await handleSetUserDetails(tokenValue || '');

                  setWalkThrough?.(prev => ({
                    ...prev,
                    stepIndex: 0,
                    phase: isSkip ? prev.phase : prev.phase + 1,
                    tourRunning: false,
                  }));
                  return;
                }

                setWalkThrough?.(prev => ({
                  ...prev,
                  stepIndex: prev.stepIndex + 1,
                }));
              } catch (e) {
                console.error(e);
              }
            }}
          >
            {index + 1 === size ? (
              <Typography sx={{ fontWeight: '500', fontSize: styledTheme.newFontSize.get('m') }}>
                {t('done')}
              </Typography>
            ) : (
              <KeyboardArrowRight />
            )}
          </StyledPrimaryButton>
        </Box>
      </StyledFooter>
    </StyledContainer>
  );
};

export default CustomToolTip;
