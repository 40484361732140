import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { KeyboardArrowRight } from '@mui/icons-material';
import { Avatar, Box, Typography, useMediaQuery } from '@mui/material';
import { googleLogout } from '@react-oauth/google';

import { AuthContext } from '@src/contexts/AuthContextProvider';
import { useGetTheme } from '@src/hooks/useGetTheme';
import { getFullName } from '@src/lib/helper';

const ProfilesDrawerLayout = () => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const { userDetail, removeAuthToken } = useContext(AuthContext);
  const { t } = useTranslation();
  const { styledTheme } = useGetTheme();

  const handleProfileNavigation = (id: string) => {
    const currentPath = window.location.pathname;
    navigate(`${currentPath}?selectedProfileId=${id}`);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: styledTheme.newSpace.get('4xl'), flex: 1 }}>
      <Box
        sx={{
          p: `${styledTheme.newSpace.get('xl')} ${styledTheme.newSpace.get('m')}`,
          bgcolor: styledTheme.colors.white,
          borderRadius: '12px',
          display: 'flex',
          alignItems: 'center',
          gap: '3.5px',
        }}
      >
        <Avatar
          alt='avatar'
          style={{
            background: styledTheme.colors.cantaloupe,
            color: styledTheme.colors.tangerine,
            fontSize: 16,
            width: 48,
            height: 48,
          }}
          src={userDetail?.parent_id?.image?.url}
        >
          {userDetail?.first_name?.[0]?.toUpperCase() || ''}
          {userDetail?.last_name?.[0]?.toUpperCase() || ''}
        </Avatar>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              fontSize: styledTheme.newFontSize.get('xl'),
              fontWeight: '500',
              color: styledTheme.colors.midnightGray,
              textTransform: 'capitalize',
            }}
          >
            {getFullName(userDetail?.first_name, userDetail?.last_name)}
          </Typography>
          <Typography
            sx={{
              fontSize: styledTheme.newFontSize.get('l'),
              fontWeight: '500',
              color: styledTheme.colors.charcoalMist,
            }}
          >
            {userDetail?.email}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: styledTheme.newSpace.get('m'),
          flex: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: styledTheme.newFontSize.get('xl'),
            fontWeight: '600',
            color: styledTheme.colors.midnightGray,
          }}
        >
          {t('myKids')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: styledTheme.newSpace.get('l'),
          }}
        >
          {userDetail?.children.map(child => (
            <Box
              sx={{
                p: `${styledTheme.newSpace.get('xl')} ${styledTheme.newSpace.get('m')}`,
                bgcolor: styledTheme.colors.white,
                borderRadius: '12px',
                display: 'flex',
                alignItems: 'center',
                gap: '3px',
                cursor: 'pointer',
              }}
              onClick={e => {
                e.stopPropagation();
                handleProfileNavigation(child.id);
              }}
            >
              <Avatar
                alt='avatar'
                style={{
                  background: styledTheme.colors.cantaloupe,
                  color: styledTheme.colors.tangerine,
                  fontSize: 16,
                  width: 40,
                  height: 40,
                }}
                src={child?.image?.url}
              >
                {child?.first_name?.[0]?.toUpperCase() || ''}
                {child?.last_name?.[0]?.toUpperCase() || ''}
              </Avatar>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: styledTheme.newFontSize.get('l'),
                    fontWeight: '500',
                    color: styledTheme.colors.midnightGray,
                    textTransform: 'capitalize',
                  }}
                >
                  {getFullName(child?.first_name, userDetail?.last_name)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: styledTheme.newFontSize.get('m'),
                    fontWeight: '500',
                    color: styledTheme.colors.charcoalMist,
                  }}
                >
                  Grade {child?.grade_level}
                </Typography>
              </Box>
              <KeyboardArrowRight fontSize='small' />
            </Box>
          ))}
        </Box>
      </Box>
      {isDesktop ? null : (
        <Box
          sx={{
            p: `${styledTheme.newSpace.get('xl')} ${styledTheme.newSpace.get('m')}`,
            bgcolor: styledTheme.colors.white,
            borderRadius: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: styledTheme.newSpace.get('xs'),
            cursor: 'pointer',
          }}
          onClick={() => {
            googleLogout();
            removeAuthToken();
          }}
        >
          <img src='/icons/logout.svg' alt='logout' />
          <Typography
            sx={{
              fontSize: styledTheme.newFontSize.get('l'),
              fontWeight: '500',
              color: styledTheme.colors.midnightGray,
            }}
          >
            Logout
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ProfilesDrawerLayout;
