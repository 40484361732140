import { useContext } from 'react';
import { AxiosError } from 'axios';

import {
  activityMappingBulkImport,
  activitySignUp,
  createActivity,
  deleteActivity,
  deleteActivitySignUp,
  getActivity,
  getActivityById,
  getActivityByIdEnrolled,
  getActivitySchedule,
  getSignUpById,
  getSignUpList,
  processingSignUp,
  updateActivity,
  updateSignUpById,
  updateSignUpPaymentStatus,
} from '@src/api/activity';
import {
  createActivityMapping,
  createGoogleSheetSync,
  createProgram,
  deleteActivityMapping,
  deleteActivityMappingList,
  getActivityMapping,
  getActivityMappingById,
  getActivityMappingByMappingId,
  getGoogleSheetSync,
  getProgramById,
  getPrograms,
  getWaitingList,
  manuallyAssign,
  programCheckout,
  softDeletePrograms,
  updateActivityMapping,
  updateGoogleSheetSync,
  updateProgram,
} from '@src/api/asa';
import {
  createSeason,
  createSession,
  createSessionMapping,
  deleteSeason,
  deleteSession,
  deleteSessionMapping,
  getSeason,
  getSeasonById,
  getSession,
  getSessionById,
  getSessionMapping,
  getSessionMappingById,
  updateSeason,
  updateSession,
  updateSessionMapping,
} from '@src/api/asaMaster';
import {
  createAttribute,
  createAttributeValue,
  deleteAttribute,
  deleteAttributeValue,
  getAttributeById,
  getAttributes,
  updateAttribute,
  updateAttributeValue,
} from '@src/api/attribute';
import { getBudgetCode } from '@src/api/budgetCode';
import { campPaymentsXcelUpload, CampPaymentsXcelUploadProps } from '@src/api/campPaymnetExcelUpload';
import { createCategory, deleteCategory, getCategory, getCategoryById, updateCategory } from '@src/api/category';
import { createCategoryType, getCategoryType, getCategoryTypeById, updateCategoryType } from '@src/api/categoryType';
import { bulkImport, deleteContractor, getContractor, getContractors, updateContractor } from '@src/api/contractors';
import {
  createCostCentres,
  deleteCostCentre,
  editCostCentres,
  getCostCentre,
  getCostCentres,
} from '@src/api/costCentre';
import { getDepartments } from '@src/api/department';
import { downloadAllProgramSheet, downloadSheet, DownloadSheetProps } from '@src/api/dowloadSheet';
import {
  downloadDaywiseProgramSchedule,
  DownloadDaywiseProgramScheduleProps,
} from '@src/api/downloadDaywiseProgramSchedule';
import { downloadProgramSubmissions, DownloadProgramSubmissionsProps } from '@src/api/downloadProgramSubmissions';
import { downloadTransactions, DownloadTransactionsProps } from '@src/api/downloadTransactions';
import { downloadWaitingActivityList, DownloadWaitingActivityListProps } from '@src/api/downloadWaitingActivityList';
import { downloadWeeklySchedule, DownloadWeeklyScheduleProps } from '@src/api/downloadWeeklySchedule';
import { generateS3Url } from '@src/api/fileUpload';
import {
  assignHomeRoomTeacher,
  deleteAssignHomeRoomTeacher,
  getHomeroomTeacherById,
  getHomeroomTeachers,
} from '@src/api/homeRoomTeachers';
import {
  adminLogin,
  getUserDetails,
  signUpWithGoogle,
  userLogout,
  verify2fa,
  verify2faRecoveryCode,
} from '@src/api/login';
import { getApiRequestsLogs, getPaymentTransactionsLogs, getSyncLogs } from '@src/api/logs';
import { getOrganizationSettings, resetPoSerial, updateOrganizationSettings } from '@src/api/organizationSettings';
import { getParentByID, getParents, photoUpload } from '@src/api/parent';
import { createPaymentLink, getPaymentLinkById, getPaymentLinks, getPaymentStatus } from '@src/api/payment';
import { sendPaymentReminder } from '@src/api/paymentReminder';
import {
  addNewProductVariant,
  createProductCategory,
  deleteProductCategory,
  deleteProductVariant,
  editOrder,
  getMerchandiseOrders,
  getOrder,
  getProductCategories,
  getProductCategoryById,
  getProductVariantById,
  placeProductOrder,
  updateProductCategory,
  updateProductDetails,
  updateProductVariantDetails,
} from '@src/api/productCategory';
import {
  addToCart,
  createProduct,
  deleteCartProduct,
  editProduct,
  getCartDetails,
  getCartProductCount,
  getProductById,
  getProducts,
  softDeleteProducts,
} from '@src/api/products';
import {
  createPurchaseOrder,
  editPurchaseOrder,
  editPurchaseOrderStatus,
  getPurchaseOrder,
  getPurchaseOrderById,
  getReviewPurchaseOrder,
  getReviewPurchaseOrderById,
} from '@src/api/purchaseOrders';
import { createRoles, editRoles, getModules, getRoleById, getRoles } from '@src/api/rolesAndPermissions';
import {
  createApprovalRulesAmount,
  createApprovalRulesUsers,
  deleteApprovalRulesAmount,
  deleteApprovalRulesUsers,
  getApprovalRulesAmount,
  getApprovalRulesAmountById,
  getApprovalRulesUsers,
  getApprovalRulesUsersById,
  updateApprovalRulesAmount,
  updateApprovalRulesUsers,
} from '@src/api/rules';
import {
  createOffDays,
  deleteOffDaysByID,
  getOffDays,
  getOffDaysById,
  getScheduleActivities,
  getSchedules,
  getSchedulesFilterList,
  updateOffDays,
} from '@src/api/schedule';
import { getSchoolLevel, SchoolLevelProductData } from '@src/api/schoolLevel';
import { getAllDepartments, getDesignations, getStaffByID, getStaffCategories, getStaffs } from '@src/api/staff';
import {
  createAsaAttendance,
  getAsaAttendance,
  getMyStudentsAttendance,
  getStudent,
  getStudentById,
} from '@src/api/student';
import { syncUserData } from '@src/api/syncup';
import { createTag, getTags } from '@src/api/tags';
import { UpdateTutorialProps, updateTuts } from '@src/api/tuts';
import { disable2fa, enable2FA, generateCode, getRecoveryCode } from '@src/api/twoFactorAuth';
import {
  addExtraPermissionsType,
  AddToCartProps,
  AsaAttendanceProps,
  CreateAttributeValue,
  FileUploadType,
  GetTableFilterProps,
  SignUpDataProps,
  UpdateAttributeValue,
  UploadChunkProps,
} from '@src/api/type';
import {
  addExtraPermissions,
  createUser,
  getUserById,
  getUsersApi,
  NickNameData,
  switchUserRole,
  updateExtraPermissions,
  updateNickname,
  updateUser,
} from '@src/api/users';
import { createVendor, editVendor, getVendors, getVendorsById } from '@src/api/vendors';
import { CategoryModulesType } from '@src/components/Category';
import { SelectOptionType } from '@src/components/FormController/type';
import { GlobalUIContext, LoadingType } from '@src/contexts/GlobalUIContext';
import { GenericResponse, LoginResponse } from '@src/contexts/type';
import { initialTableFilterProps } from '@src/lib/constants';
import { getCountriesStatesCities } from '@src/lib/helper';
import { FormControllerDataReturn, ScheduleActivitiesProps, ScheduleProps } from '@src/lib/types';
import { Activity } from '@src/Models/activity';
import { ActivityMapping, ActivityMappingById, ActivityMappingReturnProps } from '@src/Models/activityMapping';
import { ActivitySchedule } from '@src/Models/activitySchedule';
import { ApprovalRequest } from '@src/Models/ApprovalRequest';
import { AttendanceList } from '@src/Models/attendanceList';
import { BudgetCode } from '@src/Models/budgetCode';
import { Category } from '@src/Models/category';
import { CategoryType } from '@src/Models/categoryType';
import { Contractor } from '@src/Models/contractor';
import { CostCentre } from '@src/Models/costCentre';
import { ProgramDetail } from '@src/Models/googleSync';
import { HomeroomTeacherResponse, HomeroomTeacherType } from '@src/Models/homeromTeacher';
import { Logs } from '@src/Models/logs';
import { PlaceOrdersReturnProps } from '@src/Models/merchandiseOrders';
import { Modules, RolesAllowedTo } from '@src/Models/modules';
import { OrganizationSettings } from '@src/Models/organizationSettings';
import { Parent } from '@src/Models/parent';
import { PaymentLink } from '@src/Models/paymentLink';
import { Cart, Product, VariantReturnProps } from '@src/Models/product';
import { MerchandiseAttributeKey, ProductCategory } from '@src/Models/ProductCategory';
import { Program } from '@src/Models/program';
import { PurchaseOrders } from '@src/Models/purchaseOrders';
import {
  createEditCostCentreProps,
  CreateEditVendorProps,
  PaymentLinkReturnProps,
  ProductReturnProps,
} from '@src/Models/returnProps';
import { Roles } from '@src/Models/roles';
import { RulesCreateType, RulesData } from '@src/Models/rulesUser';
import { Schedule, ScheduleList, ScheduleOffDays } from '@src/Models/schedule';
import { Season } from '@src/Models/season';
import { Session, SessionMappingData, SessionMappingResponse } from '@src/Models/session';
import { SignUpProps } from '@src/Models/signUp';
import { Staff } from '@src/Models/staff';
import { Student } from '@src/Models/student';
import { Tag } from '@src/Models/tags';
import { Transaction } from '@src/Models/transaction';
import { User } from '@src/Models/user';
import { Vendor } from '@src/Models/vendor';
import { ActivityAllocationProps, UpdateSignupPaymentStatusProps } from '@src/screens/asa/signUp/type';
import { ScheduleActivitiesData } from '@src/screens/newAsa/signUp/type';
import { ResponseData } from '@src/screens/Staff/type';

import { editProductQuantityInCart } from '../api/products';

import { GetApiProps } from './type';

export const useAxiosApi = () => {
  const { setLoading, loading, setSnackValue } = useContext(GlobalUIContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const withLoading = async (action: () => Promise<any>, isLoading: LoadingType = 'modal') => {
    if (isLoading) {
      setLoading(isLoading);
    }

    try {
      const response = await action();

      if (response?.message) {
        setSnackValue({ type: 'success', message: response?.message });
      }

      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      error.response?.data?.status !== 401 &&
        setSnackValue({ type: 'error', message: error.response?.data?.message || 'Something went Wrong' });
      return Promise.reject(error as AxiosError);
    } finally {
      if (isLoading) {
        setLoading(false);
      }
    }
  };

  const handleBulkImport = (file: FormData, isLoading?: LoadingType) => {
    return withLoading(() => bulkImport(file), isLoading);
  };

  const handleActivityMappingBulkImport = (file: FormData, isLoading?: LoadingType) => {
    return withLoading(() => activityMappingBulkImport(file), isLoading);
  };

  const handlePhotoUpload = (file: FormData, params: UploadChunkProps, isLoading?: LoadingType) => {
    return withLoading(() => photoUpload(file, params), isLoading);
  };

  const handleCampPaymentsXcelUpload = (
    file: FormData,
    params: CampPaymentsXcelUploadProps,
    isLoading?: LoadingType
  ) => {
    return withLoading(() => campPaymentsXcelUpload(file, params), isLoading);
  };

  const handleGetContractors = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getContractors(data), isLoading);
  };

  const handleGetContractor = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getContractor(id), isLoading) as Promise<{ data: Contractor }>;
  };

  const handleDeleteContractor = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => deleteContractor(id), isLoading) as Promise<Contractor>;
  };

  const handleUpdateContractor = (id: string, data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => updateContractor({ id, data }), isLoading);
  };

  const handleGetStaffs = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getStaffs(data), isLoading);
  };

  const handleGetActivitySchedule = ({
    data,
    isLoading,
  }: {
    data: Pick<GetTableFilterProps, 'program_id' | 'search'>;
    isLoading?: LoadingType;
  }) => {
    return withLoading(() => getActivitySchedule(data), isLoading) as Promise<{ data: ActivitySchedule[] }>;
  };

  const handleGetStaffByID = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getStaffByID(id), isLoading) as Promise<{ data: Staff }>;
  };

  const handleGetParents = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getParents(data), isLoading);
  };

  const handleGetParentByID = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getParentByID(id), isLoading) as Promise<{ data: Parent }>;
  };

  const handleGetVendors = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getVendors(data), isLoading) as Promise<ResponseData<Vendor>>;
  };

  const handleGetVendorById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getVendorsById(id), isLoading) as Promise<{ data: Vendor }>;
  };

  const handleCreateVendor = (data: CreateEditVendorProps, isLoading?: LoadingType) => {
    return withLoading(() => createVendor(data), isLoading);
  };

  const handleCreateProduct = (data: Product, isLoading?: LoadingType) => {
    return withLoading(() => createProduct(data), isLoading);
  };

  const handleAddToCart = (data: AddToCartProps, isLoading?: LoadingType) => {
    return withLoading(() => addToCart(data), isLoading);
  };

  const handleGetStudents = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getStudent(data), isLoading) as Promise<ResponseData<Student>>;
  };

  const handleUpdateTuts = (data: UpdateTutorialProps) => {
    return withLoading(() => updateTuts(data), false) as Promise<ResponseData<Student>>;
  };

  const handleGetStudentByID = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getStudentById(id), isLoading) as Promise<{ data: Student }>;
  };

  const handleGetUserDetails = (token?: string, isLoading?: LoadingType): Promise<{ '2fa': boolean; data: User }> => {
    return withLoading(() => getUserDetails(token), isLoading);
  };

  const handleAdminLogin = (
    data: { username: string; password: string },
    isLoading?: LoadingType
  ): Promise<LoginResponse> => {
    return withLoading(() => adminLogin(data), isLoading);
  };

  const handleUserLogout = (isLoading?: LoadingType): Promise<LoginResponse> => {
    return withLoading(() => userLogout(), isLoading);
  };

  const getCreateEditVendors: ({
    id,
    isLoading,
  }: {
    id?: string;
    isLoading?: LoadingType;
  }) => Promise<[{ data: Vendor } | string, SelectOptionType[], ResponseData<CostCentre>]> = ({ id, isLoading }) =>
    withLoading(
      async () =>
        await Promise.all([
          id ? getVendorsById(id) : '',
          getCountriesStatesCities(),
          getCostCentres(initialTableFilterProps),
        ]),
      isLoading
    );

  const getCreateEditProducts: ({
    id,
    isLoading,
  }: {
    id?: string;
    isLoading?: LoadingType;
  }) => Promise<[{ data: Product } | string]> = ({ id, isLoading }) =>
    withLoading(async () => await Promise.all([id ? getProductById(id) : '']), isLoading);

  const getCreateEditCostCentres = ({ id, isLoading }: { id?: string; isLoading?: LoadingType }) =>
    withLoading(async () => {
      const data = await Promise.all([
        id ? getCostCentre(id) : '',
        getStaffs(initialTableFilterProps),
        getDepartments(id),
      ]);

      return { staffsData: data[1], departmentsData: data[2], costCentreData: data[0] };
    }, isLoading);

  const handleCreateCostCentre = (
    data: createEditCostCentreProps,
    isLoading?: LoadingType
  ): Promise<GenericResponse> => {
    return withLoading(() => createCostCentres(data), isLoading);
  };

  const handleEditCostCentre = (
    id: string,
    data: createEditCostCentreProps,
    isLoading?: LoadingType
  ): Promise<GenericResponse> => {
    return withLoading(() => editCostCentres(id, data), isLoading);
  };

  const handleEditVendor = (
    id: string,
    data: CreateEditVendorProps,
    isLoading?: LoadingType
  ): Promise<GenericResponse> => {
    return withLoading(() => editVendor(id, data), isLoading);
  };

  const handleEditProduct = (id: string, data: Product, isLoading?: LoadingType): Promise<GenericResponse> => {
    return withLoading(() => editProduct(id, data), isLoading);
  };

  const handleEditProductQuantityInCart = (
    id: string,
    data: { quantity: number },
    isLoading?: LoadingType
  ): Promise<GenericResponse> => {
    return withLoading(() => editProductQuantityInCart(id, data), isLoading);
  };

  const handleEditProductCategory = (
    id: string,
    data: FormControllerDataReturn,
    isLoading?: LoadingType
  ): Promise<GenericResponse> => {
    return withLoading(() => updateProductCategory(id, data), isLoading);
  };

  const handleEditAttribute = (
    id: string,
    data: FormControllerDataReturn,
    isLoading?: LoadingType
  ): Promise<GenericResponse> => {
    return withLoading(() => updateAttribute(id, data), isLoading);
  };

  const handleEditProductDetails = (
    id: string,
    data: FormControllerDataReturn,
    isLoading?: LoadingType
  ): Promise<GenericResponse> => {
    return withLoading(() => updateProductDetails(id, data), isLoading);
  };

  const handleEditProductVariantDetails = (
    id: string,
    data: FormControllerDataReturn,
    isLoading?: LoadingType
  ): Promise<GenericResponse> => {
    return withLoading(() => updateProductVariantDetails(id, data), isLoading);
  };

  const handleAddProductNewVariant = (
    id: string,
    data: FormControllerDataReturn,
    isLoading?: LoadingType
  ): Promise<GenericResponse> => {
    return withLoading(() => addNewProductVariant(id, data), isLoading);
  };

  const handleGetRecoveryCode = async (isLoading?: LoadingType): Promise<{ recovery_codes: string[] }> => {
    return withLoading(() => getRecoveryCode(), isLoading);
  };

  const handleGenerateCode = async (isLoading?: LoadingType) => {
    return withLoading(() => generateCode(), isLoading);
  };

  const handleEnable2FA = async (otp: string, isLoading?: LoadingType) => {
    return withLoading(() => enable2FA(otp), isLoading);
  };

  const handleDisable2fa = async (password: string, isLoading?: LoadingType) => {
    return withLoading(() => disable2fa(password), isLoading);
  };

  const handleGetCostCentres = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getCostCentres(data), isLoading);
  };

  const handleGetOrganizationSettings = (isLoading?: LoadingType) => {
    return withLoading(() => getOrganizationSettings(), isLoading) as Promise<{ data: OrganizationSettings }>;
  };

  const handleUpdateOrganizationSettings = (data: OrganizationSettings, isLoading?: LoadingType) => {
    return withLoading(() => updateOrganizationSettings(data), isLoading);
  };

  const handleDeleteCostCentres = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => deleteCostCentre(id), isLoading);
  };

  const handleGetCostCentreById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getCostCentre(id), isLoading);
  };

  const handleGetUsersApi = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getUsersApi(data), isLoading) as Promise<ResponseData<User>>;
  };

  const handleGetUserById = (id: string, permission: boolean = false, isLoading?: LoadingType) => {
    return withLoading(() => getUserById(id, permission), isLoading) as Promise<{ data: User }>;
  };

  const handleUpdateUser = (id: string, data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => updateUser({ id, data }), isLoading);
  };

  const handleUpdateNickname = (id: string, data: NickNameData, isLoading?: LoadingType) => {
    return withLoading(() => updateNickname(id, data), isLoading);
  };

  const handleCreateUser = (data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => createUser({ data }), isLoading);
  };

  const handleGetProgramsApi = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getPrograms(data), isLoading) as Promise<ResponseData<Program>>;
  };

  const handleSendPaymentReminder = ({ program_id, isLoading }: { program_id: string; isLoading?: LoadingType }) => {
    return withLoading(() => sendPaymentReminder({ program_id }), isLoading) as Promise<ResponseData<Program>>;
  };

  const handleGetProductsApi = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getProducts(data), isLoading) as Promise<ResponseData<Product>>;
  };

  const handleGetCartDetails = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getCartDetails(data), isLoading) as Promise<ResponseData<Cart>>;
  };

  const handleGetCartProductCount = (isLoading?: LoadingType) => {
    return withLoading(() => getCartProductCount(), isLoading) as Promise<{ count: number }>;
  };

  const handleGetProgramById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getProgramById(id), isLoading) as Promise<{ data: Program }>;
  };

  const handleUpdateProgram = (id: string, data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => updateProgram({ id, data }), isLoading);
  };

  const handleCreateProgram = (data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => createProgram({ data }), isLoading);
  };

  const handleSoftDeletePrograms = (data: { id: string; active: boolean }, isLoading?: LoadingType) => {
    return withLoading(() => softDeletePrograms({ data }), isLoading);
  };

  const handleSoftDeleteProducts = (data: { id: string; active: boolean }, isLoading?: LoadingType) => {
    return withLoading(() => softDeleteProducts({ data }), isLoading);
  };

  const handleDeleteCartProduct = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => deleteCartProduct(id), isLoading);
  };

  const handleGetModules = (isLoading?: LoadingType) => {
    return withLoading(() => getModules(), isLoading) as Promise<{
      data: Modules[];
    }>;
  };

  const handleGetRoles = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getRoles(data), isLoading) as Promise<ResponseData<Roles>>;
  };

  const handleCreateRoles = (
    data: { role_name: string; role_description: string; allowed_to: RolesAllowedTo[] },
    isLoading?: LoadingType
  ) => {
    return withLoading(() => createRoles(data), isLoading);
  };

  const handleEditRoles = (id: string, data: { allowed_to: RolesAllowedTo[] }, isLoading?: LoadingType) => {
    return withLoading(() => editRoles(id, data), isLoading);
  };

  const handleGetRoleById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getRoleById(id), isLoading) as Promise<{ data: Roles }>;
  };

  const handleCreateActivity = (data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => createActivity({ data }), isLoading);
  };

  const handleGetActivity = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getActivity(data), isLoading) as Promise<ResponseData<Activity>>;
  };

  const handleGetActivityByIdEnrolled = ({ id, data, isLoading }: GetApiProps) => {
    return withLoading(() => getActivityByIdEnrolled(id || '', data), isLoading) as Promise<{ data: Activity }>;
  };

  const handleGetCategoryType = ({ data, extraProps, isLoading }: GetApiProps) => {
    return withLoading(() => getCategoryType(data, extraProps?.id), isLoading) as Promise<ResponseData<CategoryType>>;
  };

  const handleGetBudgetCode = ({ extraProps, isLoading }: GetApiProps) => {
    return withLoading(() => getBudgetCode(extraProps?.id), isLoading) as Promise<ResponseData<BudgetCode>>;
  };

  const handleGetCategory = ({ data, extraProps, isLoading }: GetApiProps) => {
    return withLoading(() => getCategory(data, extraProps?.moduleType || 'po'), isLoading) as Promise<
      ResponseData<Category>
    >;
  };

  const handleGetTags = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getTags(data), isLoading) as Promise<ResponseData<Tag>>;
  };

  const handleGetProductCategories = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getProductCategories(data), isLoading) as Promise<ResponseData<ProductCategory>>;
  };

  const handleGetSchoolLevel = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getSchoolLevel(data), isLoading) as Promise<{ data: SchoolLevelProductData[] }>;
  };

  const handleGetProductAttributes = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getAttributes(data), isLoading) as Promise<ResponseData<MerchandiseAttributeKey>>;
  };

  const handleGetProductCategoryById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getProductCategoryById(id as string), isLoading) as Promise<{ data: ProductCategory }>;
  };

  const handleGetAttributeById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getAttributeById(id as string), isLoading) as Promise<{ data: MerchandiseAttributeKey }>;
  };

  const handleDeleteCategory = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => deleteCategory(id), isLoading) as Promise<Contractor>;
  };

  const handleDeleteAttribute = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => deleteAttribute(id), isLoading);
  };

  const handleGetActivityById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getActivityById(id), isLoading) as Promise<{ data: Activity }>;
  };

  const handleDeleteActivity = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => deleteActivity(id), isLoading);
  };

  const handleUpdateActivity = (id: string, data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => updateActivity({ id, data }), isLoading);
  };

  const handleGetDepartments = ({ data, isLoading = false }: GetApiProps) => {
    return withLoading(() => getAllDepartments(data), isLoading);
  };

  const handleGetDesignation = ({ data, isLoading = false }: GetApiProps) => {
    return withLoading(() => getDesignations(data), isLoading);
  };

  const handleGetStaffCategory = ({ data, isLoading = false }: GetApiProps) => {
    return withLoading(() => getStaffCategories(data), isLoading);
  };

  const handleGetActivityMapping = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getActivityMapping(data), isLoading) as Promise<ResponseData<ActivityMapping>>;
  };

  const handleGetMyStudentsAttendance = ({
    data,
    isLoading,
  }: {
    data: Pick<GetTableFilterProps, 'activity_id' | 'program_id' | 'date' | 'search'>;
    isLoading?: LoadingType;
  }) => {
    return withLoading(() => getMyStudentsAttendance(data), isLoading) as Promise<{ data: { student_id: Student }[] }>;
  };

  const handleGetAsaAttendance = ({
    data,
    isLoading,
  }: {
    data: Pick<GetTableFilterProps, 'activity_id' | 'program_id' | 'date'>;
    isLoading?: LoadingType;
  }) => {
    return withLoading(() => getAsaAttendance(data), isLoading) as Promise<{ data: AttendanceList[] }>;
  };

  const handleCreateAsaAttendance = ({ data, isLoading }: { data: AsaAttendanceProps; isLoading?: LoadingType }) => {
    return withLoading(() => createAsaAttendance(data), isLoading) as Promise<void>;
  };

  const handleDownloadSheet = ({ data, isLoading }: { data: DownloadSheetProps; isLoading?: LoadingType }) => {
    return withLoading(() => downloadSheet(data), isLoading) as Promise<Blob>;
  };

  const handleDownloadAllProgramSheet = ({
    data,
    isLoading,
  }: {
    data: { program_id: string };
    isLoading?: LoadingType;
  }) => {
    return withLoading(() => downloadAllProgramSheet(data), isLoading) as Promise<Blob>;
  };

  const handleDownloadTransactions = ({
    data,
    isLoading,
  }: {
    data: DownloadTransactionsProps;
    isLoading?: LoadingType;
  }) => {
    return withLoading(() => downloadTransactions(data), isLoading) as Promise<Blob>;
  };

  const handleDownloadWeeklySchedule = ({
    data,
    isLoading,
  }: {
    data: DownloadWeeklyScheduleProps;
    isLoading?: LoadingType;
  }) => {
    return withLoading(() => downloadWeeklySchedule(data), isLoading) as Promise<Blob>;
  };

  const handleDownloadDaywiseProgramSchedule = ({
    data,
    isLoading,
  }: {
    data: DownloadDaywiseProgramScheduleProps;
    isLoading?: LoadingType;
  }) => {
    return withLoading(() => downloadDaywiseProgramSchedule(data), isLoading) as Promise<Blob>;
  };

  const handleDownloadWaitingActivityList = ({
    data,
    isLoading,
  }: {
    data: DownloadWaitingActivityListProps;
    isLoading?: LoadingType;
  }) => {
    return withLoading(() => downloadWaitingActivityList(data), isLoading) as Promise<Blob>;
  };

  const handleDownloadProgramSubmissions = ({
    data,
    isLoading,
  }: {
    data: DownloadProgramSubmissionsProps;
    isLoading?: LoadingType;
  }) => {
    return withLoading(() => downloadProgramSubmissions(data), isLoading) as Promise<Blob>;
  };

  const handleCreateActivityMapping = (data: ActivityMappingReturnProps, isLoading?: LoadingType) => {
    return withLoading(() => createActivityMapping(data), isLoading) as Promise<{
      id: string;
      message: string;
      status: number;
    }>;
  };

  const handleUpdateActivityMapping = (data: ActivityMappingReturnProps, id: string, isLoading?: LoadingType) => {
    return withLoading(() => updateActivityMapping(data, id), isLoading);
  };

  const handleUpdateGoogleSheetSync = (data: FormControllerDataReturn, id: string, isLoading?: LoadingType) => {
    return withLoading(() => updateGoogleSheetSync(data, id), isLoading);
  };

  const handleGetActivityMappingById = (program_id: string, isLoading?: LoadingType) => {
    return withLoading(() => getActivityMappingById(program_id), isLoading) as Promise<
      ResponseData<ActivityMappingById>
    >;
  };

  const handleGetActivityMappingByMappingId = (mapping_id: string, isLoading?: LoadingType) => {
    return withLoading(() => getActivityMappingByMappingId(mapping_id), isLoading) as Promise<{
      data: ActivityMappingById;
    }>;
  };

  const handleDeleteActivityMappingList = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => deleteActivityMappingList(id), isLoading);
  };

  const handleDeleteActivityMapping = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => deleteActivityMapping(id), isLoading);
  };

  const handleEditPurchaseOrder = (
    id: string,
    data: PurchaseOrders,
    isLoading?: LoadingType
  ): Promise<GenericResponse> => {
    return withLoading(() => editPurchaseOrder(id, data), isLoading);
  };

  const handleCreatePurchaseOrder = (data: PurchaseOrders, isLoading?: LoadingType): Promise<GenericResponse> => {
    return withLoading(() => createPurchaseOrder(data), isLoading);
  };

  const handleCreateAttributeValue = (
    id: string,
    data: CreateAttributeValue,
    isLoading?: LoadingType
  ): Promise<GenericResponse> => {
    return withLoading(() => createAttributeValue(id, data), isLoading);
  };

  const handleUpdateAttributeValue = (
    data: UpdateAttributeValue,
    isLoading?: LoadingType
  ): Promise<GenericResponse> => {
    return withLoading(() => updateAttributeValue(data), isLoading);
  };

  const handleDeleteAttributeValue = (id: string, isLoading?: LoadingType): Promise<GenericResponse> => {
    return withLoading(() => deleteAttributeValue(id), isLoading);
  };

  const handleGetPurchaseOrder = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getPurchaseOrder(data), isLoading);
  };

  const handleGetReviewPurchaseOrder = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getReviewPurchaseOrder(data), isLoading);
  };

  const handleGetPurchaseOrderById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getPurchaseOrderById(id), isLoading);
  };

  const handleGetProductById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getProductById(id), isLoading) as Promise<{ data: ProductReturnProps }>;
  };

  const handleGetReviewPurchaseOrderById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getReviewPurchaseOrderById(id), isLoading);
  };

  const handleGenerateS3Url = (data: FileUploadType, isLoading: LoadingType = false) => {
    return withLoading(() => generateS3Url(data), isLoading);
  };

  const handleSignUpWithGoogle = (code: string, isLoading?: LoadingType): Promise<LoginResponse> => {
    return withLoading(() => signUpWithGoogle(code), isLoading);
  };

  const handleVerify2faRecoveryCode = (
    otpDetails: {
      recovery_code: string;
      mfatoken: string;
    },
    isLoading?: LoadingType
  ): Promise<LoginResponse> => {
    return withLoading(() => verify2faRecoveryCode(otpDetails), isLoading);
  };

  const handleVerify2fa = (
    otpDetails: { otp: string; mfatoken: string },
    isLoading?: LoadingType
  ): Promise<LoginResponse> => {
    return withLoading(() => verify2fa(otpDetails), isLoading);
  };

  const handleAddExtraPermissions = (data: addExtraPermissionsType, isLoading?: LoadingType) => {
    return withLoading(() => addExtraPermissions({ data }), isLoading);
  };

  const handleUpdateExtraPermissions = (data: addExtraPermissionsType, isLoading?: LoadingType) => {
    return withLoading(() => updateExtraPermissions({ data }), isLoading);
  };

  const handleGetSyncData = ({ data, isLoading }: GetApiProps): Promise<{ data: Logs[]; total: number }> => {
    return withLoading(() => getSyncLogs(data), isLoading);
  };

  const handleGetPaymentTransactions = ({ data, isLoading }: GetApiProps): Promise<{ data: Logs[]; total: number }> => {
    return withLoading(() => getPaymentTransactionsLogs(data), isLoading);
  };

  const handleGetApiRequestsData = ({ data, isLoading }: GetApiProps): Promise<{ data: Logs[]; total: number }> => {
    return withLoading(() => getApiRequestsLogs(data), isLoading);
  };

  const handleActivitySignUp = (
    data: { eligible_grades: string; program_id: string; student_id: string },
    isLoading?: LoadingType
  ) => {
    return withLoading(() => activitySignUp(data), isLoading);
  };

  const handleScheduleActivities = (data: ScheduleActivitiesProps, isLoading?: LoadingType) => {
    return withLoading(() => getScheduleActivities(data), isLoading) as Promise<{ data: ScheduleActivitiesData }>;
  };

  const handleProcessingSignUp = (data: SignUpDataProps, isLoading?: LoadingType) => {
    return withLoading(() => processingSignUp(data), isLoading);
  };

  const handleGetSignUpList = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getSignUpList(data), isLoading) as Promise<ResponseData<SignUpProps>>;
  };

  const handleGetSignUpById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getSignUpById(id), isLoading) as Promise<{ data: SignUpProps }>;
  };

  const handleUpdateSignUpById = (data: ActivityAllocationProps, id: string, isLoading?: LoadingType) => {
    return withLoading(() => updateSignUpById(data, id), isLoading) as Promise<{ data: SignUpProps }>;
  };

  const handleUpdateSignUpPaymentStatus = (
    data: UpdateSignupPaymentStatusProps,
    id: string,
    isLoading?: LoadingType
  ) => {
    return withLoading(() => updateSignUpPaymentStatus(id, data), isLoading);
  };

  const handleGetSession = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getSession(data), isLoading) as Promise<ResponseData<Session>>;
  };

  const handleCreateCategory = (
    data: FormControllerDataReturn,
    moduleType: CategoryModulesType,
    isLoading?: LoadingType
  ) => {
    return withLoading(() => createCategory(data, moduleType), isLoading);
  };

  const handleCreateProductCategory = (data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => createProductCategory(data), isLoading);
  };

  const handleCreateAttribute = (data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => createAttribute(data), isLoading);
  };

  const handleCreateCategoryType = (data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => createCategoryType(data), isLoading);
  };

  const handleCreateTag = (data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => createTag(data), isLoading);
  };

  const handleUpdateCategoryType = (id: string, data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => updateCategoryType(id, data), isLoading);
  };

  const handleUpdateCategory = (
    id: string,
    data: FormControllerDataReturn,
    moduleType: CategoryModulesType,
    isLoading?: LoadingType
  ) => {
    return withLoading(() => updateCategory(id, data, moduleType), isLoading);
  };

  const getCreateEditCategoryType = ({ id, isLoading }: { id?: string; isLoading?: LoadingType }) =>
    withLoading(async () => {
      const data = await Promise.all([id ? getCategoryTypeById(id) : '', getCategory(initialTableFilterProps, 'po')]);

      return { categoryTypeByIdData: data[0], categoriesData: data[1] };
    }, isLoading);

  const handleGetCategoryById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getCategoryById(id), isLoading);
  };

  const handleCreateSession = (data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => createSession(data), isLoading);
  };

  const handleUpdateSession = (id: string, data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => updateSession(id, data), isLoading);
  };

  const handleGetSessionById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getSessionById(id), isLoading);
  };

  const handleDeleteSession = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => deleteSession(id), isLoading);
  };

  const handleGetSeason = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getSeason(data), isLoading) as Promise<{
      data: Season[];
      total: number;
    }>;
  };

  const handleCreateSeason = (data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => createSeason(data), isLoading);
  };

  const handleUpdateSeason = (id: string, data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => updateSeason(id, data), isLoading);
  };

  const handleGetSeasonById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getSeasonById(id), isLoading);
  };

  const handleDeleteSeason = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => deleteSeason(id), isLoading);
  };

  const handleGetSessionMapping = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getSessionMapping(data), isLoading) as Promise<ResponseData<SessionMappingResponse>>;
  };

  const handleCreateSessionMapping = (data: SessionMappingData, isLoading?: LoadingType) => {
    return withLoading(() => createSessionMapping(data), isLoading);
  };

  const handleUpdateSessionMapping = (id: string, data: SessionMappingData, isLoading?: LoadingType) => {
    return withLoading(() => updateSessionMapping(id, data), isLoading);
  };

  const handleDeleteSessionMapping = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => deleteSessionMapping(id), isLoading);
  };

  const handleGetSessionMappingById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getSessionMappingById(id), isLoading);
  };

  const handleGetPaymentStatus = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getPaymentStatus(id), isLoading) as Promise<{ data: Transaction }>;
  };

  const handleGetSchedules = (params: ScheduleProps, isLoading?: LoadingType) => {
    return withLoading(() => getSchedules(params), isLoading) as Promise<{ data: Schedule }>;
  };

  const handleGetOffDays = ({ isLoading, data }: { data: GetTableFilterProps; isLoading?: LoadingType }) => {
    return withLoading(() => getOffDays(data), isLoading) as Promise<ScheduleOffDays[] | ResponseData<ScheduleOffDays>>;
  };

  const handleGetOffDaysById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getOffDaysById(id), isLoading) as Promise<ScheduleOffDays>;
  };

  const handleDeleteOffDaysById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => deleteOffDaysByID(id), isLoading) as Promise<ScheduleOffDays>;
  };

  const handleCreateUpdateDayoff = (data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => createOffDays(data), isLoading);
  };

  const handleEditUpdateDayoff = (id: string, data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => updateOffDays(id, data), isLoading);
  };

  const handleGetSchedulesFilterList = (params: ScheduleProps, isLoading?: LoadingType) => {
    return withLoading(() => getSchedulesFilterList(params), isLoading) as Promise<ScheduleList>;
  };

  const handleGetApprovalRulesUsers = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getApprovalRulesUsers(data), isLoading) as Promise<{
      data: RulesData[];
      total: number;
    }>;
  };

  const handleCreateApprovalRulesUsers = (data: RulesCreateType, isLoading?: LoadingType) => {
    return withLoading(() => createApprovalRulesUsers(data), isLoading);
  };

  const handleUpdateApprovalRulesUsers = (id: string, data: RulesCreateType, isLoading?: LoadingType) => {
    return withLoading(() => updateApprovalRulesUsers(id, data), isLoading);
  };

  const handleDeleteApprovalRulesUsers = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => deleteApprovalRulesUsers(id), isLoading);
  };

  const handleGetApprovalRulesAmount = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getApprovalRulesAmount(data), isLoading) as Promise<{
      data: RulesData[];
      total: number;
    }>;
  };

  const handleUpdateApprovalRulesAmount = (id: string, data: RulesCreateType, isLoading?: LoadingType) => {
    return withLoading(() => updateApprovalRulesAmount(id, data), isLoading);
  };

  const handleCreateApprovalRulesAmount = (data: RulesCreateType, isLoading?: LoadingType) => {
    return withLoading(() => createApprovalRulesAmount(data), isLoading);
  };

  const handleDeleteApprovalRulesAmount = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => deleteApprovalRulesAmount(id), isLoading);
  };

  const handleDisableProductVariant = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => deleteProductVariant(id), isLoading);
  };

  const handleDeleteProductCategory = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => deleteProductCategory(id), isLoading);
  };

  const handleGetApprovalRulesUsersById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getApprovalRulesUsersById(id), isLoading);
  };

  const handleGetApprovalRulesAmountById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getApprovalRulesAmountById(id), isLoading);
  };

  const handleSyncUserData = (isLoading?: LoadingType) => {
    return withLoading(() => syncUserData(), isLoading);
  };

  const handleResetPoSerial = (isLoading?: LoadingType) => {
    return withLoading(() => resetPoSerial(), isLoading);
  };

  const handleProgramCheckout = (order_id: string, isLoading?: LoadingType) => {
    return withLoading(() => programCheckout({ order_id }), isLoading) as Promise<{ redirect_url: string }>;
  };

  const handleUpdatePoStatus = (id: string, data: ApprovalRequest, isLoading?: LoadingType) => {
    return withLoading(() => editPurchaseOrderStatus(id, data), isLoading);
  };

  const handleGetPaymentLinkById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getPaymentLinkById(id), isLoading) as Promise<{ data: PaymentLinkReturnProps }>;
  };

  const handleCreatePaymentLink = (data: PaymentLink, isLoading?: LoadingType) => {
    return withLoading(() => createPaymentLink(data), isLoading);
  };

  const handleGetPaymentLink = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getPaymentLinks(data), isLoading) as Promise<ResponseData<PaymentLinkReturnProps>>;
  };

  const handleGetWaitingList = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getWaitingList(data), isLoading);
  };

  const handleManuallyAssign = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => manuallyAssign(id), isLoading);
  };

  const handleCreateGoogleSheetSync = (data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => createGoogleSheetSync(data), isLoading);
  };

  const handleGetGoogleSheetSync = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getGoogleSheetSync(data), isLoading) as Promise<ResponseData<ProgramDetail>>;
  };

  const handleSwitchUserRole = ({ data, isLoading }: { data: { role: string }; isLoading?: LoadingType }) => {
    return withLoading(() => switchUserRole(data), isLoading) as Promise<ResponseData<ProgramDetail>>;
  };

  const handlePlaceProductOrder = (data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => placeProductOrder(data), isLoading) as Promise<{
      order_id: string;
      payment_link: string;
      status: number;
    }>;
  };

  const handleGetOrderById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getOrder(id), isLoading) as Promise<{ data: PlaceOrdersReturnProps }>;
  };

  const handleEditOrderDetails = (
    id: string,
    data: FormControllerDataReturn,
    isLoading?: LoadingType
  ): Promise<GenericResponse> => {
    console.log('ughrhueh   ', data);
    return withLoading(() => editOrder(id, data), isLoading);
  };

  const handleGetProductVariantById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getProductVariantById(id), isLoading) as Promise<{ data: VariantReturnProps }>;
  };

  const handleGetMerchandiseOrders = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getMerchandiseOrders(data), isLoading) as Promise<ResponseData<PlaceOrdersReturnProps>>;
  };

  const handleGetHomeroomTeachers = ({ data, isLoading }: GetApiProps) => {
    return withLoading(() => getHomeroomTeachers(data), isLoading) as Promise<HomeroomTeacherResponse>;
  };

  const handleHomeroomTeacherById = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getHomeroomTeacherById(id), isLoading) as Promise<{ data: HomeroomTeacherType }>;
  };

  const handleAssignHomeRoomTeacher = async (data: FormControllerDataReturn, isLoading?: LoadingType) => {
    return withLoading(() => assignHomeRoomTeacher(data), isLoading);
  };

  const handleEditAssignHomeRoomTeacher = async (id: string, isLoading?: LoadingType) => {
    return withLoading(() => getHomeroomTeacherById(id), isLoading) as Promise<{ data: HomeroomTeacherType }>;
  };

  const handleDeleteAssignHomeRoomTeacher = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => deleteAssignHomeRoomTeacher(id), isLoading);
  };

  const handleDeleteActivitySignup = (id: string, isLoading?: LoadingType) => {
    return withLoading(() => deleteActivitySignUp(id), isLoading);
  };

  return {
    loading,
    handleGetActivitySchedule,
    handleGetMyStudentsAttendance,
    handleCreateAsaAttendance,
    handleDeleteActivitySignup,
    handleGetAsaAttendance,
    handleGetOffDaysById,
    handleCreateUpdateDayoff,
    handleEditUpdateDayoff,
    handleDeleteOffDaysById,
    handleActivityMappingBulkImport,
    handleDisableProductVariant,
    handleGetProductVariantById,
    handlePlaceProductOrder,
    handleGetOrderById,
    handleDeleteProductCategory,
    handleEditProductVariantDetails,
    handleEditProductDetails,
    handleGetProductAttributes,
    handleGetActivityByIdEnrolled,
    handleGetActivityMappingByMappingId,
    handleBulkImport,
    handleGetContractors,
    handleGetUserDetails,
    handleAdminLogin,
    handleUserLogout,
    handleGetRecoveryCode,
    handleGenerateCode,
    handleEnable2FA,
    handleDisable2fa,
    handleGetContractor,
    handleDeleteContractor,
    handleUpdateContractor,
    handleGetStaffs,
    handleGetStaffByID,
    handleGetParents,
    getCreateEditCostCentres,
    handleGetCostCentreById,
    handleCreateCostCentre,
    handleGetParentByID,
    handleGetStudents,
    handleGetStudentByID,
    handleGetCostCentres,
    handleGetUsersApi,
    handleGetUserById,
    handleUpdateUser,
    handleCreateUser,
    handleEditCostCentre,
    handleDeleteCostCentres,
    handleGetVendors,
    handleGetVendorById,
    handleGetOrganizationSettings,
    handleUpdateOrganizationSettings,
    handleCreateVendor,
    handleGetProgramsApi,
    handleGetProgramById,
    handleUpdateProgram,
    handleCreateProgram,
    handleSoftDeletePrograms,
    getCreateEditVendors,
    handleEditVendor,
    handleGetModules,
    handleGetRoles,
    handleCreateRoles,
    handleGetActivity,
    handleGetActivityById,
    handleCreateActivity,
    handleGetCategory,
    handleUpdateActivity,
    handleDeleteActivity,
    handleGetDepartments,
    handleGetDesignation,
    handleGetStaffCategory,
    handleGetActivityMapping,
    handleCreateActivityMapping,
    handleUpdateActivityMapping,
    handleGetActivityMappingById,
    handleDeleteActivityMappingList,
    handleCreatePurchaseOrder,
    handleEditPurchaseOrder,
    handleGetPurchaseOrderById,
    handleGetPurchaseOrder,
    handleGenerateS3Url,
    handleSignUpWithGoogle,
    handleVerify2faRecoveryCode,
    handleVerify2fa,
    handleAddExtraPermissions,
    handleUpdateExtraPermissions,
    handleGetSyncData,
    handleActivitySignUp,
    handleProcessingSignUp,
    handleGetApiRequestsData,
    handleGetSignUpList,
    handleGetSignUpById,
    handleUpdateSignUpById,
    handleGetSession,
    handleGetSeason,
    handleCreateSeason,
    handleCreateSession,
    handleUpdateSession,
    handleGetSessionById,
    handleGetSeasonById,
    handleUpdateSeason,
    handleDeleteSession,
    handleDeleteSeason,
    handleCreateCategory,
    handleUpdateCategory,
    handleGetCategoryById,
    handleDeleteCategory,
    handleGetSessionMapping,
    handleCreateSessionMapping,
    handleUpdateSessionMapping,
    handleDeleteSessionMapping,
    handleGetSessionMappingById,
    handleGetSchedules,
    handleGetCategoryType,
    handleCreateCategoryType,
    handleUpdateCategoryType,
    getCreateEditCategoryType,
    handleGetBudgetCode,
    handleGetApprovalRulesUsers,
    handleGetPaymentStatus,
    handleGetPaymentTransactions,
    handleGetApprovalRulesAmount,
    handleDeleteApprovalRulesUsers,
    handleDeleteApprovalRulesAmount,
    handleCreateApprovalRulesUsers,
    handleCreateApprovalRulesAmount,
    handleGetApprovalRulesUsersById,
    handleGetApprovalRulesAmountById,
    handleUpdateApprovalRulesUsers,
    handleUpdateApprovalRulesAmount,
    handlePhotoUpload,
    handleSyncUserData,
    handleResetPoSerial,
    handleProgramCheckout,
    handleUpdatePoStatus,
    handleGetReviewPurchaseOrder,
    handleGetReviewPurchaseOrderById,
    handleGetPaymentLinkById,
    handleCreatePaymentLink,
    handleGetPaymentLink,
    handleEditRoles,
    handleGetRoleById,
    handleDownloadSheet,
    handleGetSchedulesFilterList,
    handleDeleteActivityMapping,
    handleGetOffDays,
    handleDownloadTransactions,
    handleDownloadWeeklySchedule,
    handleDownloadDaywiseProgramSchedule,
    handleSoftDeleteProducts,
    handleGetProductsApi,
    handleUpdateSignUpPaymentStatus,
    handleEditProduct,
    handleCreateProduct,
    getCreateEditProducts,
    handleGetProductById,
    handleGetWaitingList,
    handleManuallyAssign,
    handleDownloadWaitingActivityList,
    handleGetProductCategories,
    handleCreateProductCategory,
    handleEditProductCategory,
    handleGetProductCategoryById,
    handleDownloadProgramSubmissions,
    handleCreateGoogleSheetSync,
    handleGetGoogleSheetSync,
    handleUpdateGoogleSheetSync,
    handleGetTags,
    handleCreateTag,
    handleCreateAttribute,
    handleDeleteAttribute,
    handleGetAttributeById,
    handleEditAttribute,
    handleCreateAttributeValue,
    handleUpdateAttributeValue,
    handleDeleteAttributeValue,
    handleAddProductNewVariant,
    handleAddToCart,
    handleGetCartDetails,
    handleDeleteCartProduct,
    handleEditProductQuantityInCart,
    handleSwitchUserRole,
    handleSendPaymentReminder,
    handleGetMerchandiseOrders,
    handleEditOrderDetails,
    handleGetHomeroomTeachers,
    handleHomeroomTeacherById,
    handleAssignHomeRoomTeacher,
    handleEditAssignHomeRoomTeacher,
    handleDeleteAssignHomeRoomTeacher,
    handleCampPaymentsXcelUpload,
    handleGetSchoolLevel,
    handleScheduleActivities,
    handleUpdateNickname,
    handleUpdateTuts,
    handleGetCartProductCount,
    handleDownloadAllProgramSheet,
  };
};
