import { useTranslation } from 'react-i18next';

import { Timer } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import { useGetTheme } from '@src/hooks/useGetTheme';
import { Breakpoints } from '@src/lib/styles/breakpoints';

const TrialWarning = ({ trialEndDate, hasTrialEnded }: { trialEndDate: string; hasTrialEnded: boolean }) => {
  const { t } = useTranslation();
  const { styledTheme } = useGetTheme();
  return (
    <Box
      sx={{
        gap: styledTheme.newSpace.get('xs'),
        bgcolor: hasTrialEnded ? styledTheme.colors.orange : styledTheme.colors.ivoryBlush,
        display: 'flex',
        p: `${styledTheme.newSpace.get('m')} 60px`,
        m: '0 -60px',
        borderRadius: 0,

        [Breakpoints.DESKTOP_SMALL]: {
          m: 0,
          p: `${styledTheme.newSpace.get('xl')} ${styledTheme.newSpace.get('m')}`,
          borderRadius: '12px',
        },
      }}
    >
      <Timer
        sx={{ color: hasTrialEnded ? styledTheme.colors.white : styledTheme.colors.orange, width: 16, height: 16 }}
      />
      <Typography
        sx={{
          fontSize: styledTheme.newFontSize.get('m'),
          fontWeight: '500',
          color: hasTrialEnded ? styledTheme.colors.white : styledTheme.colors.midnightGray,
        }}
      >
        {hasTrialEnded ? t('trialPeriodEnded') : t('trialPeriodEndsOn', { trialEndDate })}
      </Typography>
    </Box>
  );
};

export default TrialWarning;
