import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckCircle, Close } from '@mui/icons-material';
import { Box, Drawer, IconButton, Typography } from '@mui/material';

import { AuthContext } from '@src/contexts/AuthContextProvider';
import { GlobalUIContext, SelectedChildrenProps } from '@src/contexts/GlobalUIContext';
import { useGetTheme } from '@src/hooks/useGetTheme';

import { customOption } from './CustomOption';

interface MobileDrawerProps {
  isDrawerOpen: boolean;
  handleDrawerToggle: () => void;
  list: SelectedChildrenProps[];
}

const MobileDrawer = ({ isDrawerOpen, handleDrawerToggle, list }: MobileDrawerProps) => {
  const { userDetail } = useContext(AuthContext);
  const { selectedChildren, setSelectedChildren } = useContext(GlobalUIContext);

  const handleChildSelect = (child: SelectedChildrenProps) => {
    setSelectedChildren?.(child);
    handleDrawerToggle();
  };

  const { styledTheme } = useGetTheme();
  const { t } = useTranslation();

  const renderDrawerChildrenList = () => {
    return (
      <Box>
        {list.map(child => (
          <Box
            key={child.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
              p: `${styledTheme.newSpace.get('s')} ${styledTheme.newSpace.get('3xl')}`,
              '&:hover': {
                backgroundColor: styledTheme.colors.paleGray,
              },
            }}
            onClick={() => handleChildSelect(child)}
          >
            {customOption(child, userDetail?.children)}
            {child.id === selectedChildren?.id ? (
              <CheckCircle sx={{ color: styledTheme.colors.brightOrangeText }} fontSize='small' />
            ) : null}
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Drawer
      anchor='bottom'
      open={isDrawerOpen}
      onClose={handleDrawerToggle}
      PaperProps={{
        sx: {
          borderTopLeftRadius: styledTheme.newSpace.get('2xs'),
          borderTopRightRadius: styledTheme.newSpace.get('2xs'),
          pb: 3,
        },
      }}
    >
      <IconButton
        onClick={handleDrawerToggle}
        sx={{
          position: 'absolute',
          top: styledTheme.newSpace.get('xs'),
          right: styledTheme.newSpace.get('xl'),
        }}
      >
        <Close />
      </IconButton>
      <Box
        sx={{
          m: 2,
          mt: 5,
          mb: 1,
        }}
      >
        <Typography sx={{ fontWeight: '700', fontSize: styledTheme.newFontSize.get('xl') }}>{t('myKids')}</Typography>
      </Box>
      {renderDrawerChildrenList()}
    </Drawer>
  );
};

export default MobileDrawer;
