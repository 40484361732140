import styled from 'styled-components';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { Breakpoints } from '@src/lib/styles/breakpoints';

export const StyledDialog = styled(Dialog)`
  .MuiPaper-elevation {
    border-radius: 24px;
    padding: ${({ theme }) => theme.newSpace.get('4xl')};
    max-width: 500px;
    ${Breakpoints.MOBILE} {
      max-width: none;
      padding: 0 ${({ theme }) => theme.newSpace.get('xl')};
      border-radius: 0;
      gap: ${({ theme }) => theme.newSpace.get('xl')};
      background-color: ${({ theme }) => theme.colors.offWhite};
      overflow: hidden;
    }
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  padding: ${({ theme }) => theme.newSpace.get('xl')} 0;
  color: ${({ theme }) => theme.colors.midnightGray};
  font-size: ${({ theme }) => theme.newFontSize.get('2xl')};
  font-weight: 700;
`;

export const ProgramTitle = styled(DialogTitle)`
  background: ${({ theme }) => theme.colors.paleGray};
  border-radius: 12px;
  padding: ${({ theme }) => theme.newSpace.get('xl')};
  color: ${({ theme }) => theme.colors.midnightGray};
  font-size: ${({ theme }) => theme.newFontSize.get('xl')};
  font-weight: 600;
  ${Breakpoints.MOBILE} {
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 4px 12px 0px #00000005;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: 0;
  margin-top: ${({ theme }) => theme.newSpace.get('xs')};
  & > div:first-child {
    padding-top: ${({ theme }) => theme.newSpace.get('xl')};
  }
  ${Breakpoints.MOBILE} {
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 4px 12px 0px #00000005;
    border-radius: 12px;
    padding: ${({ theme }) => theme.newSpace.get('xs')};
    & > div:first-child {
      padding-top: ${({ theme }) => theme.newSpace.get('xs')};
    }
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 ${({ theme }) => theme.newSpace.get('xl')} 0;
  gap: ${({ theme }) => theme.newSpace.get('5xl')};
`;

export const ItemDetails = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.newSpace.get('xs')};
  align-items: center;
`;

export const ItemText = styled.div`
  color: ${({ theme }) => theme.colors.midnightGray};
  font-size: ${({ theme }) => theme.newFontSize.get('l')};
  font-weight: 500;
`;

export const StatusWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.newSpace.get('xs')};
`;

export const StatusLabel = styled.span`
  padding: 2px ${({ theme }) => theme.newSpace.get('3xs')};
  background: ${({ theme }) => theme.colors.paleGray};
  font-size: ${({ theme }) => theme.newFontSize.get('m')};
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 4px;
`;

export const ItemPrice = styled.div<{ $isWaiting: boolean }>`
  color: ${({ theme, $isWaiting }) => ($isWaiting ? theme.colors.fieryOrange : theme.colors.midnightGray)};
  font-size: ${({ theme }) => theme.newFontSize.get('xl')};
  font-weight: 600;
`;

export const StyledDialogActions = styled(DialogActions)`
  justify-content: space-between;
  padding: 0;
  padding-top: 32px;
  ${Breakpoints.MOBILE} {
    background: ${({ theme }) => theme.colors.white};
    padding: ${({ theme }) => theme.newSpace.get('xl')};
    margin: 0 calc(${({ theme }) => theme.newFontSize.get('xl')} * -1);
    border-top: 1px solid ${({ theme }) => theme.colors.paleGray};
  }
`;

export const TotalPrice = styled.div`
  color: ${({ theme }) => theme.colors.verdant};
  font-size: ${({ theme }) => theme.newFontSize.get('3xl')};
  font-weight: 600;
`;

export const StyledNotification = styled.div`
  background-color: ${({ theme }) => theme.colors.ivoryLace};
  padding: ${({ theme }) => theme.newSpace.get('s')} ${({ theme }) => theme.newSpace.get('m')};
  border-radius: 8px;
  gap: ${({ theme }) => theme.newSpace.get('m')};
  margin-top: ${({ theme }) => theme.newSpace.get('xl')};
  display: flex;
  ${Breakpoints.MOBILE} {
    margin-top: ${({ theme }) => theme.newSpace.get('xs')};
    margin-bottom: ${({ theme }) => theme.newSpace.get('xl')};
  }
`;

export const NotificationText = styled.div`
  font-size: ${({ theme }) => theme.newFontSize.get('m')};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.midnightGray};
`;
