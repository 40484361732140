import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { AccessTime } from '@mui/icons-material';
import { Avatar, Box, Typography } from '@mui/material';

import { useGetTheme } from '@src/hooks/useGetTheme';
import { getFullName } from '@src/lib/helper';
import { Activity } from '@src/Models/activity';
import { ScheduleEditProp, WeekDays } from '@src/Models/activityMapping';
import { Activity as SignupActivity, SignUpActivityIdsProps } from '@src/screens/asa/signUp/type';

import { SignUpContext } from '../..';
import { CampActivity, DayActivity } from '../../type';

import HolidayLayout from './HolidayLayout';
import SeeDetailsLayout from './SeeDetailsLayout';

interface SingleChildrenLayoutProps {
  date: string;
  day: WeekDays;
  dayActivityData?: DayActivity[];
  holiday?: string;
  handleSeeDetails: (data: Pick<SignupActivity, 'schedule' | 'activity_id'>) => void;
}

const SingleChildrenLayout = ({ date, day, holiday, dayActivityData, handleSeeDetails }: SingleChildrenLayoutProps) => {
  const { t } = useTranslation();

  const { selectedProgram } = useContext(SignUpContext);
  const isCamp = selectedProgram?.type === 'CAMP';
  const alloted = dayActivityData?.[0]?.activity_data as CampActivity;
  const activityData = isCamp ? alloted?.activity_id : (dayActivityData?.[0]?.activity_data as Activity | undefined);
  const scheduleData = isCamp ? alloted : dayActivityData?.[0]?.schedule;
  const instructorsData = isCamp ? alloted?.instructor_ids[0] : scheduleData?.instructor_ids?.[0];
  const { styledTheme } = useGetTheme();

  if (!activityData) return null;

  return (
    <Box
      sx={{
        bgcolor: styledTheme.colors.white,
        display: 'flex',
        borderRadius: '12px',
        boxShadow: '0px 4px 12px 0px #00000005',
      }}
    >
      <Box
        sx={{
          minWidth: '64px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          borderRight: `1px solid ${styledTheme.colors.paleGray}`,
          borderTopLeftRadius: '12px',
          borderBottomLeftRadius: '12px',
        }}
      >
        <Typography sx={{ fontSize: styledTheme.newFontSize.get('4xl'), fontWeight: '600' }}>{date}</Typography>
        <Typography sx={{ fontSize: styledTheme.newFontSize.get('m'), fontWeight: '500', textTransform: 'capitalize' }}>
          {day.slice(0, 3)}
        </Typography>
      </Box>
      {holiday ? (
        <HolidayLayout holiday={holiday} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: styledTheme.newSpace.get('s'),
            p: styledTheme.newSpace.get('m'),
            flex: 1,
            borderTopRightRadius: '12px',
            borderBottomRightRadius: '12px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: styledTheme.newSpace.get('m') }}>
            <Box sx={{ display: 'flex', gap: styledTheme.newSpace.get('2xs'), alignItems: 'center' }}>
              <Avatar
                alt='Group Profile'
                src={activityData?.image?.url || '/icons/defaultActivityImage.svg'}
                sx={{ width: 32, height: 32 }}
              />
              <Typography sx={{ fontSize: styledTheme.newFontSize.get('xl'), fontWeight: '500' }}>
                {activityData?.name}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
              <Box sx={{ display: 'flex', alignItems: 'baseline', gap: '2px' }}>
                <Typography sx={{ fontSize: styledTheme.newFontSize.get('xl'), fontWeight: '600' }}>₹</Typography>
                <Typography sx={{ fontSize: styledTheme.newFontSize.get('3xl'), fontWeight: '600' }}>
                  {isCamp ? alloted?.activity_id?.camp_basic_cost : activityData?.basic_cost}
                </Typography>
              </Box>
              <Box sx={{ color: styledTheme.colors.charcoalMist, display: 'flex', alignItems: 'baseline', gap: '2px' }}>
                <Typography sx={{ fontSize: styledTheme.newFontSize.get('m'), fontWeight: '500' }}>+</Typography>
                <Typography sx={{ fontSize: styledTheme.newFontSize.get('s'), fontWeight: '500' }}>₹</Typography>
                <Typography sx={{ fontSize: styledTheme.newFontSize.get('m'), fontWeight: '500' }}>
                  {isCamp ? alloted?.activity_id?.camp_material_cost : activityData?.material_cost} {t('materials')}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: styledTheme.newSpace.get('3xs'), alignItems: 'center' }}>
              <AccessTime sx={{ width: 12, height: 12, color: styledTheme.colors.charcoalMist }} />
              <Typography sx={{ fontSize: styledTheme.newFontSize.get('m'), fontWeight: '500', marginTop: '0.5px' }}>
                {moment(scheduleData?.start_time, 'HH:mm').format('h:mm a')} -{' '}
                {moment(scheduleData?.end_time, 'HH:mm').format('h:mm a')}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: styledTheme.newSpace.get('xs') }}>
              <Avatar
                alt='avatar'
                style={{
                  background: styledTheme.colors.dandelion,
                  color: 'black',
                  fontSize: 10,
                  width: 20,
                  height: 20,
                }}
              >
                {instructorsData?.first_name?.[0]?.toUpperCase() || ''}
                {instructorsData?.last_name?.[0]?.toUpperCase() || ''}
              </Avatar>
              <Typography sx={{ fontSize: styledTheme.newFontSize.get('m'), fontWeight: '500' }}>
                {getFullName(instructorsData?.first_name, instructorsData?.last_name)}
              </Typography>
            </Box>
          </Box>
          <SeeDetailsLayout
            handleSeeDetails={() =>
              handleSeeDetails({
                activity_id: activityData as SignUpActivityIdsProps,
                schedule: isCamp
                  ? [{ ...alloted } as unknown as ScheduleEditProp]
                  : [scheduleData as unknown as ScheduleEditProp],
              })
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default SingleChildrenLayout;
