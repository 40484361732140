import { useTranslation } from 'react-i18next';

import { KeyboardArrowRight } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';

import { useGetTheme } from '@src/hooks/useGetTheme';

const SeeDetailsLayout = ({ handleSeeDetails }: { handleSeeDetails: () => void }) => {
  const { t } = useTranslation();
  const { styledTheme } = useGetTheme();
  return (
    <>
      <Divider
        sx={{
          border: 'none',
          height: '1px',
          backgroundImage: `linear-gradient(to right, ${styledTheme.colors.paleGray} 50%, transparent 50%)`,
          backgroundSize: '20px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: styledTheme.colors.orange,
          cursor: 'pointer',
          width: 'fit-content',
        }}
        onClick={handleSeeDetails}
      >
        <Typography sx={{ fontSize: styledTheme.newFontSize.get('m'), fontWeight: '500' }}>
          {t('seeDetails')}
        </Typography>
        <KeyboardArrowRight fontSize='small' />
      </Box>
    </>
  );
};

export default SeeDetailsLayout;
