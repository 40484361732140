import styled from 'styled-components';

import { Button, IconButton } from '@mui/material';

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.colors.orange,
  color: theme.colors.white,
  textTransform: 'none',
  borderRadius: theme.space.get('2xs'),
  padding: `${theme.space.get('2xs')} ${theme.space.get('m')}`,
  width: 'fit-content',

  '&:hover': {
    backgroundColor: theme.colors.orange,
    color: theme.colors.white,
  },
}));

export const HeaderItem = styled.div<{ $isActive: boolean }>`
  padding: ${({ theme }) => `${theme.newSpace.get('s')} ${theme.newSpace.get('xl')}`};
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  gap: ${({ theme }) => theme.newSpace.get('xs')};
  font-weight: ${({ $isActive }) => ($isActive ? 600 : 500)};
  font-size: ${({ theme }) => theme.newFontSize.get('l')};
  white-space: nowrap;
  color: ${({ $isActive, theme }) => ($isActive ? theme.colors.orange : theme.colors.midnightGray)};
  align-items: center;
  background: ${({ $isActive, theme }) => ($isActive ? theme.colors.ivoryLace : 'transparent')};
  transition: color 0.3s ease, background 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.orange};
    background: ${({ theme }) => theme.colors.autumnLeaf};
  }
`;

export const HeaderRightItem = styled.div<{ $isNotPadding?: boolean }>`
  padding: ${({ $isNotPadding, theme }) =>
    $isNotPadding ? 0 : `${theme.newSpace.get('s')} ${theme.newSpace.get('xl')}`};
  display: flex;
  border-radius: 10px;
  gap: ${({ theme }) => theme.newSpace.get('xs')};
  font-weight: 500;
  font-size: ${({ theme }) => theme.newFontSize.get('l')};
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.midnightGray};
  align-items: center;
`;

export const IconButtonHeaderRightItem = styled(IconButton)`
  display: flex;
  padding: ${({ theme }) => `${theme.newSpace.get('s')} ${theme.newSpace.get('xl')}`};
  border-radius: 10px;
  gap: ${({ theme }) => theme.newSpace.get('xs')};
  font-weight: 500;
  font-size: ${({ theme }) => theme.newFontSize.get('l')};
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.midnightGray};
  align-items: center;
`;

export const StyledLogo = styled.img`
  width: 99px;
  height: 28px;
  cursor: pointer;
`;
