import { useTheme } from 'styled-components';

import { Box, Dialog, styled } from '@mui/material';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const RadioGroupWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderBottom: `1px solid ${theme.styledTheme.colors.paleGray}`,
}));

export const RightContainer = styled('div')(({ theme }) => {
  const styedTheme = useTheme();
  return {
    width: '50%',
    borderLeft: `1px solid ${styedTheme.colors.mistGray}`,
    maxHeight: '40vh',
    overflowY: 'auto',
  };
});

export const LeftContainer = styled('div')({
  width: '50%',
  maxHeight: '40vh',
  overflowY: 'auto',
});
