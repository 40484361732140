import { useTranslation } from 'react-i18next';

import { Box, Tooltip } from '@mui/material';

import { StyledButton } from '../style';
import { AddRowHandleProps, TableCreateRowLayoutProps } from '../type';

export const TableCreateRowLayout: React.FC<TableCreateRowLayoutProps> = ({ addRowData }) => {
  const { t } = useTranslation();
  const { handleAddClick, buttonText } = (addRowData as AddRowHandleProps) || {};

  const handleAddButtonClick = () => {
    handleAddClick?.();
  };

  return (
    <Box>
      <Tooltip title={t('addRow')}>
        <StyledButton onClick={handleAddButtonClick}>{buttonText ? t(buttonText) : `+ ${t('createNew')}`}</StyledButton>
      </Tooltip>
    </Box>
  );
};
