import React, { useEffect, useRef } from 'react';
import moment from 'moment';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';

import { useGetTheme } from '@src/hooks/useGetTheme';

interface DateSelectorProps {
  rangeStart: string;
  rangeEnd: string;
  selectedRange?: { start: string; end: string };
  setSelectedRange: (data: { start: string; end: string }) => void;
}

const DateSelector: React.FC<DateSelectorProps> = ({ rangeEnd, rangeStart, selectedRange, setSelectedRange }) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const currentWeekRef = useRef<HTMLDivElement>(null);

  const weeks: { start: string; end: string }[] = [];
  const current = moment(rangeStart).startOf('isoWeek');
  const endOfRange = moment(rangeEnd).endOf('isoWeek');

  while (current.isBefore(endOfRange)) {
    weeks.push({
      start: current.format('DD MMM YY'),
      end: moment(current).endOf('isoWeek').format('DD MMM YY'),
    });
    current.add(1, 'weeks');
  }

  const { styledTheme } = useGetTheme();

  useEffect(() => {
    if (!selectedRange) {
      const currentWeekStart = moment().startOf('isoWeek');
      const currentWeekEnd = moment().endOf('isoWeek');

      if (currentWeekStart.isSameOrAfter(moment(rangeStart)) && currentWeekEnd.isSameOrBefore(moment(rangeEnd))) {
        const formattedStart = currentWeekStart.format('DD MMM YY');
        const formattedEnd = currentWeekEnd.format('DD MMM YY');

        setSelectedRange({ start: formattedStart, end: formattedEnd });

        if (currentWeekRef.current) {
          currentWeekRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      } else {
        setSelectedRange(weeks[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRange]);

  const handlePreviousWeek = () => {
    if (selectedRange) {
      const currentIndex = weeks.findIndex(
        week => week.start === selectedRange.start && week.end === selectedRange.end
      );

      if (currentIndex > 0) {
        setSelectedRange(weeks[currentIndex - 1]);
      }
    }
  };

  const handleNextWeek = () => {
    if (selectedRange) {
      const currentIndex = weeks.findIndex(
        week => week.start === selectedRange.start && week.end === selectedRange.end
      );

      if (currentIndex < weeks.length - 1) {
        setSelectedRange(weeks[currentIndex + 1]);
      }
    }
  };

  const isPreviousDisabled = selectedRange
    ? weeks[0].start === selectedRange.start && weeks[0].end === selectedRange.end
    : true;

  const isNextDisabled = selectedRange
    ? weeks[weeks.length - 1].start === selectedRange.start && weeks[weeks.length - 1].end === selectedRange.end
    : true;

  if (isDesktop)
    return (
      <Box
        sx={{
          display: 'flex',
          padding: `${styledTheme.newFontSize.get('xl')} 0`,
          position: 'sticky',
          top: 0,
          bgcolor: styledTheme.colors.offWhite,
          gap: styledTheme.newFontSize.get('4xl'),
          overflowX: 'auto',
          zIndex: 1,
        }}
      >
        {weeks.map(week => {
          const isSelected = JSON.stringify(selectedRange) === JSON.stringify(week);
          const isCurrentWeek = moment().startOf('isoWeek').format('DD MMM YY') === week.start;

          return (
            <Typography
              key={week.start}
              ref={isCurrentWeek ? currentWeekRef : null}
              sx={{
                fontSize: styledTheme.newFontSize.get('m'),
                fontWeight: '600',
                p: `${styledTheme.newSpace.get('xs')} ${styledTheme.newFontSize.get('m')}`,
                bgcolor: isSelected ? styledTheme.colors.ebony : styledTheme.colors.paleGray,
                color: isSelected ? styledTheme.colors.white : styledTheme.colors.inkShade,
                borderRadius: '8px',
                cursor: 'pointer',
                flexShrink: 0,
                transition: 'background-color 0.3s',
              }}
              onClick={() => setSelectedRange(week)}
            >
              {week.start} - {week.end}
            </Typography>
          );
        })}
      </Box>
    );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: `${styledTheme.newSpace.get('xl')} 0`,
        position: 'sticky',
        top: 0,
        bgcolor: styledTheme.colors.offWhite,
        zIndex: 1,
      }}
    >
      <IconButton
        onClick={handlePreviousWeek}
        disabled={isPreviousDisabled}
        sx={{
          width: 32,
          height: 32,
          color: isPreviousDisabled ? styledTheme.colors.lightGray : styledTheme.colors.charcoalMist,
          cursor: isPreviousDisabled ? 'not-allowed' : 'pointer',
        }}
      >
        <KeyboardArrowLeft sx={{ width: 16, height: 16 }} />
      </IconButton>
      <Typography sx={{ flex: 1, textAlign: 'center', fontSize: styledTheme.newFontSize.get('xl'), fontWeight: '600' }}>
        {selectedRange?.start} - {selectedRange?.end}
      </Typography>
      <IconButton
        onClick={handleNextWeek}
        disabled={isNextDisabled}
        sx={{
          width: 32,
          height: 32,
          color: isNextDisabled ? styledTheme.colors.lightGray : styledTheme.colors.charcoalMist,
          cursor: isNextDisabled ? 'not-allowed' : 'pointer',
        }}
      >
        <KeyboardArrowRight sx={{ width: 16, height: 16 }} />
      </IconButton>
    </Box>
  );
};

export default DateSelector;
