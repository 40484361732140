import styled from 'styled-components';

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const StyledLabel = styled.div`
  text-transform: capitalize;
  font-size: ${({ theme }) => theme.newFontSize.get('2xl')};
  font-weight: 600;
  margin-right: ${({ theme }) => theme.newSpace.get('s')};
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  left: 40%;
`;
