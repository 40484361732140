import styled from 'styled-components';

import { Breakpoints } from '@src/lib/styles/breakpoints';

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: ${({ theme }) => theme.space.get('s')};
  padding: ${({ theme }) => theme.space.get('s')};
  ${Breakpoints.DESKTOP_SMALL} {
    grid-template-columns: repeat(4, 1fr);
  }
  ${Breakpoints.TABLET} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${Breakpoints.MOBILE} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${Breakpoints.MOBILE_SMALL} {
    grid-template-columns: 1fr;
  }
`;

export const SignupGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin-top: 9px;
  flex: 1;
  padding-bottom: ${({ theme }) => theme.newSpace.get('xl')};
  gap: ${({ theme }) => theme.newSpace.get('4xl')};
  ${Breakpoints.DESKTOP_SMALL} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${Breakpoints.TABLET_SMALL} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${Breakpoints.MOBILE} {
    grid-template-columns: 1fr;
  }
`;

export const ContentContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.space.get('s')};
  border: 1px solid ${({ theme }) => theme.colors.deepGrayBorder};
`;

export const ProfileCard = styled.div`
  background: white;
  border-radius: ${({ theme }) => theme.space.get('xs')};
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.mistGray};
`;

export const ProfileHeader = styled.div`
  padding: calc(${({ theme }) => theme.space.get('m')} - 3px) ${({ theme }) => theme.space.get('s')}
    ${({ theme }) => theme.space.get('s')} ${({ theme }) => theme.space.get('s')};
  flex: 1;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mistGray};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.space.get('xs')};
`;

export const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.get('2xs')};
`;

export const ProfileName = styled.div`
  font-weight: 600;
  font-size: calc(${({ theme }) => theme.fontSize.get('m')} - 2px);
  text-transform: capitalize;
`;

export const ProfileID = styled.div`
  font-weight: 500;
  font-size: calc(${({ theme }) => theme.fontSize.get('xs')} + 1px);
  color: grey;
`;

export const ProfileInfo = styled.div`
  padding: ${({ theme }) => theme.space.get('s')} calc(${({ theme }) => theme.space.get('m')} - 3px);
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.get('2xs')};
`;

export const InfoTitle = styled.div`
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSize.get('s')};
  text-transform: capitalize;
`;

export const SignUpContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  flex: 1;
  margin: calc(${({ theme }) => theme.space.get('s')} * -1) 0;
  ${Breakpoints.DESKTOP_SMALL} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const SignUpSidebar = styled.div`
  grid-column: 1/4;
  border-right: 1px solid ${({ theme }) => theme.colors.mistGray};
  padding: ${({ theme }) => theme.space.get('m')} calc(${({ theme }) => theme.space.get('m')} - 3px)
    ${({ theme }) => theme.space.get('m')} 0;

  ${Breakpoints.DESKTOP_SMALL} {
    padding: ${({ theme }) => theme.space.get('m')} 0;
    border-right: none;
  }
`;

export const SignUpActivityContent = styled.div`
  grid-column: 4/-1;
  display: flex;
  flex-direction: column;
  gap: calc(${({ theme }) => theme.space.get('m')} - 3px);
  & > div:first-child {
    display: flex;
    flex-direction: column;
    gap: calc(${({ theme }) => theme.space.get('m')} - 3px);
    flex: 1;
    padding: ${({ theme }) => theme.space.get('m')} 0 0 calc(${({ theme }) => theme.space.get('m')} - 3px);
  }
`;

export const ActivityFooter = styled.div`
  position: sticky;
  top: calc(${({ theme }) => theme.space.get('s')} * -1);
  padding: ${({ theme }) => theme.space.get('m')};
  border-top: 1px solid ${({ theme }) => theme.colors.mistGray};
  background: ${({ theme }) => theme.colors.offWhite};
`;

export const WeeklyScheduleContainer = styled.div`
  flex: 1;
  display: flex;
  background: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.deepGrayBorder};

  ${Breakpoints.DESKTOP_SMALL} {
    border: 0;
    border-radius: 0;
  }
`;

export const WeeklyScheduleHeader = styled.div`
  border-radius: 16px 16px 0 0;
  padding: ${({ theme }) => theme.space.get('m')} calc(${({ theme }) => theme.space.get('l')} + 8px);
  border-bottom: 0.5px solid #0000004d;
  display: flex;
  gap: ${({ theme }) => theme.space.get('s')};
  background: ${({ theme }) => theme.colors.offWhite};
  ${Breakpoints.DESKTOP_SMALL} {
    padding: ${({ theme }) => theme.space.get('m')} ${({ theme }) => theme.space.get('3xs')};
    flex-direction: column;
    border: 0;
    border-radius: 0;
  }
`;

export const WeeklyScheduleDayName = styled.div`
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: calc(${({ theme }) => theme?.fontSize.get('xs')} + 1px);
  ${Breakpoints.DESKTOP_SMALL} {
    font-weight: 700;
    text-align: left;
    font-size: ${({ theme }) => theme?.fontSize.get('xs')};
    color: ${({ theme }) => theme.colors.mediumGray};
    border-bottom: 1px solid ${({ theme }) => theme.colors.deepGrayBorder};
  }
`;

export const WeeklyScheduleContent = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.space.get('m')} ${({ theme }) => theme.newSpace.get('5xl')};
  ${Breakpoints.DESKTOP_SMALL} {
    padding: ${({ theme }) => theme.space.get('m')} ${({ theme }) => theme.space.get('3xs')};
  }
  flex: 1;
  & > div:last-child {
    & > div:last-child {
      border-right: none;
    }
  }
`;

export const WeeklyScheduleDayColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.get('s')};
  margin-left: ${({ theme }) => theme.space.get('xs')};
`;

export const WeeklyScheduleActivityList = styled.div`
  padding-right: ${({ theme }) => theme.space.get('xs')};
  border-right: 1px solid ${({ theme }) => theme.colors.mistGray};
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.get('s')};
  ${Breakpoints.DESKTOP_SMALL} {
    border: 0;
  }
`;

export const StyledSubmitHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `${theme.newSpace.get('xl')} ${theme.space.get('2xl')}`};
  margin: ${({ theme }) => `0 calc(${theme.space.get('2xl')} * -1)`};
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.newSpace.get('3xs')};
  border-bottom: 1px solid ${({ theme }) => theme.colors.mistGray};
  ${Breakpoints.DESKTOP_SMALL} {
    padding: ${({ theme }) => `${theme.space.get('m')} ${theme.space.get('2xl')}`};
  }
`;

export const NoActivityFound = styled.div`
  text-align: center;
  padding: ${({ theme }) => theme.space.get('s')};
  color: ${({ theme }) => theme.colors.mediumGray};
`;
