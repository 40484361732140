import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckCircle, Close } from '@mui/icons-material';
import { Box, Drawer, IconButton, Typography } from '@mui/material';

import { useGetTheme } from '@src/hooks/useGetTheme';
import { Program } from '@src/Models/program';

import { SignUpContext } from '..';

import { customOption } from './CustomOption';

interface MobileDrawerProps {
  programs: Program[] | undefined;
}

const MobileDrawer = ({ programs }: MobileDrawerProps) => {
  const { selectedProgram, setSelectedProgram, isProgramMobileDrawerOpen, setIsProgramMobileDrawerOpen } =
    useContext(SignUpContext);

  const { t } = useTranslation();
  const { styledTheme } = useGetTheme();

  const handleDrawerToggle = () => {
    setIsProgramMobileDrawerOpen?.(prev => !prev);
  };

  const handleProgramSelect = (data: Program) => {
    setSelectedProgram?.(data);
    handleDrawerToggle();
  };

  const renderDrawerChildrenList = () => (
    <Box>
      {programs?.map(program => (
        <Box
          key={program.id}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            p: `${styledTheme.newSpace.get('s')} ${styledTheme.newSpace.get('3xl')}`,
            '&:hover': {
              backgroundColor: styledTheme.colors.paleGray,
            },
          }}
          onClick={() => handleProgramSelect(program)}
        >
          {customOption(program)}
          {program.id === selectedProgram?.id ? (
            <CheckCircle sx={{ color: styledTheme.colors.brightOrangeText }} fontSize='small' />
          ) : null}
        </Box>
      ))}
    </Box>
  );

  return (
    <Drawer
      anchor='bottom'
      open={isProgramMobileDrawerOpen}
      onClose={handleDrawerToggle}
      PaperProps={{
        sx: {
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          pb: 3,
        },
      }}
    >
      <IconButton
        onClick={handleDrawerToggle}
        sx={{
          position: 'absolute',
          top: styledTheme.newSpace.get('xs'),
          right: styledTheme.newSpace.get('xl'),
        }}
      >
        <Close />
      </IconButton>
      <Box
        sx={{
          m: 2,
          mt: 5,
          mb: 1,
        }}
      >
        <Typography sx={{ fontWeight: '700', fontSize: styledTheme.newFontSize.get('xl') }}>
          {t('selectProgram')}
        </Typography>
      </Box>
      {renderDrawerChildrenList()}
    </Drawer>
  );
};

export default MobileDrawer;
