import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import moment from 'moment';

import { Box, useMediaQuery } from '@mui/material';

import Loader from '@src/components/Loader';
import { ActivityOrderSummary } from '@src/components/Modals/ActivityOrderSummary';
import { AuthContext } from '@src/contexts/AuthContextProvider';
import { GlobalUIContext } from '@src/contexts/GlobalUIContext';
import { useAxiosApi } from '@src/hooks/useAxiosApi';
import useDebounce from '@src/hooks/useDebounce';
import { useGetTheme } from '@src/hooks/useGetTheme';
import { AsaSubRoutes, initialTableFilterProps, Routes, weekDaysFullNames } from '@src/lib/constants';
import { convertTimeRange, dateBwtComparison, gradeLevelMapping } from '@src/lib/helper';
import { Breakpoints } from '@src/lib/styles/breakpoints';
import { Activity } from '@src/Models/activity';
import { ScheduleOffDays } from '@src/Models/schedule';
import { Children } from '@src/Models/user';

import FallbackScreen from '../FallbackScreen';
import { DayActivity, ScheduleActivitiesData } from '../type';
import { SignUpContext } from '..';

import DateSelector from './DateSelector';
import Footer from './Footer';
import ScheduleLayout from './ScheduleLayout';
import TrialWarning from './TrialWarning';

const ViewSchedule = () => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { handleScheduleActivities, handleGetOffDays, handleProgramCheckout } = useAxiosApi();
  const { userDetail } = useContext(AuthContext);
  const { selectedChildren } = useContext(GlobalUIContext);

  const { selectedProgram, setIsProgramWebDrawerOpen, setIsProgramMobileDrawerOpen } = useContext(SignUpContext);
  const isCampProgram = selectedProgram?.type === 'CAMP';
  const [isOrderSummary, setIsOrderSummary] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRange, setSelectedRange] = useState<{ start: string; end: string }>();
  const [visibleSelectedRange, setVisibleSelectedRange] = useState<{ start: string; end: string }>();
  const [scheduleActivitiesData, setScheduleActivitiesData] = useState<ScheduleActivitiesData>();
  const [offDaysData, setOffDaysData] = useState<ScheduleOffDays[]>();

  const isAll = selectedChildren?.id === 'all';
  const { styledTheme } = useGetTheme();

  const getNotRegistredStatus = () => {
    if (!scheduleActivitiesData) return true;

    for (const data of Object.values(scheduleActivitiesData) as DayActivity[]) {
      if (data) return false;
    }

    return true;
  };

  const handleSelectProgram = () => {
    if (isDesktop) {
      setIsProgramWebDrawerOpen?.(true);
    } else {
      setIsProgramMobileDrawerOpen?.(true);
    }
  };

  const handleRegisterClick = (withSignup: boolean) => {
    let link = `${Routes.ASA}${AsaSubRoutes.SIGN_UP}?programId=${selectedProgram?.id}`;

    if (userDetail?.role === 'student') {
      link += `&studentId=${userDetail?.student_id?.id}&grades=${
        gradeLevelMapping[userDetail?.student_id?.grade_level]
      }`;
    } else {
      link += `&studentId=${selectedChildren?.id}&grades=${
        gradeLevelMapping[(selectedChildren as Children)?.grade_level]
      }`;
    }

    if (withSignup) {
      link += `&signUpId=${scheduleActivitiesData?.signup?.id}`;
    }

    navigate(link);
  };

  const fetchActivitySignUp = useCallback(async () => {
    if (!selectedProgram) return;

    setLoading(true);
    setSelectedRange(undefined);

    let student_id;

    if (userDetail?.role === 'student') {
      student_id = userDetail?.student_id?.id;
    } else {
      student_id = isAll ? undefined : selectedChildren?.id;
    }

    try {
      const offDays = (await handleGetOffDays({
        data: { ...initialTableFilterProps, program_id: selectedProgram.id },
      })) as ScheduleOffDays[];

      const res = await handleScheduleActivities({
        program_id: selectedProgram.id,
        student_id,
      });

      setOffDaysData(offDays);
      setScheduleActivitiesData(res?.data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChildren?.id, selectedProgram?.id, userDetail?.id]);

  useEffect(() => {
    fetchActivitySignUp();
  }, [fetchActivitySignUp, selectedProgram]);

  const debouncedSetVisibleSelectedRange = useDebounce(() => {
    setVisibleSelectedRange(selectedRange);
  }, 500);

  useEffect(() => {
    if (selectedRange) {
      if (visibleSelectedRange) {
        debouncedSetVisibleSelectedRange();
      } else {
        setVisibleSelectedRange(selectedRange);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRange?.start]);

  if (!selectedProgram)
    return (
      <FallbackScreen
        heading={t('noProgramSelected')}
        subHeading={t('chooseProgram')}
        buttonText={t('selectProgram')}
        imgUrl='/icons/calendar.svg'
        handleButtonClick={handleSelectProgram}
      />
    );

  if (loading) return <Loader />;

  if (getNotRegistredStatus())
    return (
      <FallbackScreen
        heading={t('youHaventRegisteredYet')}
        subHeading={t(isAll ? 'registrationInstruction' : 'chooseActivityAndSignUp')}
        buttonText={'+ ' + t('register')}
        imgUrl='/icons/register.svg'
        handleButtonClick={() => handleRegisterClick(false)}
        disabled={(() => {
          if (isAll) {
            return true;
          }

          if (!selectedProgram?.signup_start_date || !selectedProgram?.signup_end_date) {
            return true;
          }

          const startDateTime = `${selectedProgram?.signup_start_date}T${selectedProgram?.signup_start_time}`;
          const endDateTime = `${selectedProgram?.signup_end_date}T${selectedProgram?.signup_end_time}`;

          const isWithinSignupPeriod = dateBwtComparison(startDateTime, endDateTime);
          return !isWithinSignupPeriod;
        })()}
      />
    );

  // Combine the trial end date and time into a single moment object
  const trialEndDateTime = moment(
    scheduleActivitiesData?.signup?.program_id?.trial_end_date_time,
    'YYYY-MM-DD HH:mm:ss'
  );

  const now = moment();

  // Check if today's date is after the trial end date or if the trial end time has passed on the same day
  const hasTrialEnded = now.isAfter(trialEndDateTime);

  const formattedDate = trialEndDateTime.format('DD MMM YY');

  const handlePaymentRedirect = async (order_id?: string) => {
    if (!order_id) return console.error('Id not found');

    try {
      const res = await handleProgramCheckout(order_id);
      window.location.href = res?.redirect_url;
    } catch (error) {
      console.log('Error', error);
    }
  };

  let studentImage;

  if (userDetail?.role === 'student') {
    studentImage = (selectedChildren as Children)?.image?.url;
  } else {
    studentImage = userDetail?.student_id?.image?.url;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        backgroundColor: styledTheme.colors.offWhite,
        flexDirection: 'column',
        paddingBottom: styledTheme?.newSpace.get('2xl'),
        [Breakpoints.DESKTOP_SMALL]: {
          gap: styledTheme.newSpace.get('xl'),
        },
      }}
    >
      <ActivityOrderSummary
        handleClose={() => setIsOrderSummary(false)}
        isOpen={isOrderSummary}
        showWarnings={false}
        studentImage={studentImage}
        isCampProgram={isCampProgram}
        summaryData={(() => {
          const data: Record<
            string,
            {
              name: string;
              amount: number;
              imageUrl?: string;
            }
          > = {};

          if (isCampProgram) {
            Object.entries(scheduleActivitiesData?.activity_ids || {}).forEach(([key, activityData]) => {
              const { camp_basic_cost, camp_material_cost, image, name } =
                activityData?.[0].activity_data?.activity_id || {};

              const keyName = convertTimeRange(key);

              data[keyName] = {
                amount: Number(camp_basic_cost) + Number(camp_material_cost),
                name,
                imageUrl: image?.url,
              };
            });
          } else {
            for (const day of weekDaysFullNames) {
              const scheduleData = scheduleActivitiesData?.[day]?.[0]?.activity_data as Activity | undefined;

              if (scheduleData) {
                const name = scheduleData.name;

                const amount =
                  (scheduleData.included ? 0 : Number(scheduleData?.basic_cost) || 0) +
                    Number(scheduleData?.material_cost) || 0;

                const imageUrl = scheduleData.image?.url;
                data[day] = {
                  name,
                  amount,
                  imageUrl,
                };
              }
            }
          }

          return data;
        })()}
        showAvailableSlots={false}
        trialDays={selectedProgram?.trial_days || '0'}
        handleSubmit={() => {
          handlePaymentRedirect(scheduleActivitiesData?.signup?.id);
          setIsOrderSummary(false);
        }}
        programName={selectedProgram?.name || ''}
        buttonText='Proceed to Pay'
      />

      {isDesktop ? (
        <>
          <TrialWarning hasTrialEnded={hasTrialEnded} trialEndDate={formattedDate} />
          <DateSelector
            rangeStart={selectedProgram.start_date}
            rangeEnd={selectedProgram.end_date}
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
          />
        </>
      ) : (
        <>
          <DateSelector
            rangeStart={selectedProgram.start_date}
            rangeEnd={selectedProgram.end_date}
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
          />
          <TrialWarning hasTrialEnded={hasTrialEnded} trialEndDate={formattedDate} />
        </>
      )}
      <ScheduleLayout
        visibleSelectedRange={visibleSelectedRange}
        scheduleActivitiesData={scheduleActivitiesData}
        offDaysData={offDaysData}
        isAll={isAll}
        selectedProgram={selectedProgram}
      />
      {!isAll ? (
        <Footer
          cost={scheduleActivitiesData?.signup?.cost || '0.00'}
          hasTrialEnded={hasTrialEnded}
          handleButtonClick={() => {
            if (hasTrialEnded) {
              setIsOrderSummary(true);
              return;
            }

            handleRegisterClick(true);
          }}
          disabled={(() => {
            if (hasTrialEnded) {
              return ['PAID', 'ZERO_FEE'].includes(scheduleActivitiesData?.signup?.payment_status || '');
            }

            return false;
          })()}
        />
      ) : null}
    </Box>
  );
};

export default ViewSchedule;
