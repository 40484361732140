import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Close as CloseIcon, KeyboardArrowRight } from '@mui/icons-material';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { AuthContext } from '@src/contexts/AuthContextProvider';
import { useAxiosApi } from '@src/hooks/useAxiosApi';
import { useGetTheme } from '@src/hooks/useGetTheme';
import { AsaSubRoutes, MerchandiseSubRoutes, RoleRoutes, Routes } from '@src/lib/constants';
import { Children, StudentId } from '@src/Models/user';
import { AccordionLayout } from '@src/screens/Dashboard/AccordionLayout';
import { getCookie } from '@src/utils/getCookie';

import { DrawerHeader, StyledLogo } from '../style';

import GeneralLayout from './GeneralLayout';
import LanguageDrawerLayout from './LanguageDrawerLayout';
import ProfileDrawerLayout from './ProfileDrawerLayout';
import ProfilesDrawerLayout from './ProfilesDrawerLayout';

interface SideBarDrawerListProps {
  handleSelectModule: (module: string) => void;
  toggleDrawer: (newOpen: boolean) => () => void;
  selectedModule: string;
}

interface NewSideDrawerProps extends Pick<SideBarDrawerListProps, 'toggleDrawer'> {
  isOpen: boolean;
}

const NewSideDrawer = ({ isOpen, toggleDrawer }: NewSideDrawerProps) => {
  const [selectedModule, setSelectedModule] = useState<string>('');

  const handleSelectModule = (module: string) => {
    setSelectedModule(module);
  };

  const { styledTheme } = useGetTheme();
  return (
    <Drawer open={isOpen} onClose={toggleDrawer(false)}>
      {!selectedModule ? (
        <>
          <DrawerHeader>
            <StyledLogo src='/logo.svg' alt='logo' />
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon sx={{ color: styledTheme.colors.ebony }} />
            </IconButton>
          </DrawerHeader>
          <Divider />
        </>
      ) : null}
      <Box sx={{ width: 320, p: selectedModule ? 0 : `24px 0`, height: '100%' }} role='presentation'>
        <SideBarDrawer
          toggleDrawer={toggleDrawer}
          handleSelectModule={handleSelectModule}
          selectedModule={selectedModule}
        />
      </Box>
    </Drawer>
  );
};

const SideBarDrawer = ({ toggleDrawer, handleSelectModule, selectedModule }: SideBarDrawerListProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedProfileId = useSearchParams()[0].get('selectedProfileId');
  const { handleSwitchUserRole } = useAxiosApi();
  const { userDetail, handleSetUserDetails } = useContext(AuthContext);

  const handleSwitchStaffCumParent = async () => {
    try {
      await handleSwitchUserRole({ data: { role: userDetail?.role === 'parent' ? 'staff' : 'parent' } });
      const tokenValue = getCookie('access_token');
      await handleSetUserDetails(tokenValue || '');

      if (userDetail?.role === 'parent') {
        navigate(Routes.ASA + AsaSubRoutes.PROGRAMS);
      } else {
        navigate(Routes.ASA + AsaSubRoutes.SCHEDULING + RoleRoutes.PARENT);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const DrawerList = [
    {
      key: 'asa',
      text: 'activities',
      url: '/icons/menu-board.svg',
      redirectionUrl: Routes.ASA + AsaSubRoutes.SCHEDULING + RoleRoutes.PARENT,
    },
    {
      key: 'merchandise',
      text: 'merchandise',
      url: '/icons/shopping-bag.svg',
      redirectionUrl: Routes.MERCHANDISE + MerchandiseSubRoutes.DASHBOARD,
    },
    { key: null, text: 'changeLanguage', url: '/icons/group.svg' },
    { key: null, text: 'profile', url: '/icons/profile.svg' },
    { key: null, text: 'generalInfo', url: '/icons/info-circle.svg' },
    ...(userDetail?.ps_role === 'staff_cum_parent'
      ? [
          {
            key: null,
            text: `${t('switchTo')} ${userDetail?.role === 'parent' ? t('staff') : t('parent')}`,
            url: '/icons/swap.svg',
            isStaffCumParent: true,
          },
        ]
      : []),
  ];

  const { headers } = useContext(AuthContext);

  const handleNavigate = (redirectionUrl: string) => {
    navigate(redirectionUrl);
    toggleDrawer(false);
  };

  switch (selectedModule) {
    case 'changeLanguage':
      return (
        <GeneralLayout handleBack={() => handleSelectModule('')} headerText='changeLanguage'>
          <LanguageDrawerLayout />
        </GeneralLayout>
      );

    case 'profile':
      if (selectedProfileId || userDetail?.role === 'student') {
        let childData: Children | StudentId | undefined = userDetail?.children?.find(
          item => item.id === selectedProfileId
        );
        const isStudent = userDetail?.role === 'student';

        if (isStudent) {
          childData = userDetail?.student_id;
        }

        if (!childData) {
          console.error('No child Data found found');
          return null;
        }

        return (
          <GeneralLayout
            handleBack={() => {
              if (isStudent) {
                return handleSelectModule('');
              }

              const currentPath = window.location.pathname;
              navigate(currentPath);
            }}
            headerText='profile'
          >
            <ProfileDrawerLayout childData={childData} />
          </GeneralLayout>
        );
      }

      return (
        <GeneralLayout handleBack={() => handleSelectModule('')} headerText='profile'>
          <ProfilesDrawerLayout />
        </GeneralLayout>
      );

    case 'generalInfo':
      return (
        <GeneralLayout handleBack={() => handleSelectModule('')} headerText='generalInfo'>
          <AccordionLayout />
        </GeneralLayout>
      );

    default:
      return (
        <List sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {DrawerList.map(({ key, text, url, redirectionUrl, isStaffCumParent }) => {
            if (!headers || !(headers === 'super_admin' || (typeof key === 'string' ? headers[key] : true)))
              return null;

            return (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={e => {
                    e.stopPropagation();

                    if (redirectionUrl) {
                      handleNavigate(redirectionUrl);
                      return;
                    }

                    if (isStaffCumParent) {
                      handleSwitchStaffCumParent();
                    } else {
                      handleSelectModule(text);
                    }
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 'fit-content', mr: 0.8 }}>
                    <img src={url} alt='Icon' />
                  </ListItemIcon>
                  <ListItemText primary={t(text)} />
                  <KeyboardArrowRight />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      );
  }
};

export default NewSideDrawer;
