import { PropsWithChildren, useContext, useEffect } from 'react';

import { useMediaQuery } from '@mui/material';

import { AuthContext } from '@src/contexts/AuthContextProvider';
import { GlobalUIContext } from '@src/contexts/GlobalUIContext';

import MobileHeader from '../ParentMainLayout/MobileHeader';
import WebHeader from '../ParentMainLayout/WebHeader';

import MainHeader from './MainHeader';
import MapSubHeader from './MapSubHeader';
import MerchandiseSubHeader from './MerchandiseSubHeader';
import { StyleChildContainer, StyledMainContainer, StyledParentChildWrapper } from './styles';
import SubHeaders from './SubHeaders';

const MainLayout = ({
  children,
  isBreadcrumb,
  hasMerchandiseSubHeader,
  isSubModule,
}: { isBreadcrumb?: boolean; hasMerchandiseSubHeader?: boolean; isSubModule?: boolean } & PropsWithChildren) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const { userDetail } = useContext(AuthContext);
  const { setWalkThrough } = useContext(GlobalUIContext);
  const { role = '' } = userDetail || {};
  const isParentLayout = role && ['parent', 'student'].includes(role);

  useEffect(() => {
    if (userDetail?.tuts && setWalkThrough) {
      for (const [phase, value] of Object.entries(userDetail.tuts)) {
        if (value) {
          setWalkThrough(prev => ({ ...prev, phase: Number(phase.slice(-1)) }));
          break;
        }
      }
    }
  }, [userDetail?.tuts, setWalkThrough]);

  return (
    <StyledMainContainer>
      {!isParentLayout ? <MainHeader /> : null}
      {isDesktop && isParentLayout ? <WebHeader /> : null}

      {isSubModule && isDesktop && !isParentLayout ? <SubHeaders /> : null}
      {isBreadcrumb && !isParentLayout ? <MapSubHeader /> : null}
      {hasMerchandiseSubHeader ? (
        <>
          {!isDesktop ? <MobileHeader hideSwitchKidsDropdown heading='merchandise' /> : null}
          <MerchandiseSubHeader />
        </>
      ) : null}
      {isParentLayout ? (
        <StyledParentChildWrapper>{children}</StyledParentChildWrapper>
      ) : (
        <StyleChildContainer $isBreadcrumbOff={!isBreadcrumb}>{children}</StyleChildContainer>
      )}
    </StyledMainContainer>
  );
};

export default MainLayout;
