import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { AccessTime } from '@mui/icons-material';
import { Avatar, Box, Typography } from '@mui/material';

import { StyledFreeActivity } from '@src/components/ProgramActivityCard/style';
import { useGetTheme } from '@src/hooks/useGetTheme';
import { Activity } from '@src/Models/activity';
import { ScheduleEditProp, WeekDays } from '@src/Models/activityMapping';
import { Activity as SignupActivity, SignUpActivityIdsProps } from '@src/screens/asa/signUp/type';

import { SignUpContext } from '../..';
import { CampActivity, DayActivity } from '../../type';

import HolidayLayout from './HolidayLayout';
import SeeDetailsLayout from './SeeDetailsLayout';

interface AllChildrenLayoutProps {
  date: string;
  day: WeekDays;
  dayActivityData?: DayActivity[];
  holiday?: string;
  handleSeeDetails: (data: Pick<SignupActivity, 'schedule' | 'activity_id'>) => void;
}

const AllChildrenLayout = ({ date, day, holiday, dayActivityData, handleSeeDetails }: AllChildrenLayoutProps) => {
  const { styledTheme } = useGetTheme();
  const { selectedProgram } = useContext(SignUpContext);
  const isCamp = selectedProgram?.type === 'CAMP';
  const { t } = useTranslation();

  if (!dayActivityData?.[0]?.activity_data) return null;

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Box
        sx={{
          minWidth: '64px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography sx={{ fontSize: styledTheme.newFontSize.get('4xl'), fontWeight: '600' }}>{date}</Typography>
        <Typography sx={{ fontSize: styledTheme.newFontSize.get('m'), fontWeight: '500', textTransform: 'capitalize' }}>
          {day.slice(0, 3)}
        </Typography>
      </Box>
      {holiday ? (
        <HolidayLayout holiday={holiday} />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: styledTheme.newSpace.get('xl'), flex: 1 }}>
          {dayActivityData?.map(data =>
            data?.students?.map(studentData => {
              const activityData: Record<string, string | boolean> = { alloted: !!studentData.alloted };

              if (isCamp) {
                activityData['image'] = (data?.activity_data as CampActivity)?.activity_id?.image?.url || '';
                activityData['name'] = (data?.activity_data as CampActivity)?.activity_id?.name || '';
                activityData['start_time'] = (data?.activity_data as CampActivity)?.start_time || '';
                activityData['end_time'] = (data?.activity_data as CampActivity)?.end_time || '';
              } else {
                const activityDetails = data?.activity_data as Activity | undefined;
                activityData['image'] = activityDetails?.image?.url || '';
                activityData['name'] = activityDetails?.name || '';
                activityData['start_time'] = data?.schedule?.start_time || '';
                activityData['end_time'] = data?.schedule?.end_time || '';
              }

              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: styledTheme.newSpace.get('s'),
                    p: styledTheme.newSpace.get('m'),
                    flex: 1,
                    borderRadius: '12px',
                    boxShadow: '0px 4px 12px 0px #00000005',
                    bgcolor: styledTheme.colors.white,
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: styledTheme.newSpace.get('m') }}>
                      <Box sx={{ display: 'flex', gap: styledTheme.newSpace.get('2xs'), alignItems: 'center' }}>
                        <Avatar
                          alt='Group Profile'
                          src={activityData?.image || '/icons/defaultActivityImage.svg'}
                          sx={{ width: 32, height: 32 }}
                        />
                        <Typography sx={{ fontSize: styledTheme.newFontSize.get('xl'), fontWeight: '500' }}>
                          {activityData.name}
                        </Typography>
                      </Box>

                      <Box sx={{ display: 'flex', gap: styledTheme.newSpace.get('3xs'), alignItems: 'center' }}>
                        <AccessTime sx={{ width: 12, height: 12, color: styledTheme.colors.charcoalMist }} />
                        <Typography
                          sx={{ fontSize: styledTheme.newFontSize.get('m'), fontWeight: '500', marginTop: '0.5px' }}
                        >
                          {moment(activityData?.start_time, 'HH:mm').format('h:mm a')} -{' '}
                          {moment(activityData?.end_time, 'HH:mm').format('h:mm a')}
                        </Typography>
                        <StyledFreeActivity $isWaiting={!activityData.alloted}>
                          {t(activityData.alloted ? 'alloted' : 'waiting')}
                        </StyledFreeActivity>
                      </Box>
                    </Box>

                    <Avatar alt='Group Profile' src={studentData?.image?.url} sx={{ width: 32, height: 32 }} />
                  </Box>
                  <SeeDetailsLayout
                    handleSeeDetails={() =>
                      handleSeeDetails({
                        activity_id: isCamp
                          ? (data?.activity_data as CampActivity)?.activity_id
                          : (data?.activity_data as SignUpActivityIdsProps),
                        schedule: [(isCamp ? data?.activity_data : data?.schedule) as unknown as ScheduleEditProp],
                      })
                    }
                  />
                </Box>
              );
            })
          )}
        </Box>
      )}
    </Box>
  );
};

export default AllChildrenLayout;
