import { ReactNode } from 'react';
import { t } from 'i18next';

import { Avatar, AvatarGroup, Box } from '@mui/material';

import { getFullName } from '@src/lib/helper';
import getTheme, { ModeType } from '@src/lib/styles/theme';
import { Children } from '@src/Models/user';

import { StyledTypography } from './style';
export const customOption = (data: unknown, allChildrenData?: Children[]): ReactNode => {
  const childrenData = data as Children;
  const isAll = childrenData.id === 'all';
  const theme = getTheme(localStorage.getItem('mode') as ModeType);
  return (
    <Box key={childrenData?.id} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {isAll ? (
        <AvatarGroup
          max={3}
          slotProps={{
            additionalAvatar: { sx: { width: 24, height: 24, fontSize: theme.newFontSize.get('m') } }, // Custom size for +X avatar
          }}
        >
          {allChildrenData?.map(item => (
            <Avatar alt='Group Profile' src={item.image?.url} sx={{ width: 24, height: 24 }} />
          ))}
        </AvatarGroup>
      ) : (
        <Avatar alt='Profile' src={childrenData?.image?.url} sx={{ width: 24, height: 24 }} />
      )}
      <StyledTypography>
        {isAll ? t('all') : getFullName(childrenData?.first_name, childrenData?.last_name)}
      </StyledTypography>
    </Box>
  );
};
