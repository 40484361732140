import { ReactNode } from 'react';

import { Box } from '@mui/material';

import { Program } from '@src/Models/program';

import { StyledTypography } from './style';

export const customOption = (data: unknown): ReactNode => {
  const programData = data as Program;
  return (
    <Box key={programData?.id} sx={{ display: 'flex', alignItems: 'center' }}>
      <StyledTypography>{programData.name}</StyledTypography>
    </Box>
  );
};
