import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AccordionSummary from '@mui/material/AccordionSummary';

import { ArrowDown } from '@src/components/Icons/ArrowDown';
import { useGetTheme } from '@src/hooks/useGetTheme';
import { GeneralInformationData } from '@src/lib/constants';

import { StyledAccordion, StyledAccordionDetails } from './style';

export const AccordionLayout = () => {
  const { t } = useTranslation();
  const [expandedIndex, setExpandedIndex] = useState(0);
  const { styledTheme } = useGetTheme();

  const handleAccordionChange = (index: number, isExpanded: boolean) => {
    setExpandedIndex(isExpanded ? index : -1);
  };

  return (
    <>
      {GeneralInformationData.map(({ name, descriptions, list }, index) => {
        const expanded = expandedIndex === index;
        return (
          <StyledAccordion
            key={name}
            expanded={expanded}
            onClick={e => e.stopPropagation()}
            onChange={(_, isExpanded) => {
              handleAccordionChange(index, isExpanded);
            }}
          >
            <AccordionSummary
              sx={{ fontSize: 16, fontWeight: '500', color: styledTheme.colors.gunmental }}
              expandIcon={<ArrowDown />}
              aria-controls={`${name}-content`}
              id={`${name}-header`}
            >
              {t(name)}
            </AccordionSummary>
            {descriptions?.map(description => (
              <StyledAccordionDetails>{description}</StyledAccordionDetails>
            ))}
            {list?.length ? (
              <ul>
                {list.map(({ point, subPoints }) => {
                  return (
                    <li>
                      <StyledAccordionDetails sx={{ padding: '0' }}>
                        {point}
                        {subPoints?.length ? (
                          <ul style={{ paddingBottom: styledTheme.newSpace.get('s') }}>
                            {subPoints?.map(subPoint => (
                              <li>
                                <StyledAccordionDetails sx={{ padding: '0' }}>{subPoint}</StyledAccordionDetails>
                              </li>
                            ))}
                          </ul>
                        ) : null}
                      </StyledAccordionDetails>
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </StyledAccordion>
        );
      })}
    </>
  );
};
