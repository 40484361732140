import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { KeyboardArrowDown, ShoppingCart } from '@mui/icons-material';
import { Badge, Box, IconButton } from '@mui/material';

import { GlobalUIContext } from '@src/contexts/GlobalUIContext';
import { useAxiosApi } from '@src/hooks/useAxiosApi';
import { initialTableFilterProps, MerchandiseSubRoutes, Routes } from '@src/lib/constants';
import { ProductCategory } from '@src/Models/ProductCategory';

import {
  AllSubHeadersContainer,
  DropdownContainer,
  DropdownSubHeader,
  MerchandiseCategoriesContainer,
  MerchandiseSubHeader,
  MerchandiseSubHeaderContainer,
  StyledCloseIcon,
  StyledIconButton,
} from './styles';

const MerchandiseSubHeaderLayout = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { handleGetProductCategories, handleGetCartProductCount } = useAxiosApi();
  const { cartInfo, setCartInfo } = useContext(GlobalUIContext);

  const [open, setOpen] = useState<boolean>(false);
  const [productCategoriesData, setProductCategoriesData] = useState<ProductCategory[]>();

  const handleSubHeaderClick = (subHeader: string) => {
    navigate(Routes.MERCHANDISE + MerchandiseSubRoutes.STORE + `?category_id=${subHeader}`);
  };

  const fetchProductDetaildata = async () => {
    try {
      const res = await handleGetProductCategories({ data: initialTableFilterProps });
      setProductCategoriesData(res?.data);
    } catch (err) {
      console.error('Error: ', err);
    }
  };

  const handleCartNavigation = () => {
    navigate(Routes.MERCHANDISE + MerchandiseSubRoutes.CART);
  };

  useEffect(() => {
    fetchProductDetaildata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCartProductCount = async () => {
    if (!setCartInfo) return;

    try {
      const res = await handleGetCartProductCount();
      setCartInfo(() => ({ count: res.count, refetch: fetchCartProductCount }));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchCartProductCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCartInfo]);

  return (
    <div>
      <MerchandiseSubHeaderContainer>
        <MerchandiseCategoriesContainer>
          <MerchandiseSubHeader onClick={() => setOpen(prev => !prev)}>
            {t('all')} {t('categories')}
            <KeyboardArrowDown />
          </MerchandiseSubHeader>
          {productCategoriesData?.map(({ id, name }) => (
            <MerchandiseSubHeader key={id} onClick={() => handleSubHeaderClick(id)}>
              {t(name)}
            </MerchandiseSubHeader>
          ))}
        </MerchandiseCategoriesContainer>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <IconButton aria-label='cart' onClick={handleCartNavigation}>
            <Badge badgeContent={cartInfo?.count} color='secondary'>
              <ShoppingCart />
            </Badge>
          </IconButton>
        </Box>
      </MerchandiseSubHeaderContainer>
      {open ? (
        <DropdownContainer>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h3>{t('categories')}</h3>
            <StyledIconButton onClick={() => setOpen(false)}>
              <StyledCloseIcon />
            </StyledIconButton>
          </div>
          <AllSubHeadersContainer>
            {productCategoriesData?.map(({ id, name }, index) => (
              <DropdownSubHeader key={id} $index={index + 1} onClick={() => handleSubHeaderClick(id)}>
                {t(name)}
              </DropdownSubHeader>
            ))}
          </AllSubHeadersContainer>
        </DropdownContainer>
      ) : null}
    </div>
  );
};

export default MerchandiseSubHeaderLayout;
