import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RemoveRedEye, RemoveRedEyeOutlined } from '@mui/icons-material';
import { Button, FormControl } from '@mui/material';

import { useGetTheme } from '@src/hooks/useGetTheme';
import { StyledMenu } from '@src/lib/styles/globalWrapper';

import {
  StyledApplyButton,
  StyledBackdrop,
  StyledButtonsContainer,
  StyledCheckbox,
  StyledFilterBox,
  StyledFilterText,
  StyledFormControlLabel,
  StyledRadioGroup,
  StyledResetButton,
} from '../style';
import { SelectedColumnsType, TableColumnFilterLayoutProps } from '../type';

export const TableColumnFilterLayout: React.FC<TableColumnFilterLayoutProps> = ({
  headers,
  handleApplyColumnFilter,
}) => {
  const [anchorFilter, setAnchorFilter] = useState<null | HTMLElement>(null);
  const [isSelected, setSelected] = useState<boolean>(false);
  const { t } = useTranslation();
  const { styledTheme } = useGetTheme();

  const initialColumns = headers.reduce((cols, column) => {
    cols[column.name] = !column.hideInitially;

    return cols;
  }, {} as SelectedColumnsType);

  const [selectedColumns, setSelectedColumns] = useState<SelectedColumnsType>(initialColumns);

  const handleColumnChange = (column: string, checked: boolean) => {
    setSelectedColumns(prevSelectedColumns => ({
      ...prevSelectedColumns,
      [column]: checked,
    }));
  };

  const handleSelectAllColumns = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedColumns = Object.keys(selectedColumns).reduce((cols, column) => {
      cols[column] = event.target.checked;
      return cols;
    }, {} as SelectedColumnsType);

    setSelectedColumns(newSelectedColumns);
  };

  const applyFilters = () => {
    handleApplyColumnFilter(selectedColumns);
    handleCloseFilterMenu();
  };

  const resetFilters = () => {
    setSelectedColumns(initialColumns);
    handleApplyColumnFilter('reset');
    handleCloseFilterMenu();
  };

  const handleCloseFilterMenu = () => {
    setAnchorFilter(null);
    setSelected(false);
  };

  const handleOpenFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorFilter(event.currentTarget);
    setSelected(true);
  };

  const areAllColumnsSelected = Object.values(selectedColumns).every(value => value);

  return (
    <>
      <StyledFilterBox isSelected={isSelected} hasAnchorFilter={!!anchorFilter} onClick={handleOpenFilterMenu}>
        {anchorFilter ? (
          <RemoveRedEye sx={{ width: '16px', height: '16px' }} />
        ) : (
          <RemoveRedEyeOutlined sx={{ width: '16px', height: '16px' }} />
        )}
        <StyledFilterText>{t('hideColumns')}</StyledFilterText>
      </StyledFilterBox>
      <StyledBackdrop open={Boolean(anchorFilter)} onClick={handleCloseFilterMenu} />
      <StyledMenu
        id='menu-appbar'
        anchorEl={anchorFilter}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorFilter)}
        onClose={handleCloseFilterMenu}
      >
        <div style={{ width: '400px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <FormControl
              component='fieldset'
              style={{
                maxHeight: '300px',
                padding: '10px 14px',
              }}
            >
              <StyledFormControlLabel
                control={
                  <StyledCheckbox
                    checked={areAllColumnsSelected}
                    indeterminate={!areAllColumnsSelected && Object.values(selectedColumns).some(value => value)}
                    onChange={handleSelectAllColumns}
                  />
                }
                label={t('selectAll')}
              />
              <StyledRadioGroup>
                {headers.map(column => (
                  <StyledFormControlLabel
                    key={column.name}
                    control={
                      <StyledCheckbox
                        checked={selectedColumns[column.name]}
                        onChange={event => handleColumnChange(column.name, event.target.checked)}
                      />
                    }
                    label={t(column.label || '')}
                  />
                ))}
              </StyledRadioGroup>
            </FormControl>
            <StyledButtonsContainer>
              <Button
                variant='outlined'
                sx={{
                  borderColor: styledTheme.colors.deepGrayBorder,
                }}
                onClick={resetFilters}
              >
                <StyledResetButton>{t('resetAll')}</StyledResetButton>
              </Button>
              <Button variant='contained' onClick={applyFilters}>
                <StyledApplyButton>{t('applyNow')}</StyledApplyButton>
              </Button>
            </StyledButtonsContainer>
          </div>
        </div>
      </StyledMenu>
    </>
  );
};
