import { styled } from '@mui/material';

// Styled components for the modal
export const DrawerContent = styled('div')(({ theme }) => ({
  padding: `0 ${theme?.styledTheme.newSpace.get('xl')} ${theme?.styledTheme.newFontSize.get(
    '4xl'
  )} ${theme?.styledTheme.newFontSize.get('xl')}`,
  display: 'flex',
  flexDirection: 'column',
  height: '100%', // Make sure the div takes full height
}));

export const Header = styled('div')(({ theme }) => ({
  padding: `${theme?.styledTheme.newSpace.get('4xl')} 0 ${theme?.styledTheme.newSpace.get('xl')} 0`,
  color: theme?.styledTheme.colors.midnightGray,
  fontSize: theme?.styledTheme.newFontSize.get('2xl'),
  fontWeight: '700',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'sticky',
  top: 0,
  backgroundColor: theme?.styledTheme.colors.offWhite,
  zIndex: 1,
}));

export const ScrollableContent = styled('div')({
  flex: 1, // Take up remaining space
  overflowY: 'auto', // Enable scrolling
});
