import { FC, KeyboardEvent, MouseEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Drawer, useMediaQuery } from '@mui/material';

import { useGetTheme } from '@src/hooks/useGetTheme';
import { convert24HourTo12Hour, formatCostToINR, getFullName } from '@src/lib/helper';
import { ScheduleEditProp, WeekDays } from '@src/Models/activityMapping';
import { Activity } from '@src/screens/asa/signUp/type';
import { SignUpContext } from '@src/screens/newAsa/signUp';

import {
  AvatarContainer,
  CostText,
  DescriptionText,
  DrawerContent,
  HeaderContainer,
  InstructorContainer,
  InstructorItem,
  InstructorNameText,
  StyledAvatar,
  StyledIconButton,
  TimeContainer,
  TitleContainer,
  TitleText,
  TitleTextContainer,
} from './style';

interface SignUpActivityDetailModalProps {
  isOpen: boolean;
  handleClose: () => void;
  activityDetails?: Pick<Activity, 'schedule' | 'activity_id'> &
    Partial<Pick<Activity, 'end_time' | 'start_time' | 'instructor_ids'>>;
  day: WeekDays;
  isCampProgram?: boolean;
}

export const SignUpActivityDetailModal: FC<SignUpActivityDetailModalProps> = ({
  handleClose,
  isOpen,
  activityDetails,
  day: currentDay,
  isCampProgram,
}) => {
  const {
    activity_id,
    schedule,
    end_time = '',
    start_time = '',
    instructor_ids = [],
  } = (activityDetails || {}) as Pick<
    Activity,
    'schedule' | 'activity_id' | 'end_time' | 'start_time' | 'instructor_ids'
  >;

  const isMobile = useMediaQuery('(max-width:650px)');
  const { t } = useTranslation();
  const { styledTheme } = useGetTheme();
  const { selectedProgram } = useContext(SignUpContext);
  const isCamp = selectedProgram?.type === 'CAMP';

  const {
    instructor_ids: instructorIds,
    start_time: startTime,
    end_time: endTime,
  } = (isCamp
    ? (schedule as ScheduleEditProp[])?.[0]
    : (schedule as ScheduleEditProp[])?.find(({ day }) => day?.includes(currentDay))) || {};

  const scheduleStartTime = (isCampProgram ? start_time : startTime) || '';
  const scheduleEndTime = (isCampProgram ? end_time : endTime) || '';

  const toggleDrawer = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
    if (event.type === 'keydown' && ['Tab', 'Shift'].includes((event as KeyboardEvent).key)) {
      return;
    }

    if (!open) {
      handleClose();
    }
  };

  return (
    <Drawer
      anchor={isMobile ? 'bottom' : 'right'}
      open={isOpen}
      onClose={toggleDrawer(false)}
      sx={isMobile ? { '.MuiPaper-elevation': { borderRadius: '30px 30px 0 0' } } : {}}
    >
      <DrawerContent>
        <HeaderContainer>
          <TitleContainer>
            <TitleTextContainer>
              <AvatarContainer>
                <Avatar
                  src={activity_id?.image?.url || '/icons/defaultActivityImage.svg'}
                  alt='activity-image'
                  sx={{ width: 20, height: 20 }}
                />
              </AvatarContainer>
              <TitleText>{activity_id?.name}</TitleText>
            </TitleTextContainer>
            <StyledIconButton onClick={toggleDrawer(false)} size='small'>
              <CloseIcon sx={{ color: styledTheme.colors.ebony, width: '24px', height: '24px' }} />
            </StyledIconButton>
          </TitleContainer>
          <DescriptionText>{activity_id?.description}</DescriptionText>
        </HeaderContainer>
        <CostText>
          {`${formatCostToINR(activity_id?.basic_cost || '', false)} + ${formatCostToINR(
            activity_id?.material_cost || '',
            false
          )} (${t('supplies')})`}
        </CostText>
        <TimeContainer>
          <AccessTimeIcon fontSize='small' sx={{ color: styledTheme?.colors.charcoalMist }} />
          {convert24HourTo12Hour(scheduleStartTime || '')} - {convert24HourTo12Hour(scheduleEndTime || '')}
        </TimeContainer>
        <InstructorContainer>
          {(isCampProgram ? instructor_ids : instructorIds)?.map(({ first_name, last_name }) => {
            const instructorName = getFullName(first_name, last_name);
            return (
              <InstructorItem key={instructorName}>
                <StyledAvatar>
                  {instructorName.split(' ')[0].slice(0, 1).toUpperCase()}
                  {instructorName.split(' ')[1]?.slice(0, 1)?.toUpperCase() || ''}
                </StyledAvatar>
                <InstructorNameText>{instructorName}</InstructorNameText>
              </InstructorItem>
            );
          })}
        </InstructorContainer>
      </DrawerContent>
    </Drawer>
  );
};
