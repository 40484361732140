export const NewCheckedBoxIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='16' height='16' rx='4' fill='#D97415' />
      <path
        d='M11.3307 5.5L6.7474 10.0833L4.66406 8'
        stroke='white'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
