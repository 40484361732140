import styled from 'styled-components';

import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, Box, Button } from '@mui/material';

import { Breakpoints } from '@src/lib/styles/breakpoints';

export const ChildrenCardsContainer = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
  gap: theme.space.get('m'),

  '@media (min-width: 900px)': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
}));

export const ChildrenDataContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.space.get('m'),
  borderRadius: theme.space.get('s'),
  border: `1px solid ${theme.colors.lightPeach}`,
  gap: theme.space.get('3xs'),
  background: `linear-gradient(to top right, ${theme.colors.white} 30%, ${theme.colors.lightPeach})`,
}));

export const StyledText = styled.h5(({ theme }) => ({
  color: theme.colors.mediumGray,
}));

export const StyledHeadingText = styled.h4(({ theme }) => ({
  color: theme.colors.mediumGray,
}));

export const StyledHeading = styled.div(({ theme }) => ({
  fontSize: theme.space.get('m'),
  marginBottom: theme.space.get('s'),
}));

export const StyledContainer = styled.div(({ theme }) => ({
  marginBottom: theme.space.get('xl'),
}));

export const StyledDescription = styled(Box)(({ theme }) => ({
  fontSize: 16,
  fontWeight: '400',
  color: theme.colors.slateGray,
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.colors.orange,
  width: 'fit-content',
  alignSelf: 'end',
  textTransform: 'none',
  borderRadius: theme.space.get('2xs'),
  '&:hover': {
    backgroundColor: theme.colors.orange,
  },
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.colors.paleGray}`,
  borderRadius: theme.space.get('xs'),
  boxShadow: '0px 4px 12px 0px #00000005',
  marginBottom: theme.newSpace.get('m'),
  '&::before': {
    backgroundColor: 'transparent',
  },
  '&:last-of-type': {
    borderBottomLeftRadius: theme.space.get('xs'),
    borderBottomRightRadius: theme.space.get('xs'),
  },
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  fontSize: 14,
  fontWeight: '400',
  color: theme.colors.charcoalMist,
}));

export const StyledExpandMoreIcon = styled(ExpandMore)<{ expanded: boolean }>(({ theme, expanded }) => ({
  backgroundColor: expanded ? theme.colors.orange : 'transparent',
  borderRadius: '50%',
  color: expanded ? 'white' : theme.colors.orange,
}));

export const StyledWrapper = styled.div`
  width: 70%;

  ${Breakpoints.DESKTOP_SMALL} {
    width: 100%;
  }
`;
