import { RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, TextField, Typography } from '@mui/material';

import { useGetTheme } from '@src/hooks/useGetTheme';

const FormValueLayout = ({
  heading,
  value,
  optional,
  placeholder,
  calendar,
  instructions,
  inputRef,
  id,
}: {
  heading: string;
  id?: string;
  value?: string;
  calendar?: boolean;
  optional?: boolean;
  placeholder?: string;
  instructions?: string;
  inputRef?: RefObject<HTMLInputElement>;
}) => {
  const [edit, setEdit] = useState(false);
  const { styledTheme } = useGetTheme();
  const { t } = useTranslation();
  return (
    <Box id={id} sx={{ display: 'flex', flexDirection: 'column', gap: styledTheme.newSpace.get('2xs') }}>
      <Box sx={{ display: 'flex', gap: styledTheme.newSpace.get('2xs'), alignItems: 'baseline' }}>
        <Typography
          sx={{ fontSize: styledTheme.newFontSize.get('l'), fontWeight: '600', color: styledTheme.colors.midnightGray }}
        >
          {heading}
        </Typography>
        {optional ? (
          <Typography
            sx={{
              fontSize: styledTheme.newFontSize.get('m'),
              fontWeight: '400',
              color: styledTheme.colors.charcoalMist,
            }}
          >
            ({t('optional')})
          </Typography>
        ) : null}
      </Box>
      <Box
        sx={{
          border: `1px solid ${styledTheme.colors.mistGray}`,
          p: `${styledTheme.newSpace.get('s')} ${styledTheme.newSpace.get('l')}`,
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <TextField
          variant='standard'
          inputRef={inputRef}
          defaultValue={value}
          fullWidth
          disabled={!inputRef || !edit}
          placeholder={placeholder}
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            '& .MuiInputBase-root': {
              fontSize: styledTheme.newFontSize.get('l'),
              fontWeight: '400',
              color: styledTheme.colors.midnightGray,
              textTransform: 'capitalize',
            },
          }}
        />
        {inputRef && !edit ? (
          <IconButton
            onClick={() => {
              setEdit(true);
              inputRef.current?.focus();
            }}
          >
            <img src='/icons/edit-2.svg' alt='icon' style={{ cursor: 'pointer' }} autoFocus />
          </IconButton>
        ) : null}
        {calendar ? <img src='/icons/calendar-2.svg' alt='icon' /> : null}
      </Box>
      {instructions ? (
        <Typography
          sx={{ fontSize: styledTheme.newSpace.get('m'), fontWeight: '400', color: styledTheme.colors.charcoalMist }}
        >
          {instructions}
        </Typography>
      ) : null}
    </Box>
  );
};

export default FormValueLayout;
