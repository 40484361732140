import { useState } from 'react';

import { SignUpActivityDetailModal } from '@src/components/Modals/SignupActivityDetailModal';
import { WeekDays } from '@src/Models/activityMapping';
import { Activity } from '@src/screens/asa/signUp/type';

import { DayActivity } from '../../type';

import AllChildrenLayout from './AllChildrenLayout';
import SingleChildrenLayout from './SingleChildrenLayout';

interface ScheduleCardProps {
  date: string;
  day: WeekDays;
  dayActivityData?: DayActivity[];
  holiday?: string;
  isAll: boolean;
}

const ScheduleCard = (props: ScheduleCardProps) => {
  const { day, dayActivityData, holiday, isAll } = props;

  const [isActivityModalOpen, setIsActivityModalOpen] = useState<Pick<Activity, 'schedule' | 'activity_id'>>();

  const hasActivity = dayActivityData && Object.values(dayActivityData).length;

  if (!hasActivity && !holiday) return null;

  return (
    <>
      <SignUpActivityDetailModal
        isOpen={!!isActivityModalOpen}
        handleClose={() => setIsActivityModalOpen(undefined)}
        activityDetails={isActivityModalOpen}
        day={day}
      />
      {isAll ? (
        <AllChildrenLayout {...props} handleSeeDetails={setIsActivityModalOpen} />
      ) : (
        <SingleChildrenLayout {...props} handleSeeDetails={setIsActivityModalOpen} />
      )}
    </>
  );
};

export default ScheduleCard;
