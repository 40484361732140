import { useState } from 'react';

import { LIMIT } from '@src/lib/constants';
import { appendUniqueRecords } from '@src/lib/helper';
import { ResponseData } from '@src/screens/Staff/type';

import { useScrollAndSearchProps } from './type';

export const useScrollAndSearch = <T extends { id: string }>({
  api,
  extraProps,
  filterProps,
  skip,
}: useScrollAndSearchProps<T>) => {
  const [data, setData] = useState<ResponseData<T>>();
  const [searchedData, setSearchedData] = useState<ResponseData<T> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchMoreUsersData = async (searchText: string, page: number) => {
    if (skip || (page === 1 && !searchText && !searchedData)) return;

    setLoading(true);

    try {
      const response = await api({
        data: {
          limit: LIMIT,
          page,
          search: searchText,
          ...filterProps,
        },
        extraProps,
        isLoading: false,
      });

      if (!searchText) {
        setSearchedData(null);
        setData(prev => appendUniqueRecords(prev, response));
        return;
      }

      setSearchedData(prev => {
        if (page === 1) {
          return response;
        }

        return prev ? appendUniqueRecords(prev, response) : response;
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleScrollToBottom = async (searchText: string) => {
    if (!data) return;

    if (data?.total <= data?.data?.length) return;

    await fetchMoreUsersData(searchText, data?.current_page + 1);
  };

  const handleSearchText = async (searchText: string) => {
    await fetchMoreUsersData(searchText, 1);
  };

  const getCanFetchMore = () => {
    const currentData = searchedData || data;

    if (!currentData) return false;

    if (currentData?.total <= currentData?.data?.length) return false;

    return true;
  };

  return {
    data: (searchedData || data)?.data,
    handleSearchText,
    handleScrollToBottom,
    setData,
    loading,
    canFetchMore: getCanFetchMore(),
  };
};
