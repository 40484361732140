import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { useGetTheme } from '@src/hooks/useGetTheme';

import { StyledButton } from './style';

interface FallbackScreenProps {
  heading: string;
  subHeading: string;
  buttonText: string;
  imgUrl: string;
  handleButtonClick: () => void;
  disabled?: boolean;
}

const FallbackScreen = ({
  heading,
  subHeading,
  buttonText,
  imgUrl,
  disabled,
  handleButtonClick,
}: FallbackScreenProps) => {
  const { t } = useTranslation();
  const { styledTheme } = useGetTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 2,
        backgroundColor: styledTheme.colors.offWhite,
      }}
    >
      <img src={imgUrl} alt='calendar' />
      <Box sx={{ textAlign: 'center' }}>
        <Typography
          sx={{
            color: styledTheme.colors.midnightGray,
            fontWeight: '600',
            fontSize: styledTheme.newFontSize.get('xl'),
            pl: 1,
          }}
        >
          {t(heading)}
        </Typography>
        <Typography
          sx={{
            color: styledTheme.colors.charcoalMist,
            fontWeight: '500',
            fontSize: styledTheme.newFontSize.get('l'),
            pl: 1,
          }}
        >
          {t(subHeading)}
        </Typography>
      </Box>
      <StyledButton variant='contained' onClick={handleButtonClick} disabled={disabled}>
        {t(buttonText)}
      </StyledButton>
    </Box>
  );
};

export default FallbackScreen;
