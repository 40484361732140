import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Avatar, useMediaQuery } from '@mui/material';

import { ActivityAvatarWrapper, SlotStatus } from '@src/components/ProgramActivityCard/style';
import { useGetTheme } from '@src/hooks/useGetTheme';
import { formatCostToINR } from '@src/lib/helper';
import SubmitHeader from '@src/screens/asa/signUp/SignupProgramLayout/signupHeader';

import { NoButton } from '../ConfirmationModal/style';

import {
  ItemContainer,
  ItemDetails,
  ItemPrice,
  ItemText,
  NotificationText,
  ProgramTitle,
  StatusLabel,
  StatusWrapper,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledNotification,
  TotalPrice,
} from './style';

export const ActivityOrderSummary = ({
  handleClose,
  isOpen,
  summaryData = {},
  trialDays,
  handleSubmit,
  buttonText,
  showAvailableSlots = true,
  programName,
  studentImage,
  showWarnings = true,
  isCampProgram = false,
}: {
  isOpen: boolean;
  handleClose: () => void;
  trialDays?: string;
  handleSubmit: () => void;
  buttonText?: string;
  isCampProgram?: boolean;
  programName: string;
  showAvailableSlots?: boolean;
  studentImage?: string;
  showWarnings?: boolean;
  summaryData?: Record<
    string,
    {
      name: string;
      availableSlots?: number;
      waitingSlots?: number;
      amount: number;
      isAlloted?: boolean;
      imageUrl?: string;
    }
  >;
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 480px)');

  const totalPrice = useMemo(
    () =>
      Object.values(summaryData)?.reduce((acc, curr) => {
        if (curr?.isAlloted) {
          return acc + curr.amount;
        }

        return acc + (curr?.waitingSlots ? 0 : curr.amount);
      }, 0),
    [summaryData]
  );

  const { styledTheme } = useGetTheme();
  return (
    <div>
      <StyledDialog
        open={isOpen}
        onClose={handleClose}
        fullScreen={isMobile}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
      >
        {isMobile ? (
          <>
            <SubmitHeader
              studentImage={studentImage}
              handleClick={() => handleClose()}
              headingText={'orderSummary'}
              studentName={''}
              isInfo={false}
            />
            <div
              style={{
                fontSize: styledTheme.newFontSize.get('xl'),
                fontWeight: 700,
                color: styledTheme.colors.midnightGray,
              }}
            >
              {t('program')}
            </div>
          </>
        ) : (
          <StyledDialogTitle id='alert-dialog-title'>{t('orderSummary')}</StyledDialogTitle>
        )}
        <ProgramTitle>{programName}</ProgramTitle>
        <StyledDialogContent>
          {isMobile && showWarnings ? (
            <StyledNotification>
              <AccessTimeIcon sx={{ width: 16, height: 16, color: styledTheme.colors.orange }} />
              <NotificationText>{t('modify_schedule', { trial_days: trialDays })}</NotificationText>
            </StyledNotification>
          ) : null}
          {Object.entries(summaryData)?.map(([key, value]) => (
            <ItemContainer key={key}>
              <ItemDetails>
                <ActivityAvatarWrapper>
                  <Avatar
                    src={value?.imageUrl || '/icons/defaultActivityImage.svg'}
                    alt='activity-image'
                    sx={{ width: 32, height: 32 }}
                  />
                </ActivityAvatarWrapper>
                <ItemText>
                  {value?.name}
                  <StatusWrapper>
                    <StatusLabel>{isCampProgram ? key : key?.slice(0, 3)}</StatusLabel>
                    {showAvailableSlots ? (
                      value?.availableSlots ? (
                        <SlotStatus>
                          <span>{value?.availableSlots}</span>
                          {t('available')}
                        </SlotStatus>
                      ) : (
                        <SlotStatus $isWaiting>
                          {value?.waitingSlots && value?.waitingSlots > 0 ? (
                            <span>{value.waitingSlots}</span>
                          ) : (
                            <span></span>
                          )}
                          {t('waiting')}
                        </SlotStatus>
                      )
                    ) : null}
                  </StatusWrapper>
                </ItemText>
              </ItemDetails>
              <ItemPrice $isWaiting={!!value?.waitingSlots && !value.isAlloted}>
                {formatCostToINR(value?.amount, false)}
              </ItemPrice>
            </ItemContainer>
          ))}
        </StyledDialogContent>
        {!isMobile && showWarnings ? (
          <StyledNotification>
            <AccessTimeIcon sx={{ width: 16, height: 16, color: styledTheme.colors.orange }} />
            <NotificationText>{t('modify_schedule', { trial_days: trialDays })}</NotificationText>
          </StyledNotification>
        ) : null}
        <StyledDialogActions>
          <TotalPrice>{formatCostToINR(totalPrice, false)}</TotalPrice>
          <NoButton fullWidth={false} onClick={handleSubmit}>
            {buttonText || t('start_trial')}
          </NoButton>
        </StyledDialogActions>
      </StyledDialog>
    </div>
  );
};
