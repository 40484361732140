import { DoDisturb } from '@mui/icons-material';
import { Avatar, Box, Typography } from '@mui/material';

import { useGetTheme } from '@src/hooks/useGetTheme';

const HolidayLayout = ({ holiday }: { holiday: string }) => {
  const { styledTheme } = useGetTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: styledTheme.newSpace.get('s'),
        p: styledTheme.newSpace.get('m'),
        flex: 1,
        borderTopRightRadius: '12px',
        borderBottomRightRadius: '12px',
        bgcolor: styledTheme.colors.ivoryBlush,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: styledTheme.newSpace.get('m') }}>
        <Box sx={{ display: 'flex', gap: styledTheme.newSpace.get('2xs'), alignItems: 'center' }}>
          <Avatar alt='Group Profile' src={'/icons/defaultActivityImage.svg'} sx={{ width: 32, height: 32 }} />
          <Typography
            sx={{ fontSize: styledTheme.newFontSize.get('xl'), fontWeight: '500', color: styledTheme.colors.ochre }}
          >
            {holiday}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: styledTheme.newSpace.get('3xs'), alignItems: 'center' }}>
          <DoDisturb sx={{ width: 12, height: 12, color: styledTheme.colors.charcoalMist }} />
          <Typography sx={{ fontSize: styledTheme.newFontSize.get('m'), fontWeight: '500', marginTop: '0.5px' }}>
            No ASA
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HolidayLayout;
