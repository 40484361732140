import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Menu } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

import WalkThrough from '@src/components/WalkThrough';
import { useGetTheme } from '@src/hooks/useGetTheme';
import SwitchKidsDropdown from '@src/screens/newAsa/signUp/SwitchKidsDropdown';

import NewSideDrawer from './NewSidebar';

interface MobileHeaderProps {
  heading?: string;
  hideSwitchKidsDropdown?: boolean;
}

const MobileHeader = ({ hideSwitchKidsDropdown, heading = 'activities' }: MobileHeaderProps) => {
  const { t } = useTranslation();
  const { styledTheme } = useGetTheme();

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const WalkThroughSteps = [
    {
      target: '#menu-access-tour-target',
      heading: 'menuAccess',
      content: 'menuAccessDescription',
      disableBeacon: true,
    },
  ];

  return (
    <WalkThrough phase={1} steps={WalkThroughSteps}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: `${styledTheme.newSpace.get('s')} ${styledTheme.newSpace.get('xl')}`,
          borderBottom: `1px solid ${styledTheme.colors.mistGray}`,
          m: `0 calc(${styledTheme.newSpace.get('xl')} * -1)`,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={toggleDrawer(true)} id='menu-access-tour-target'>
            <Menu />
          </IconButton>
          <Typography
            sx={{
              color: styledTheme.colors.midnightGray,
              fontWeight: '600',
              fontSize: styledTheme.newFontSize.get('xl'),
            }}
          >
            {t(heading)}
          </Typography>
        </Box>
        <NewSideDrawer isOpen={open} toggleDrawer={toggleDrawer} />
        {hideSwitchKidsDropdown ? null : <SwitchKidsDropdown />}
      </Box>
    </WalkThrough>
  );
};

export default MobileHeader;
