import styled from 'styled-components';

import { Box, ListItemButton, Radio, styled as muiStyled } from '@mui/material';

export const StyledLogo = styled.img`
  width: 99px;
  height: 28px;
  cursor: pointer;
  margin-right: ${({ theme }) => theme.space.get('s')};
`;

export const DrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '47px',
  padding: `11.5px ${theme.newSpace.get('xl')} `,
}));

export const DrawerItemButton = styled(ListItemButton)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: `${theme.styledTheme.newSpace.get('xl')} 0`,
  '& > div': {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

export const SubHeaderButton = styled(ListItemButton)`
  margin-left: ${({ theme }) => theme.space.get('l')};
`;

export const Header = muiStyled('div')(({ theme }) => ({
  cursor: 'pointer',
  borderBottom: `1px solid ${theme?.styledTheme.colors.paleGray}`,
  display: 'flex',
  padding: theme?.styledTheme.newSpace.get('xl'),
  gap: theme?.styledTheme.newSpace.get('xl'),
  alignItems: 'center',
  fontSize: theme?.styledTheme.newFontSize.get('xl'),
  fontWeight: 600,
  color: theme?.styledTheme.colors.midnightGray,
  background: theme?.styledTheme.colors.white,
  position: 'sticky',
  top: 0,
  zIndex: 1,
}));

export const Container = muiStyled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme?.styledTheme.colors.offWhite,
}));

export const GeneralLayoutContainer = muiStyled('div')(({ theme }) => ({
  padding: `${theme?.styledTheme.newSpace.get('4xl')} ${theme?.styledTheme.newSpace.get('xl')}`,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}));

export const LanguageOption = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme?.styledTheme.newSpace.get('m'),
  background: theme?.styledTheme.colors.white,
  boxShadow: '0px 4px 12px 0px #00000005',
  borderRadius: '12px',
  color: theme?.styledTheme.colors.inkShade,
  fontSize: theme?.styledTheme.newFontSize.get('xl'),
  fontWeight: 500,
  cursor: 'pointer',
}));

export const RadioStyled = muiStyled(Radio)(({ theme }) => ({
  color: theme?.styledTheme.colors.charcoalMist,
  '&.Mui-checked': {
    color: theme?.styledTheme.colors.orange,
  },
}));
