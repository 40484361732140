import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { useGetTheme } from '@src/hooks/useGetTheme';

import { StyledButton } from '../style';

const Footer = ({
  hasTrialEnded,
  cost,
  disabled,
  handleButtonClick,
}: {
  hasTrialEnded: boolean;
  cost: string;
  disabled?: boolean;
  handleButtonClick: () => void;
}) => {
  const { t } = useTranslation();
  const { styledTheme } = useGetTheme();
  return (
    <Box
      sx={{
        width: '100%',
        position: 'sticky',
        bottom: 0,
        display: 'flex',
        marginTop: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: styledTheme.colors.offWhite,
        paddingTop: styledTheme.newSpace.get('s'),
      }}
    >
      <Box
        sx={{
          border: `1px solid ${styledTheme.colors.paleGray}`,
          borderBottom: 0,
          bgcolor: styledTheme.colors.white,
          flex: 1,
          maxWidth: '400px',
          p: styledTheme.newSpace.get('xl'),
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTopRightRadius: '12px',
          borderTopLeftRadius: '12px',

          '@media screen and (max-width: 400px)': {
            borderRadius: 0,
            borderLeft: 0,
            borderRight: 0,
          },
        }}
      >
        <Box sx={{ color: styledTheme.colors.verdant, display: 'flex', gap: '1px', alignItems: 'baseline' }}>
          <Typography sx={{ fontWeight: '600', fontSize: styledTheme.newFontSize.get('xl') }}>₹</Typography>
          <Typography sx={{ fontWeight: '600', fontSize: styledTheme.newFontSize.get('3xl') }}>{cost}</Typography>
        </Box>

        <StyledButton variant='contained' onClick={handleButtonClick} disabled={disabled}>
          {hasTrialEnded ? t('continueToPayment') : t('modify')}
        </StyledButton>
      </Box>
    </Box>
  );
};

export default Footer;
