import { useContext, useState } from 'react';

import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, useMediaQuery } from '@mui/material';

import AiscSelect from '@src/components/AiscSelect';
import { AuthContext } from '@src/contexts/AuthContextProvider';
import { GlobalUIContext, SelectedChildrenProps } from '@src/contexts/GlobalUIContext';
import { useGetTheme } from '@src/hooks/useGetTheme';

import { SignUpContext } from '..';

import { customOption } from './CustomOption';
import MobileDrawer from './MobileDrawer';

const SwitchKidsDropdown = () => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const { userDetail } = useContext(AuthContext);
  const { selectedChildren, setSelectedChildren } = useContext(GlobalUIContext);
  const { selectedProgram } = useContext(SignUpContext);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(prev => !prev);
  };

  const { styledTheme } = useGetTheme();

  if (!selectedChildren) return null;

  let list: SelectedChildrenProps[] = [];

  if (userDetail?.children) {
    list = JSON.parse(JSON.stringify(userDetail.children));

    if (selectedProgram && userDetail.children.length > 1) {
      list.push({ id: 'all' });
    }
  }

  if (isDesktop)
    return (
      <AiscSelect
        id='children'
        value={selectedChildren}
        options={list}
        onChange={data => setSelectedChildren?.(data as SelectedChildrenProps)}
        formatOptionLabel={data => customOption(data, userDetail?.children)}
      />
    );

  return (
    <>
      <Box
        id='switch-child-tour-target'
        sx={{
          color: styledTheme.colors.midnightGray,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          bgcolor: styledTheme.colors.paleGray,
          borderRadius: '50px',
          gap: 1,
          userSelect: 'none',
          p: 0.5,
          pr: 1,
        }}
        onClick={handleDrawerToggle}
      >
        {customOption(selectedChildren, userDetail?.children)}
        <KeyboardArrowDown fontSize='small' />
      </Box>
      <MobileDrawer isDrawerOpen={isDrawerOpen} handleDrawerToggle={handleDrawerToggle} list={list} />
    </>
  );
};

export default SwitchKidsDropdown;
