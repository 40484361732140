import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Clear } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';

import useDebounce from '@src/hooks/useDebounce';

import { Search, SearchIconWrapper, StyledInputBase } from '../style';
import { TableSearchLayoutProps } from '../type';

export const TableSearchLayout: React.FC<TableSearchLayoutProps> = ({ setSearchText }) => {
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation();

  const handleClearSearch = () => {
    setSearchValue('');
    setSearchText('');
  };

  const handleSearchDebounce = useDebounce(value => {
    setSearchText(value as string);
  }, 500);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    handleSearchDebounce(event.target.value);
  };

  return (
    <Search>
      <SearchIconWrapper>
        <img src='/icons/search-normal.svg' alt='search' />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder={t('search') + '...'}
        value={searchValue}
        onChange={handleSearchChange}
        inputProps={{ 'aria-label': 'search' }}
        endAdornment={
          searchValue && (
            <InputAdornment position='end'>
              <IconButton onClick={handleClearSearch} aria-label='clear search'>
                <Clear />
              </IconButton>
            </InputAdornment>
          )
        }
      />
    </Search>
  );
};
