import { useTranslation } from 'react-i18next';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Avatar, IconButton, useMediaQuery } from '@mui/material';

import { ArrowLeftIcon } from '@src/components/Icons/ArrowLeft';
import { useGetTheme } from '@src/hooks/useGetTheme';

import { StyledSubmitHeader } from '../style';

import {
  RegisterActivitiesContainer,
  RegisterActivitiesText,
  StyledAvatarChip,
  StyledAvatarContainer,
  StyledMobileAvatarWrapper,
} from './style';
import { SubmitHeaderProps } from './type';

const SubmitHeader: React.FC<SubmitHeaderProps> = ({
  studentName,
  handleClick,
  studentImage,
  headingText,
  isInfo = true,
  isStudentAvatar = true,
}) => {
  const { t } = useTranslation();
  const { styledTheme } = useGetTheme();
  const isMobile = useMediaQuery('(max-width: 480px)');

  return (
    <StyledSubmitHeader
      style={{
        paddingTop: isInfo ? styledTheme.newSpace.get('xs') : styledTheme.newSpace.get('l'),
        paddingBottom: isInfo ? styledTheme.newSpace.get('xs') : styledTheme.newSpace.get('l'),
      }}
    >
      <RegisterActivitiesContainer>
        <RegisterActivitiesText onClick={handleClick}>
          {handleClick ? <ArrowLeftIcon /> : null}
          {t(headingText)}
        </RegisterActivitiesText>
        {isStudentAvatar ? (
          isMobile ? (
            <StyledMobileAvatarWrapper>
              {isInfo ? (
                <IconButton>
                  <ErrorOutlineIcon fontSize='small' sx={{ color: 'black' }} id='program-information-tour-target' />
                </IconButton>
              ) : null}
              <StyledAvatarContainer>
                <Avatar alt={studentName} src={studentImage} sx={{ width: '24px', height: '24px' }} />
              </StyledAvatarContainer>
            </StyledMobileAvatarWrapper>
          ) : (
            <StyledAvatarChip
              avatar={<Avatar alt={studentName} src={studentImage} />}
              label={studentName}
              variant='outlined'
            />
          )
        ) : (
          <div></div>
        )}
      </RegisterActivitiesContainer>
    </StyledSubmitHeader>
  );
};

export default SubmitHeader;
