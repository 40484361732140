import React from 'react';
import { useTranslation } from 'react-i18next';
import MuiPhoneNumber from 'mui-phone-number';

import { Box } from '@mui/material';

import { useGetTheme } from '@src/hooks/useGetTheme';

import { StyledErrorMessage, StyledLabel, StyledSelectContainer } from './style';

interface PhoneNumberInputProps {
  name: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  isEditable?: boolean;
  required?: boolean;
  errorMessage?: string;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  name,
  label,
  value,
  onChange,
  placeholder,
  isEditable = true,
  required = false,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const { styledTheme } = useGetTheme();
  return (
    <StyledSelectContainer>
      <StyledLabel htmlFor={name} error={!!errorMessage}>
        {t(label)}
        {required ? ' *' : ''}
      </StyledLabel>
      <Box display='flex' flexDirection='column'>
        <MuiPhoneNumber
          defaultCountry='in'
          value={value}
          onChange={onChangeValue => onChange((onChangeValue as string) || '')}
          disabled={!isEditable}
          placeholder={placeholder ? `${t(placeholder)}...` : undefined}
          variant='outlined'
          fullWidth
          required={required}
          error={!!errorMessage}
          helperText={errorMessage || ''}
          size='small'
          inputProps={{
            id: name,
            name: name,
          }}
          InputLabelProps={{ shrink: !!value }}
          sx={{
            '& .MuiOutlinedInput-root': {
              fontSize: styledTheme.newFontSize.get('xl'),
              '&.Mui-focused fieldset': {
                borderColor: 'lavenderMist',
              },
            },
            '& label.Mui-focused': {
              color: 'lavenderMist',
            },
          }}
        />
        {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
      </Box>
    </StyledSelectContainer>
  );
};

export default PhoneNumberInput;
