import moment from 'moment';

import { Box } from '@mui/material';

import { useGetTheme } from '@src/hooks/useGetTheme';
import { weekDaysFullNames } from '@src/lib/constants';
import { Breakpoints } from '@src/lib/styles/breakpoints';
import { Program } from '@src/Models/program';
import { ScheduleOffDays } from '@src/Models/schedule';

import { ScheduleActivitiesData } from '../type';

import ScheduleCard from './ScheduleCard';

const ScheduleLayout = ({
  offDaysData,
  scheduleActivitiesData,
  visibleSelectedRange,
  isAll,
  selectedProgram,
}: {
  selectedProgram: Program;
  scheduleActivitiesData: ScheduleActivitiesData | undefined;
  offDaysData: ScheduleOffDays[] | undefined;
  visibleSelectedRange:
    | {
        start: string;
        end: string;
      }
    | undefined;
  isAll: boolean;
}) => {
  const { styledTheme } = useGetTheme();

  if (!visibleSelectedRange || !offDaysData || !scheduleActivitiesData) return null;

  return (
    <div>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, minmax(313px, 1fr))',
          gridTemplateRows: 'repeat(2, 1fr)',
          gap: styledTheme.newSpace.get('4xl'),
          flex: 1,
          mt: styledTheme.newSpace.get('s'),

          [Breakpoints.DESKTOP_SMALL]: {
            gap: styledTheme.newSpace.get('xl'),
            gridTemplateColumns: 'repeat(1, 1fr)',
          },
        }}
      >
        {weekDaysFullNames.map((day, index) => {
          const currentDate = moment(visibleSelectedRange.start).add(index, 'days');
          const formattedDate = currentDate.format('YYYY-MM-DD');

          const holiday = offDaysData.find(
            item => moment(item.date).format('YYYY-MM-DD') === formattedDate
          )?.description;

          const baseScheduleCardData = {
            date: currentDate.format('DD'),
            day,
            holiday,
            isAll,
            dayActivityData: scheduleActivitiesData[day],
          };

          if (selectedProgram?.type === 'CAMP') {
            if (isAll) {
              const campScheduleCardData = {
                ...baseScheduleCardData,
                dayActivityData: Object.values(scheduleActivitiesData.activity_ids).flat(),
              };

              return <ScheduleCard {...campScheduleCardData} />;
            }

            return Object.values(scheduleActivitiesData.activity_ids || {}).map(activityData => {
              const campScheduleCardData = {
                ...baseScheduleCardData,
                dayActivityData: activityData,
              };

              return <ScheduleCard {...campScheduleCardData} />;
            });
          } else {
            return <ScheduleCard {...baseScheduleCardData} />;
          }
        })}
      </Box>
    </div>
  );
};

export default ScheduleLayout;
